import React, { useState, useEffect } from "react";
import {
  Button,
  ConfigProvider,
  Modal,
  Input,
  Select,
  TimePicker,
  DatePicker,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  modalScheduleSelector,
  setModalSchedule,
  setModalLoading,
  modalLoadingSelector,
} from "../../../store/modal/modalSlice";
import {
  createSchedule,
  getLichCongTacNgay,
} from "../../../services/scheduleServices";
import "./ModalSchedule.scss";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import _ from "lodash";

dayjs.locale("vi");

const ModalSchedule = () => {
  const dispatch = useDispatch();
  const { isModalShedule, data } = useSelector(modalScheduleSelector);
  const modalLoading = useSelector(modalLoadingSelector);
  const modalSchedule = useSelector(modalScheduleSelector);

  const [title, setTitle] = useState("");
  const [type, setType] = useState(modalSchedule?.type);
  const [date, setDate] = useState(data?.date ?? dayjs().format("DD/MM/YYYY"));
  const [listSchedule, setListSchedule] = useState(data?.listSchedule ?? []);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [leader, setLeader] = useState("");
  const [captain, setCaptain] = useState("");
  const [squadMember, setSquadMember] = useState("");
  const [dateCongTac, setDateCongTac] = useState("");
  const [squadMemberOnDuty, setSquadMemberOnDuty] = useState([]);

  useEffect(() => {
    if (type === "lịch trực") {
      getLichCT();
      handleFilterSquadMember();
    }
  }, [date]);

  const handleFilterSquadMember = () => {
    const filterSquadMember = listSchedule.filter((item) => item.date === date);
    const squadMember = filterSquadMember.map((item) => {
      if (item.start == "11:58") {
        return {
          dayShift: "sang",
          start: item.start,
          squadMember: item.squadMember,
        };
      } else if (item.start == "17:58") {
        return {
          dayShift: "chieu",
          start: item.start,
          squadMember: item.squadMember,
        };
      } else {
        return {
          dayShift: "toi",
          start: item.start,
          squadMember: item.squadMember,
        };
      }
    });
    setSquadMemberOnDuty(squadMember);
  };

  const getLichCT = async () => {
    await getLichCongTacNgay(date)
      .then((res) => {
        setDateCongTac(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const optionsDayShift = [
    { label: "Sáng", value: "sang" },
    { label: "Chiều", value: "chieu" },
    { label: "Tối", value: "toi" },
  ];

  const parseToDayShift = (value) => {
    let dayShift = {};
    if (value === "sang") {
      dayShift = {
        start: "11:58",
        end: "11:59",
      };
    } else if (value === "chieu") {
      dayShift = {
        start: "17:58",
        end: "17:59",
      };
    } else {
      dayShift = {
        start: "23:58",
        end: "23:59",
      };
    }
    return dayShift;
  };

  const [start, setStart] = useState(
    type === "lịch trực"
      ? parseToDayShift(optionsDayShift[0].value).start
      : dayjs(new Date()).format("HH:mm")
  );
  const [end, setEnd] = useState(
    type === "lịch trực" ? parseToDayShift(optionsDayShift[0].value).end : ""
  );

  const optionsSchedule = [
    {
      value: "lịch trực",
      label: "lịch trực",
    },
    {
      value: "lịch công tác",
      label: "lịch công tác",
    },
  ];

  const handleCancel = () => {
    dispatch(
      setModalSchedule({
        isModalShedule: false,
        type: "",
        data: {},
      })
    );
  };

  const checkSquadMemberOnDuty = async () => {
    setLoading(true);
    const isOnDuty = squadMemberOnDuty.some((item) => item?.start === start);

    if (isOnDuty) {
      message.warning(
        `Đã có người trực vào ca này!!! \n Vui lòng chọn ca khác!!!`
      );
      setLoading(false);
      return;
    }

    await createSchedule({
      title,
      type: "lichTruc",
      date,
      start,
      end,
      description,
      leader: leader,
      captain,
      squadMember,
    })
      .then((res) => {
        setLoading(false);
        message.success(`Thêm ${type} thành công`);
        dispatch(
          setModalSchedule({
            isModalShedule: false,
            type: "",
            data: {},
          })
        );
        dispatch(setModalLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setModalSchedule({
            isModalShedule: false,
            type: "",
            data: {},
          })
        );
      });
  };

  const handleOk = async () => {
    if (type == "lịch trực") {
      if (squadMember === "") {
        message.error(`Thiếu cán bộ trực!!!`);
        return;
      }
      if (
        _.has(dateCongTac, "leader") &&
        dateCongTac?.leader !== "" &&
        !_.isEmpty(leader)
      ) {
        message.error(`Đã có người lãnh đạo trực trong ngày!!!`);
        return;
      }
      if (
        _.has(dateCongTac, "captain") &&
        dateCongTac?.captain !== "" &&
        !_.isEmpty(captain)
      ) {
        message.error(`Đã có người chỉ huy trực trong ngày!!!`);
        return;
      }
      checkSquadMemberOnDuty();
    } else {
      if (leader === "") {
        message.error(`Thiếu người công tác!!!`);
        return;
      }
      setLoading(true);
      await createSchedule({
        title,
        type: "lichCongTac",
        date,
        start,
        end,
        description,
        leader: `Đ/C: ${leader}`,
      })
        .then((res) => {
          setLoading(false);
          message.success(`Thêm ${type} thành công`);
          dispatch(
            setModalSchedule({
              isModalShedule: false,
              type: "",
              data: {},
            })
          );
          dispatch(setModalLoading(false));
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            setModalSchedule({
              isModalShedule: false,
              type: "",
              data: {},
            })
          );
        });
    }
  };

  const handleSelect = (value) => {
    setType(value);
  };

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleChooseDate = (date, dateString) => {
    setDate(dayjs(date).format("DD/MM/YYYY"));
  };

  const handleChangeDayShift = (value) => {
    setStart(parseToDayShift(value).start);
    setEnd(parseToDayShift(value).end);
  };

  const handleChangeLeader = (e) => {
    setLeader(e.target.value);
  };

  const handleCheckStart = (start) => {
    setStart(start);
    if (start < "12:01") {
      setEnd("12:00");
    } else if (start < "17:01") {
      setEnd("17:00");
    }
  };

  useEffect(() => {
    if (type !== "lịch trực") {
      handleCheckStart(start);
    }
  }, [start]);

  return (
    <>
      <Modal
        title={`Thêm ${type}`}
        okButtonProps={{ style: { width: 100, backgroundColor: "#E61D23" } }}
        okText={<span>Thêm lịch</span>}
        cancelText={<span>Hủy</span>}
        open={isModalShedule}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
        width={700}
      >
        <div className="modal-schedule">
          <div className="title-container">
            <span>Tiêu đề {type}</span>
            <Input
              onChange={handleChangeTitle}
              style={{ marginTop: 10 }}
              placeholder={`Tiêu đề ${type}`}
              className="input-schedule"
            />
          </div>
          <div className="select-container">
            <span>Chọn lịch hiển thị</span>
            <Select
              defaultValue={type}
              style={{
                marginTop: 10,
                height: 50,
              }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={handleSelect}
              options={optionsSchedule}
            />
          </div>
          <div className="description-container">
            <span>
              {type == "lịch trực" ? "Lãnh đạo trực" : "Người công tác"}
            </span>
            <Input
              onChange={handleChangeLeader}
              style={{ marginTop: 10 }}
              placeholder={
                type == "lịch trực" ? "Lãnh đạo trực" : "Người công tác"
              }
              value={leader}
              className="input-schedule"
            />
          </div>
          {type == "lịch trực" && (
            <>
              <div className="description-container">
                <span>Chỉ huy trực</span>
                <Input
                  onChange={(e) => setCaptain(e.target.value)}
                  style={{ marginTop: 10 }}
                  placeholder={"Chỉ huy trực"}
                  value={captain}
                  className="input-schedule"
                />
              </div>
              <div>
                <span>Cán bộ trực</span>
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                    width: "70%",
                    gap: 20,
                  }}
                >
                  <Input
                    onChange={(e) => setSquadMember(e.target.value)}
                    placeholder={"Cán bộ trực"}
                    value={squadMember}
                    className="input-schedule"
                  />
                  <Select
                    allowClear={false}
                    style={{
                      height: 50,
                      width: "50%",
                    }}
                    placeholder="Please select"
                    defaultValue={optionsDayShift[0]}
                    onChange={handleChangeDayShift}
                    options={optionsDayShift}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </div>
              </div>
            </>
          )}
          <div className="choose-date-container">
            <div className="choose-date-title">
              <span>
                Ngày {type == "lịch trực" ? type.split(" ")[1] : "công tác"}
              </span>
              {type !== "lịch trực" && (
                <span style={{ alignSelf: "flex-start", width: "62%" }}>
                  Thời gian bắt đầu
                </span>
              )}
            </div>
            <div className="choose-date-input">
              <ConfigProvider>
                <DatePicker
                  onChange={handleChooseDate}
                  className="input-choose-date"
                  value={dayjs(date, "DD/MM/YYYY")}
                  format="DD/MM/YYYY"
                  allowClear={false}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                />
              </ConfigProvider>
              {type !== "lịch trực" && (
                <>
                  <TimePicker
                    format={"HH:mm"}
                    onChange={(e) => handleCheckStart(dayjs(e).format("HH:mm"))}
                    className="input-choose-date"
                    value={dayjs(start, "HH:mm")}
                    allowClear={false}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </>
              )}
            </div>
          </div>
          <div className="description-container">
            <span>Mô tả {type}</span>
            <Input
              onChange={handleChangeDescription}
              style={{ marginTop: 10 }}
              placeholder={`Mô tả ${type}`}
              className="input-schedule"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalSchedule;
