import React, {
  useState,
  useLayoutEffect,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import "./ThuVien.scss";
import Marquee from "../../../components/Marquee/Marquee";
import Flag from "../../../assets/vietnam.png";
import HeaderSearch from "../../../components/Header/HeaderAdmin";
import Tabs from "../../../components/Tabs/Tabs";
import { Flex, Input, Modal, Table, message, Space } from "antd";
import { CiFilter } from "react-icons/ci";
import {
  baiVietColumns,
  bannerColumns,
  calendarColumns,
  hinhAnhColumns,
  titleColumns,
  videoColumns,
} from "../../../components/Columns";
import { useQuery } from "@tanstack/react-query";
import { API_ENDPOINT } from "../../../services/axiosClient";
import { ModalAddLibrary } from "../../../components/Modal/ModalAddLibrary/ModalAddLibrary";
import { ModalEditLibrary } from "../../../components/Modal/ModalEditLibrary/ModalEditLibrary";
import { useDispatch, useSelector } from "react-redux";
import {
  modalEditBlogSelector,
  modalEditLibrarySelector,
  modalFilterSelector,
  modalLoadingSelector,
  setModalFilter,
} from "../../../store/modal/modalSlice";
import {
  getCollections,
  searchCollections,
} from "../../../services/collectionServices";
import { ModalFilter } from "../../../components/Modal/ModalFilter/ModalFilter";
import _ from "lodash";
import { getImage } from "../../../services/imageServices";
import ModalBlog from "../../../components/Modal/ModalBlog/ModalBlog";
import ModalEditBlog from "../../../components/Modal/ModalEditBlog/ModalEditBlog";
import dayjs from "dayjs";
import { getRole } from "../../../utils/localStorage";
import { refeshSelector } from "../../../store/refesh/refeshSlice";
import {
  setLoading as setLoadingSearchTable,
  selectLoading,
} from "../../../store/table/tableSlice";
import { layoutSelector, setLayout } from "../../../store/layouts/LayoutSlice";

const fetchCollectionData = async (query, limit, page) => {
  const res = await getCollections(query, { limit, page });
  const data = res?.data;
  const updatedData = await Promise.all(
    data?.collections.map(async (item) => {
      const contentRes = async () => {
        const validImageExtensions = [
          "png",
          "jpg",
          "jpeg",
          "gif",
          "bmp",
          "webp",
          "mp4",
        ];
        const fileExtension = item.content.split(".").pop().toLowerCase();

        if (validImageExtensions.includes(fileExtension)) {
          return await fetch(
            `${API_ENDPOINT}collections/download/${item.content}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + localStorage.getItem("LOCAL_STORAGE_AUTH_KEY"),
              },
            }
          ).then((res) => res?.url);
        } else return null;
      };

      if (item?.type == "title") {
        return { ...item };
      } else {
        return { ...item, imageUrl: await contentRes() };
      }
    })
  );

  return {
    data: updatedData,
    currentPage: data.currentPage,
    totalCount: data.totalCount,
    totalPages: data.totalPages,
  };
};

// hàm blog
const adddBlogData = async (
  query,
  title,
  description,
  content,
  layoutSelected,
  setLabelTitleData,
  setDescriptionData,
  setIsModalOpen,
  setBaiVietData
) => {
  const formData = new FormData();
  formData.append("title", title);
  formData.append("description", description);
  formData.append("content", content);
  formData.append("layout", layoutSelected);
  const res = await fetch(`${API_ENDPOINT}collections/${query}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("LOCAL_STORAGE_AUTH_KEY")}`,
    },
    body: formData,
  });
  if (res.ok) {
    setLabelTitleData("");
    setDescriptionData("");
    setIsModalOpen(false);
    setBaiVietData("");
    message.success("Thêm bài viết thành công");
    // window.location.reload();
  }
  const data = await res.json();
  return data;
};

const ThuVien = ({ columnTitles, isMargee = true }) => {
  const dispatch = useDispatch();
  const [checkRole, setCheckRole] = useState("");
  const [fileList, setFileList] = useState([]);
  const [addName, setAddName] = useState("banner");
  const [type, setType] = useState("banner");
  const [addNameTitle, setAddNameTitle] = useState("");
  const [labelTitleData, setLabelTitleData] = useState("");
  const [labelDescriptionData, setDescriptionData] = useState("");
  const [baiVietData, setBaiVietData] = useState("");
  const [dataFilter, setDataFilter] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingModalAdd, setLoadingModalAdd] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
  });
  const [paginationFilter, setPaginationFilter] = useState({
    currentPage: 1,
    totalCount: 0,
    totalPages: 0,
  });

  const { isModalEditOpen } = useSelector(modalEditLibrarySelector);
  const { isModalFilterOpen, data: params } = useSelector(modalFilterSelector);
  const { isModalEditBlogOpen } = useSelector(modalEditBlogSelector);
  const loadingModal = useSelector(modalLoadingSelector);
  const loadingInSearchTable = useSelector(selectLoading);
  const refeshSelec = useSelector(refeshSelector);

  console.log(refeshSelec);

  const handleBaiVietChange = (data) => {
    setBaiVietData(data);
  };

  const queryType = useMemo(() => {
    switch (addName) {
      case "Banner":
        return "banner";
      case "Bài viết":
        return "blog";
      case "Video":
        return "video";
      case "Hình ảnh":
        return "image";
      case "Tiêu đề":
        return "title";
      case "Hình của lịch":
        return "schedule";
      default:
        return "banner";
    }
  }, [addName]);

  const {
    data: dataApi,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ["collections", queryType, pagination.currentPage],
    queryFn: async () =>
      await fetchCollectionData(
        queryType,
        pagination?.pageSize,
        pagination?.currentPage
      ),
    enabled: !!queryType,
    refetchOnMount: true,
  });

  useEffect(() => {
    const handleFilter = async () => {
      // console.log(params);
      if (
        !_.isEmpty(params?.fromDate) &&
        !_.isEmpty(params?.toDate) &&
        !_.isNull(params?.fromDate) &&
        !_.isNull(params?.toDate)
        // && params?.fromDate == params?.toDate
      ) {
        // console.log("da vao day");
        const newToDate = dayjs(params?.toDate).add(24, "hour").format();
        // console.log(newToDate);
        const newData = {
          ...params,
          toDate: newToDate,
        };
        await searchCollections(newData)
          .then((res) => {
            // console.log("res", res)
            setDataFilter(res);
            setPaginationFilter({
              currentPage: res?.data.currentPage,
              totalCount: res?.data.totalCount,
              totalPages: res?.data.totalPages,
            });
            dispatch(
              setModalFilter({
                isModalFilterOpen: false,
                data: {},
              })
            );
          })
          .catch((err) => {
            message.error("Tìm kiếm thất bại");
          });
      } else if (_.has(params, "title")) {
        console.log("da vao day");
        await searchCollections({
          ...params,
          title: params?.title,
        })
          .then((res) => {
            console.log("res", res.data);
            message.success("Tìm kiếm thành công");
            setDataFilter(res);
            setPaginationFilter({
              currentPage: res?.data.currentPage,
              totalCount: res?.data.totalCount,
              totalPages: res?.data.totalPages,
            });
            dispatch(
              setModalFilter({
                isModalFilterOpen: false,
                data: {},
              })
            );
          })
          .catch((err) => {
            message.error("Tìm kiếm thất bại");
          });
      } else {
        await searchCollections(params)
          .then((res) => {
            // console.log("res", res)
            message.success("Tìm kiếm thành công");
            setDataFilter(res);
            setPaginationFilter({
              currentPage: res?.data.currentPage,
              totalCount: res?.data.totalCount,
              totalPages: res?.data.totalPages,
            });
            dispatch(
              setModalFilter({
                isModalFilterOpen: false,
                data: {},
              })
            );
          })
          .catch((err) => {
            message.error("Tìm kiếm thất bại");
          });
      }
    };

    const hasEmptyField = _.some(params, _.isEmpty);

    if (hasEmptyField) {
      // console.log("params", params);
      handleFilter();
    } else {
      refetch();
    }
  }, [params, paginationFilter]);

  useEffect(() => {
    const handleCheckRole = async () => {
      const checkRole = await getRole();
      setCheckRole(checkRole);
    };
    handleCheckRole();
  }, []);

  useLayoutEffect(() => {
    switch (addName) {
      case "Banner":
        setType("banner");
        setAddNameTitle("Thêm banner");
        setDataFilter(null);
        break;
      case "Bài viết":
        setType("blog");
        setAddNameTitle("Thêm bài viết");
        setDataFilter(null);
        break;
      case "Video":
        setType("video");
        setAddNameTitle("Thêm video");
        setDataFilter(null);
        break;
      case "Hình ảnh":
        setType("image");
        setAddNameTitle("Thêm hình ảnh");
        setDataFilter(null);
        break;
      case "Tiêu đề":
        setType("title");
        setAddNameTitle("Thêm tiêu đề");
        setDataFilter(null);
        break;
      case "Hình của lịch":
        setType("schedule");
        setAddNameTitle("Thêm Hình của lịch");
        setDataFilter(null);
        break;
      default:
        setType("banner");
        setAddNameTitle("Thêm banner");
        setDataFilter(null);
    }
  }, [addName]);

  useEffect(() => {
    const imageFilter = async () => {
      if (dataFilter?.data?.results) {
        const updatedResults = await Promise.all(
          dataFilter.data.results.map(async (item) => {
            try {
              const validImageExtensions = [
                "png",
                "jpg",
                "jpeg",
                "gif",
                "bmp",
                "webp",
                "mp4",
              ];
              const fileExtension = item.content.split(".").pop().toLowerCase();

              if (validImageExtensions.includes(fileExtension)) {
                const response = await fetch(
                  `${API_ENDPOINT}collections/download/${item.content}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization:
                        "Bearer " +
                        localStorage.getItem("LOCAL_STORAGE_AUTH_KEY"),
                    },
                  }
                );

                if (response.ok) {
                  const imageUrl = response.url;
                  return { ...item, imageUrl };
                } else {
                  console.error(`Failed to fetch image: ${item.content}`);
                  return { ...item, imageUrl: null };
                }
              } else {
                return { ...item, imageUrl: null };
              }
            } catch (error) {
              console.error(`Error fetching image: ${item.content}`, error);
              return { ...item, imageUrl: null };
            }
          })
        );
        setDataFilter({
          ...dataFilter,
          data: { ...dataFilter.data, results: updatedResults },
        });
      }
      dispatch(setLoadingSearchTable(false));
    };
    imageFilter();
  }, [dataFilter?.data?.results?.length]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const layoutSelected = useSelector(layoutSelector);

  useEffect(() => {
    refetch();
  }, [loadingModal, queryType, labelTitleData, refeshSelec]);

  // Hàm thêm dữ liệu thư viện
  const addDataThuVien = async (
    data,
    des,
    type,
    imageFile,
    text,
    layoutSelected
  ) => {
    try {
      const formData = new FormData();
      formData.append("title", data);
      formData.append("description", des);
      formData.append("type", type);
      formData.append("file", imageFile[0]);
      formData.append("text", text);
      formData.append("layout", layoutSelected);

      const res = await fetch(`${API_ENDPOINT}collections`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_AUTH_KEY"
          )}`,
        },
        body: formData,
      });

      if (!res.ok) {
        message.error("Thêm dữ liệu thư viện thất bại");
        return;
      }
      await res.json();

      // setAddNameTitle("");
      setFileList([]);
      setLabelTitleData("");
      setDescriptionData("");
      setIsModalOpen(false);
      message.success("Thêm dữ liệu thư viện thành công");
      // window.location.reload();
    } catch (error) {
      console.error("Error adding data to library", error);
      message.error("Có lỗi xảy ra khi thêm dữ liệu");
    }
  };

  const addDataTitleThuVien = async (data) => {
    try {
      const formData = new FormData();
      formData.append("title", data);
      const res = await fetch(`${API_ENDPOINT}collections/title`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_AUTH_KEY"
          )}`,
        },
        body: formData,
      });

      if (!res.ok) {
        message.error("Thêm dữ liệu thư viện thất bại");
        return;
      }
      await res.json();

      setLabelTitleData("");
      setDescriptionData("");
      setIsModalOpen(false);
      message.success("Thêm dữ liệu thư viện thành công");
      // window.location.reload();
    } catch (error) {
      console.error("Error adding data to library", error);
      message.error("Có lỗi xảy ra khi thêm dữ liệu");
    }
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      // Kiểm tra nếu addName là "Video" và file không phải là mp4
      if (addName === "Video" && file.type !== "video/mp4") {
        // Hiển thị thông báo lỗi
        message.error("Chỉ có thể up file có đuôi .mp4");
        // Ngăn việc tải lên
        setIsModalOpen(false);
        return false;
      }
      // kiểm tra nếu addName là "Hình ảnh" và file không phải là ảnh
      if (
        (addName === "Banner" || addName === "Hình ảnh") &&
        !["image/jpeg", "image/png"].includes(file.type) &&
        !file.name.match(/\.(jpg|jpeg|png)$/i)
      ) {
        message.error("Chỉ có thể up file có đuôi .jpg, .jpeg, .png");
        setIsModalOpen(false);
        // Ngăn việc tải lên
        return false;
      }

      const reader = new FileReader();
      reader.onload = () => {
        if (addName === "Video") {
          setFileList([file]);
        } else {
          setFileList([file]);
        }
      };
      reader.readAsDataURL(file);
      // Cho phép tải lên nếu không có lỗi
      return true;
    },
    onRemove: () => {
      setFileList([]);
    },
    fileList,
  };

  // Hàm Lưu dữ liệu ở Modal
  const handleOk = async () => {
    console.log("layoutSelected", layoutSelected);
    setLoadingModalAdd(true);
    if (addName === "Bài viết") {
      if (!labelTitleData) {
        message.error("Xin nhập tiêu đề bài viết");
        return;
      }
      await adddBlogData(
        "blog",
        labelTitleData,
        labelDescriptionData,
        baiVietData,
        layoutSelected,
        setLabelTitleData,
        setDescriptionData,
        setIsModalOpen,
        setBaiVietData
      )
        .then(() => {
          dispatch(setLayout(""));
          setLoadingModalAdd(false);
        })
        .catch(() => setLoadingModalAdd(false));
    } else if (addName === "Tiêu đề") {
      console.log(labelTitleData);
      await addDataTitleThuVien(labelTitleData)
        .then(() => setLoadingModalAdd(false))
        .catch(() => setLoadingModalAdd(false));
    } else {
      // if (!labelTitleData || fileList.length === 0) {
      //   message.error("Xin nhập tiêu đề và cung cấp hình ảnh");
      //   return;
      // }
      const imageFile = fileList;
      await addDataThuVien(
        labelTitleData,
        labelDescriptionData,
        type,
        imageFile,
        null,
        layoutSelected
      )
        .then(() => setLoadingModalAdd(false))
        .catch(() => setLoadingModalAdd(false));
      refetch();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLabelTitleData("");
    setDescriptionData("");
    dispatch(setLayout(""));
  };

  const handleModalTitle = useCallback(
    (e) => {
      setLabelTitleData(e.target.value);
    },
    [labelTitleData]
  );

  const handleThuVienAddModal = () => {
    showModal();
  };

  const handleModalFilter = () => {
    dispatch(
      setModalFilter({
        isModalFilterOpen: true,
        data: {},
      })
    );
  };

  const handleTabClick = (name) => {
    setPagination({ ...pagination, currentPage: 1 });
    setLabelTitleData("");
    setDescriptionData("");
    setAddName(name);
    setFileList([]);
  };

  // console.log("dataApi", dataApi, "\n", "dataFilter", dataFilter);
  // console.log(_.isEmpty(params?.title));
  // console.log(dataFilter?.data?.results);
  // console.log(!_.has(params, 'title'));

  const transformedData = useMemo(() => {
    if (dataFilter?.data?.results?.length > 0 || _.has(params, "title")) {
      return dataFilter?.data?.results.map((item, index) => ({
        id: item._id,
        key: index + 1,
        author: item.author,
        content: item.content,
        createdAt: item.createdAt,
        department_id: item?.department_id?.join(", "),
        description: item.description,
        duration: item.duration,
        title: item.title,
        type: item.type,
        imageUrl: item.imageUrl,
        videoUrl: item.videoUrl,
      }));
    } else if (_.isEmpty(params?.title)) {
      return dataApi?.data?.map((item, index) => ({
        id: item._id,
        key: index + 1,
        author: item.author,
        content: item.content,
        createdAt: item.createdAt,
        department_id: item?.department_id?.join(", "),
        description: item.description,
        duration: item.duration,
        title: item.title,
        type: item.type,
        imageUrl: item.imageUrl,
        videoUrl: item.videoUrl,
      }));
    } else {
      return dataApi?.data?.map((item, index) => ({
        id: item._id,
        key: index + 1,
        author: item.author,
        content: item.content,
        createdAt: item.createdAt,
        department_id: item?.department_id?.join(", "),
        description: item.description,
        duration: item.duration,
        title: item.title,
        type: item.type,
        imageUrl: item.imageUrl,
        videoUrl: item.videoUrl,
      }));
    }
  }, [dataApi, dataFilter]);
  // console.log("transformedData", transformedData);
  // console.log(dataApi?.data.map((item, index) => console.log(index)));

  const renderTabContent = (columns, title) => (
    <div className="item_thuvien">
      <div className="title_thuvien">
        <span className="title_thuvien_span">{title}</span>
        <div className="filter-container" onClick={handleModalFilter}>
          <CiFilter size={25} />
        </div>
      </div>
      <Table
        loading={isLoading || loadingInSearchTable}
        columns={columns}
        dataSource={transformedData}
        pagination={{
          onChange: (page, pageSize) => {
            if (dataFilter?.data?.results?.length > 0) {
              setPaginationFilter({
                currentPage: page,
                totalCount: dataFilter?.data?.totalCount,
                totalPages: dataFilter?.data?.totalPages,
              });
            } else {
              setPagination({
                currentPage: page,
                pageSize: pageSize,
                totalCount: dataApi?.totalCount,
                totalPages: dataApi?.totalPages,
              });
            }
          },
          pageSize: pagination.pageSize,
          total: dataFilter?.data?.totalCount ?? dataApi?.totalCount,
        }}
      />
    </div>
  );

  const optionsAdmin = [
    {
      value: "2",
      label: "layout2",
    },
    {
      value: "3",
      label: "layout3",
    },
  ];

  const optionsPhongBan = [
    {
      value: "2",
      label: "layout2",
    },
    {
      value: "3",
      label: "layout3",
    },
    {
      value: "4",
      label: "layout4",
    },
  ];

  const optionsHinhCuaLich = [
    {
      value: "5",
      label: "layout5",
    },
  ];

  return (
    <div className="thuvien_contain">
      {isMargee ? (
        <Marquee img={Flag}>
          Trung thành, Dũng cảm - Mưu trí, Sáng tạo - Vì hạnh phúc nhân dân - Vì
          bình yên Thành phố
        </Marquee>
      ) : null}
      <HeaderSearch
        name="Thư Viện"
        addName={addNameTitle}
        onBtnAdd={() => handleThuVienAddModal(addName)}
        type={type}
      />
      {addName === "Bài viết" ? (
        <ModalBlog
          options={checkRole === "DEPARTMENT" ? optionsPhongBan : optionsAdmin}
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          addName={addName}
          loading={loadingModalAdd}
          addNameTitle={addNameTitle}
          labelTitleData={labelTitleData}
          handleModalTitle={handleModalTitle}
          setDescriptionData={setDescriptionData}
          labelDescriptionData={labelDescriptionData}
          handleBaiVietChange={handleBaiVietChange}
        />
      ) : (
        <ModalAddLibrary
          options={
            addName === "Hình của lịch"
              ? optionsHinhCuaLich
              : checkRole === "DEPARTMENT"
              ? optionsPhongBan
              : optionsAdmin
          }
          addNameTitle={addNameTitle}
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          loading={loadingModalAdd}
          handleCancel={handleCancel}
          labelTitleData={labelTitleData}
          handleModalTitle={handleModalTitle}
          setDescriptionData={setDescriptionData}
          uploadProps={uploadProps}
          addName={addName}
          labelDescriptionData={labelDescriptionData}
        />
      )}

      <Tabs onTabClick={handleTabClick}>
        <div label="Banner">
          {renderTabContent(bannerColumns, "Danh sách banner")}
        </div>
        <div label="Bài viết" style={{ color: "gray" }}>
          {renderTabContent(baiVietColumns, "Danh sách bài viết")}
        </div>
        <div label="Video">
          {renderTabContent(videoColumns, "Danh sách video")}
        </div>
        <div label="Hình ảnh">
          {renderTabContent(hinhAnhColumns, "Danh sách hình ảnh")}
        </div>
        <div label="Tiêu đề">
          {renderTabContent(titleColumns, "Danh sách tiêu đề")}
        </div>
        {checkRole !== "ADMIN" ? (
          <div label="Hình của lịch">
            {renderTabContent(calendarColumns, "Danh sách lịch trực")}
          </div>
        ) : null}
      </Tabs>
      {isModalEditOpen && <ModalEditLibrary refetch={refetch} />}
      {isModalFilterOpen && <ModalFilter type={type} />}
      {isModalEditBlogOpen && <ModalEditBlog addName={addName} />}
    </div>
  );
};

export default ThuVien;
