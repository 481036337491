import React, { useState, useEffect } from "react";
import { Dropdown, Menu } from "antd";
import { IoIosArrowDown } from "react-icons/io";
import "./DropdowButton.scss";

function DropdowButton({ ispreview, onButtonKey, activeKey, role }) {
  const [currentLabel, setCurrentLabel] = useState("");

  // Define menu items
  let items;
  if (!ispreview) {
    if (role !== "ADMIN") {
      items = [
        { label: "Tiêu đề", key: "1" },
        { label: "Khu vực phụ bên trái", key: "2" },
        { label: "Khu vực chính", key: "3" },
        { label: "Khu phụ bên phải", key: "4" },
        { label: "Lịch", key: "5" },
      ];
    } else {
      items = [
        { label: "Khu vực phụ bên trái", key: "2" },
        { label: "Khu vực chính", key: "3" },
      ];
    }
  } else {
    items = [
      {
        key: "1",
        label: "FULL HD",
      },
      {
        key: "2",
        label: "FULL HD",
      },
      {
        label: "FULL HD",
        key: "3",
      },
      {
        label: "FULL HD",
        key: "4",
      },
      {
        label: "FULL HD",
        key: "5",
      },
    ];
  }

  // Handle click event on menu item
  const onClick = ({ key }) => {
    const selectedItem = items.find((item) => item.key === key);
    if (selectedItem) {
      setCurrentLabel(selectedItem.label);
      onButtonKey(selectedItem.key);
    }
  };

  // Update label when activeKey changes
  useEffect(() => {
    const selectedItem = items.find((item) => item.key === activeKey);
    if (selectedItem) {
      setCurrentLabel(selectedItem.label);
    }
  }, [activeKey]);

  // Define menu
  const menu = (
    <Menu onClick={onClick}>
      {items.map(item => (
        <Menu.Item key={item.key}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <div className="dropdownss" onClick={(e) => e.preventDefault()}>
        <div className="dropdown_label" style={{ cursor: "pointer" }}>
          {currentLabel}
        </div>
        <div className="dropdown_icon">
          <IoIosArrowDown size={24} />
        </div>
      </div>
    </Dropdown>
  );
}

export default DropdowButton;
