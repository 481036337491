import React from "react";
import "./Login.scss";
import { Button } from "../../components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { login } from "../../services/authServices";
import { removeToken, setRole, setToken } from "../../utils/localStorage";
import { useMutation } from "@tanstack/react-query";
import Notiflix from "notiflix";
import { message } from "antd";
import _ from "lodash";

function Login() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const mutation = useMutation({
    mutationFn: login,
    onSuccess: async (data) => {
      Notiflix.Notify.success("Đăng nhập thành công !!!", {
        timeout: 1000,
        fontFamily: "Montserrat",
        fontSize: "16px",
      });
      const apiResponse = data?.data;
      setToken(apiResponse.access_token);
      setRole(apiResponse.role);
      if (apiResponse.role === "DATAENTRY") {
        navigate("/thuvien");
      } else {
        navigate("/");
      }
      setTimeout(() => removeToken(), 36 * 100 * 1000);
    },
    onError: (error) => {
      console.log(error);
      if (_.has(error.response.data, "message")) {
        return message.error(error.response.data.message);
      }
      return message.error("Sai tài khoản hoặc mật khẩu!!");
      // Notiflix.Notify.failure(
      //   "Đăng nhập thất bại!!!\t Không có quyền truy cập",
      //   {
      //     timeout: 1000,
      //     fontFamily: "Montserrat",
      //     fontSize: "16px",
      //   }
      // );
    },
  });

  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  return (
    <div className="container-login">
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="header">Đăng Nhập</div>
        <div className="input-group">
          <div className="input-field">
            <span>Tên đăng nhập</span>
            <input
              style={{ height: "50px" }}
              type="text"
              placeholder="Nhập tên đăng nhập"
              {...register("username", { required: true })}
            />
            {errors.username?.type === "required" && (
              <p className="error-message">
                Tên đăng nhập không được để trống !!!
              </p>
            )}
          </div>
          <div className="input-field">
            <span>Mật khẩu</span>
            <input
              style={{ height: "50px" }}
              type="password"
              placeholder="Nhập mật khẩu"
              {...register("password", { required: true })}
            />
            {errors.password?.type === "required" && (
              <p className="error-message">Mật khẩu không được để trống !!!</p>
            )}
          </div>
          <div className="forgot-pass">
            <Link to="/forgot-password" className="forgot-btn">
              Quên mật khẩu?
            </Link>
          </div>
        </div>
        <div className="button-login">
          <Button>Đăng nhập</Button>
        </div>
      </form>
    </div>
  );
}

export default Login;
