import { Flex, Input, message, Modal } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useState } from 'react'
import BaiVietComponent from '../../BaiViet/BaiViet'
import { useDispatch, useSelector } from 'react-redux'
import { modalEditBlogSelector, setModalEditLibrary, setModalLoading } from '../../../store/modal/modalSlice'
import { setModalEditBlog } from '../../../store/modal/modalSlice'
import { editCollections } from '../../../services/collectionServices'

function ModalEditBlog({ addName }) {
	const dispatch = useDispatch()
	const { isModalEditBlogOpen: isModalOpen, data } = useSelector(modalEditBlogSelector)
	// console.log(data);
	const [labelTitleData, handleModalTitle] = useState(data?.title ?? '')
	const [labelDescriptionData, setDescriptionData] = useState(data?.description ?? '')
	const [baiVietData, setBaiVietData] = useState(data?.content ?? '')
	const [loading, setLoading] = useState(false)
	// console.log(data);
	const handleBaiVietChange = (data) => {
		setBaiVietData(data)
	}
	const handleOk = async () => {
		setLoading(true)
		const dataSend = {
			title: labelTitleData,
			description: labelDescriptionData,
			content: baiVietData,
		}

		await editCollections(data?.id, dataSend)
			.then((res) => {
				// console.log(res)
				message.success("Sửa bài viết thành công")
				dispatch(setModalEditBlog({ isModalEditBlogOpen: false, data: {} }))
				dispatch(setModalLoading(true))
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
				message.error(`Sửa bài viết thất bại ${err}`, 2000)
				setLoading(false)
			})
	}
	const handleCancel = () => {
		dispatch(setModalEditBlog({ isModalEditBlogOpen: false, data: {} }))
	}

	return (
		<Modal
			title={<span style={{ fontSize: "24px" }}>Chỉnh sửa bài viết</span>}
			open={isModalOpen}
			onOk={handleOk}
			confirmLoading={loading}
			okText={<span>Lưu</span>}
			onCancel={handleCancel}
			cancelButtonProps={{ style: { height: 50, width: 100 } }}
			okButtonProps={{
				style: { height: 50, width: 100, backgroundColor: "#E61D23" },
			}}
			width={"80vw"}
		>
			<div className="modalSection">
				<label className="modalLabel">
					Tiêu đề {addName} <span style={{ color: "red" }}>(*)</span>
				</label>
				<Input
					placeholder="Nhập tiêu đề"
					value={labelTitleData}
					onChange={handleModalTitle}
					required
				/>
			</div>
			<div className="modalSection">
				<label className="modalLabel">Mô tả {addName}</label>
				<Flex vertical gap={32}>
					<TextArea
						onChange={(e) => setDescriptionData(e.target.value)}
						value={labelDescriptionData}
						placeholder="Nhập mô tả bài viết"
						style={{
							height: 120,
							resize: "none",
						}}
					/>
				</Flex>
			</div>
			<div className="modalSection">
				<label className="modalLabel">Nội dung {addName}</label>
				<BaiVietComponent data={baiVietData} onDataChange={handleBaiVietChange} />
			</div>
		</Modal>
	)
}

export default ModalEditBlog