import React from "react";
import { verifyCollection } from "../../services/collectionServices";
import { useDispatch } from "react-redux";
import { libraryRefesh } from "../../store/refesh/refeshSlice";
import Notiflix from "notiflix";

function HandleEevent({ record }) {
   const dispatch = useDispatch();
   const handleClick = (data) => {
      Notiflix.Loading.pulse();
      verifyCollection(data)
         .then((res) => {
            console.log(res);
            dispatch(libraryRefesh());
            Notiflix.Loading.remove();
         })
         .catch((err) => {
            console.log(err);
            Notiflix.Loading.remove();
         });
   };
   return (
      <div style={{ display: "flex", gap: "10px" }}>
         <button
            style={{ padding: "0 10px", fontWeight: "bold" }}
            onClick={() => handleClick({ id: record.id, type: "REJECT" })}>
            Từ chối
         </button>
         <button
            style={{ padding: "0 10px", fontWeight: "bold", backgroundColor: "#E61D23", color: "white" }}
            onClick={() => handleClick({ id: record.id, type: "ACCEPT" })}>
            Đồng ý
         </button>
      </div>
   );
}

export default HandleEevent;
