import { createSlice } from "@reduxjs/toolkit";
import { getScreensList } from "../info/infoThunk";

export const screensSlice = createSlice({
  name: "screens",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {
    screenAdded: (state, action) => {
      state.data.push(action.payload);
    },
    screenUpdated: (state, action) => {
      const { _id, screenName, department_id, departmentName } = action.payload;
      const existingScreen = state.data.find((screen) => screen._id === _id);
      if (existingScreen) {
        existingScreen.screenName = screenName;
        existingScreen.department_id = department_id;
        existingScreen.departmentName = departmentName;
      }
    },
    searchScreen: (state, action) => {
      if (action.payload.trim() === "") {
        state.filteredData = [];
      } else {
        const searchTerm = action.payload.toLowerCase();
        state.filteredData = state.data.filter(
          (screen) =>
            screen.screenName.toLowerCase().includes(searchTerm) ||
            screen.departmentName.toLowerCase().includes(searchTerm)
        );
      }
    },
    clearSearch: (state) => {
      state.filteredData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getScreensList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getScreensList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getScreensList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { screenAdded, searchScreen, clearSearch, screenUpdated } =
  screensSlice.actions;

export default screensSlice.reducer;
