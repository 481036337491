import React, { useState, useRef, useEffect } from "react";
import { Button, Dropdown, Image } from "antd";
import {
   DownOutlined, FullscreenOutlined,
} from "@ant-design/icons";
import "./previewStyle.scss";
import Home from "./components/Home";
import iconTitle from "../../../assets/iconTitle.png";
import { useDispatch, useSelector } from "react-redux";
import { previewLayoutSelector, setPhongbanLayout, setPhongbanSelected } from "../../../store/phongban/phongbanSlice";
import { API_ENDPOINT } from "../../../services/axiosClient";
import _ from "lodash";
import lichTruc from "../../../assets/lichTruc.png";
import lichCongTac from "../../../assets/lichCongTac.png";

const Show = ({ slogan, data, headingName, layoutPosition, layoutTitle, departmentTitle }) => {
   console.log(data);
   return (
      <div className="show">
         {_.isEmpty(data) && _.isEmpty(slogan) && (
            <div className="title">
               <img src={iconTitle} alt="" />
               <div>
                  <span>Không có khung để hiển thị</span>
               </div>
            </div>
         )}
         {departmentTitle && (
            <div className="content-title-layout">
               <span style={{ textAlign: 'center' }}>Tiêu đề phòng ban</span>
               <div className="title-layout">
                  <span
                     style={{
                        whiteSpace: 'pretty',
                        width: '100%',
                        overflowWrap: 'break-word',
                        fontWeight: 600
                     }}>
                     {departmentTitle}
                  </span>
               </div>
            </div>
         )}
         {slogan && headingName && (
            <div className="content-title-layout">
               <span style={{ textAlign: 'center' }}>Tên phòng ban</span>
               <div className="title-layout">
                  <span
                     style={{
                        whiteSpace: 'pretty',
                        width: '100%',
                        overflowWrap: 'break-word',
                        fontWeight: 600
                     }}>
                     {headingName}
                  </span>
               </div>
            </div>
         )}
         {slogan && (
            <div className="content-title-layout">
               <span style={{ textAlign: 'center' }}>Slogan</span>
               <div className="title-layout">
                  <span
                     style={{
                        whiteSpace: 'pretty',
                        width: '100%',
                        overflowWrap: 'break-word',
                        fontWeight: 600
                     }}>
                     {slogan}
                  </span>
               </div>
            </div>
         )}
         {layoutTitle && (
            <div className="content-title-layout">
               <span>Tiêu đề layout {layoutPosition}</span>
               <div className="title-layout">
                  <span
                     style={{
                        whiteSpace: 'pretty',
                        width: '100%',
                        overflowWrap: 'break-word',
                        fontWeight: 600
                     }}>
                     {layoutTitle}
                  </span>
               </div>
            </div>
         )}
         {data?.length > 0 && data?.map((item) => (
            <div className="content">
               {item?.collection?.type === "blog" ? (
                  <>
                     <div className="content-title-layout">
                        <span>Bài viết</span>
                        <div className="title-layout">
                           <span
                              style={{
                                 marginLeft: 10,
                                 display: "-webkit-box",
                                 WebkitLineClamp: 5,
                                 WebkitBoxOrient: "vertical",
                                 overflowY: "scroll",
                                 scrollbarWidth: 'none',
                                 textAlign: "left",
                                 fontWeight: 500,
                                 fontSize: 14
                              }}>
                              {item?.collection?.title}
                           </span>
                        </div>
                     </div>
                  </>
               ) : (
                  <>
                     <div style={{ width: '40%' }}>
                        <Image
                           height={60}
                           width={'100%'}
                           className="image-content"
                           src={`${API_ENDPOINT}collections/download/${item?.collection?.content}`}
                           alt="hình ảnh"
                           style={{ objectFit: "contain" }}
                        />
                     </div>
                     <span style={{ whiteSpace: 'pretty', width: 150, overflowWrap: 'break-word' }}>{headingName}</span>
                  </>
               )}
            </div>
         )
         )}

         {data?.lichTruc && data?.lichCongTac ? (
           <div className="LichPreview-section">
               <div style={{ display: 'flex', gap: 10, flexDirection: 'column', marginTop: 10 }}>
                  <div style={{ display: 'flex', gap: '20px', width: '600px', alignItems: 'center' }}>
                     <Image preview={false} src={lichTruc} width={90} height={90} />
                     <span style={{ whiteSpace: 'nowrap', fontSize: 20 }}>Lịch trực</span>
                  </div>
                  <div style={{ display: 'flex', gap: '20px', width: '600px', alignItems: 'center' }}>
                     <Image preview={false} src={lichCongTac} width={90} height={90} />
                     <span style={{ whiteSpace: 'nowrap', fontSize: 20 }}>Lịch công tác</span>
                  </div>
               </div>
           </div>
         ) : (
            <div className="LichPreview-section">
               {!_.isEmpty(data?.lichTruc) && (
                  <div style={{ display: 'flex', gap: '20px', width: '600px', alignItems: 'center' }}>
                     <Image preview={false} src={lichTruc} width={90} height={90} />
                     <span style={{ whiteSpace: 'nowrap', fontSize: 20 }}>Lịch trực</span>
                  </div>
               )}
               {!_.isEmpty(data?.lichCongTac) && (
                  <div style={{ display: 'flex', gap: '20px', width: '600px', alignItems: 'center' }}>
                     <Image preview={false} src={lichCongTac} width={90} height={90} />
                     <span style={{ whiteSpace: 'nowrap', fontSize: 20 }}>Lịch công tác</span>
                  </div>
               )}
                 {!_.isEmpty(data?.lichDangBang) && (
                  <div style={{ display: 'flex', gap: '20px', width: '600px', alignItems: 'center' }}>
                     <Image preview={false} src={lichCongTac} width={90} height={90} />
                     <span style={{ whiteSpace: 'nowrap', fontSize: 20 }}>Lịch dạng bảng</span>
                  </div>
               )}
            </div>
         )
         }
      </div >
   )
}

function Preview() {
   const dispatch = useDispatch();
   const [activeKey, setActiveKey] = useState("1");
   const [heightBodyPrev, setHeightBodyPrev] = useState(0);

   const previewLayout = useSelector(previewLayoutSelector)
   const slogan = previewLayout?.slogan;
   const headingName = previewLayout?.headingName;
   const departmentTitle = previewLayout?.departmentTitle;
   const layout2 = previewLayout?.layout2;
   const layout3 = previewLayout?.layout3;
   const layout4 = previewLayout?.layout4;
   const layout5 = previewLayout?.layout5;
   console.log(layout5)
   // console.log(layout2, layout3, layout4, layout5)
   const TABS = {
      '1': <Show slogan={slogan} headingName={headingName} departmentTitle={departmentTitle} />,
      '2': <Show data={layout2?.data} headingName={headingName} layoutPosition={layout2?.layout_position} layoutTitle={layout2?.title} />,
      '3': <Show data={layout3?.data} headingName={headingName} layoutPosition={layout3?.layout_position} layoutTitle={layout3?.title} />,
      '4': <Show data={layout4?.data} headingName={headingName} layoutPosition={layout4?.layout_position} layoutTitle={layout4?.title} />,
      '5': <Show data={layout5} headingName={headingName} layoutPosition={layout5?.layout_position} layoutTitle={layout5?.title} />,
   }


   console.log("TABS",TABS);
   

   const bodyPrevRef = useRef(null);

   useEffect(() => {
      const widthBodyPrev = bodyPrevRef?.current?.clientWidth;
      setHeightBodyPrev(widthBodyPrev / 1.725);
   }, [bodyPrevRef]);


   const handleChangeTab = (key) => {
      if (key === "1") {
         setActiveKey("1");
      }
      if (key === "2") {
         setActiveKey("2");
      }
      if (key === "3") {
         setActiveKey("3");
      }
      if (key === "4") {
         setActiveKey("4");
      }
      if (key === "5") {
         setActiveKey("5");
      }
   };

   const items = [
      {
         label: "Tiêu đề",
         key: "1",
      },
      {
         label: "Khu vực phụ bên trái",
         key: "2",
      },
      {
         label: "Khu vực chính",
         key: "3",
      },
      {
         label: "Khu vực phụ bên phải",
         key: "4",
      },
      {
         label: "Lịch",
         key: "5",
      },
   ];
   const label = items.find((item) => item.key === activeKey)?.label;

   const handleMenuClick = (e) => {
      // console.log(e);
      handleChangeTab(e.key);
   };

   const menuProps = {
      items,
      onClick: handleMenuClick,
   };

   return (
      <div className="container">
         <div className="left">
            <div className="heading">
               {/* <div className="fullScreen">
                  <Button icon={<FullscreenOutlined />} iconPosition={"end"}>
                     Xem toàn bộ màn hình
                  </Button>
               </div> */}
               {/* <div className="fullhd">
                  <Dropdown menu={menuProps}>
                     <Button>
                        <Space>
                           Độ phân giải 4k
                           <DownOutlined />
                        </Space>
                     </Button>
                  </Dropdown>
               </div> */}
            </div>
            <div className="body">
               <div
                  className="screenPrev"
                  ref={bodyPrevRef}
               // style={{ height: `${heightBodyPrev}px` }}
               >
                  <Home />
               </div>
            </div>
         </div>
         <div className="right">
            <div className="choselayout">
               <Dropdown menu={menuProps}>
                  <Button onClick={(e) => e.preventDefault()}
                     style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                     }}
                  >
                     <span style={{ fontWeight: 600, fontSize: "16px" }}>{label}</span>
                     <span style={{ fontWeight: 600, fontSize: "16px" }}>
                        <DownOutlined />
                     </span>
                  </Button>
               </Dropdown>
            </div>
            <div className="layoutShow">
               <div className="title">Hiện đang trình chiếu</div>
               {TABS[activeKey]}
            </div>
         </div>
      </div >
   );
}
export default Preview;
