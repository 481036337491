import { useNavigate, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useContext, useState, useEffect } from "react";
import { Select } from "antd";
import { getRole, getToken } from "../utils/localStorage";

import Home from "../pages/Home/Home";
import ThuVien from "../pages/Tab/ThuVien/ThuVien";
import PhongBan from "../pages/Tab/PhongBan/PhongBan";
import Lich from "../pages/Tab/Lich/Lich";
// import DisplayRoutes from "./DisplayRoutes";
import Login from "../pages/Login/Login";
import DisplayRoutes from "./DisplayRoutes";
import ProtectedRoutesAdmin from "./ProtectedRoutesAdmin";
import PhongBanDetails from "../pages/Tab/PhongBan/PhongBanDetails";
import InfoComponent from "../components/Info/InfoComponent";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";


const AppRoutes = () => {
  // console.log("AppRoutes re-render");
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [displayMode, setDisplayMode] = useState(() => {
    // Load displayMode from localStorage if available, default to "web"
    const storedMode = localStorage.getItem("displayMode");
    // console.log(storedMode);
    return storedMode ? storedMode : "web";
  });
  const navigate = useNavigate();
  const token = getToken();
  const role = getRole();

  const { Option } = Select;
  let Routes = PublicRoutes;

  if (token) {
    if (displayMode === "web") {
      Routes = ProtectedRoutes;
      if (role === "ADMIN") {
        Routes = ProtectedRoutesAdmin;
      }
    } else if (displayMode === "display") {
      Routes = DisplayRoutes;
    }
  }
  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type === 1) {
        navigate("/");
      }
    }
  }, [user]);

  const handleModeSelection = (mode) => {
    setDisplayMode(mode);
    localStorage.setItem("displayMode", mode);
  };

  return (
    !loading && (
      <React.Fragment>
        {/* {!token && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              top: "72%",
              left: "50%",
              height: 54,
            }}
          >
            <div style={{ fontSize: 20, padding: 20 }}>
              <span>Vai Trò:</span>
            </div>
            <Select
              defaultValue={displayMode} // Set default value based on displayMode
              style={{ width: "auto", textAlign: "center" }}
              onChange={handleModeSelection}
            >
              <Option value="web">Quản lí</Option>
              <Option value="display">Thiết bị</Option>
            </Select>
          </div>
        )} */}
        <Routes />
      </React.Fragment>
    )
  );
};

export default AppRoutes;
export const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />}>
        <Route path="/phongban" element={<PhongBan />} />
        <Route path="/phongban/info" element={<InfoComponent />} />
        <Route path="/phongbanDetails/:id" element={<PhongBanDetails />} />
        <Route path="/thuvien" element={<ThuVien />} />
        <Route path="/lich" element={<Lich />} />
      </Route>
    </Routes>
  );
};

export const PublicRoutes = () => {
  const isAuthenticated = !!localStorage.getItem("token");
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/"
        element={isAuthenticated ? <Home /> : <Navigate to="/login" />}
      />
      <Route path="/" element={<Navigate to="/login" />} />
    </Routes>
  );
};
