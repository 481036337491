import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, message, Modal, Select } from "antd";
import {
  modalEditUserSelector,
  setModalEditUser,
} from "../../../store/modal/modalSlice";
import classNames from "classnames/bind";
import style from "./ModalEditUser.module.scss";
import { changePasswordUser } from "../../../services/authServices";
import { Option } from "antd/es/mentions";
import { Loading } from "notiflix";
import AxiosClient from "../../../services/axiosClient";
import { addToDepartment } from "../../../services/userServices";
import { getAllDepartment } from "../../../store/info/infoThunk";

const cx = classNames.bind(style);

function ModalEditUser({ editPermission }) {
  const dispatch = useDispatch();
  const { isModalEditUserOpen, data } = useSelector(modalEditUserSelector);

  const [username, setUsername] = useState(data?.name);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordVisible1, setPasswordVisible1] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    dispatch(
      setModalEditUser({
        isModalEditUserOpen: false,
        isModalEditPermissionOpen: false,
        data: {},
      })
    );
  };
  const handleChangeUsername = (e) => {
    // setUsername(e.target.value)
    return;
  };
  const handleChangePassword1 = (e) => {
    setPassword1(e.target.value);
  };
  const handleChangePassword2 = (e) => {
    setPassword2(e.target.value);
  };
  const handleOk = async () => {
    setLoading(true);
    const data = {
      username,
      password: password2,
    };
    if (password1 !== password2) {
      return message.error("Mật khẩu không khớp");
    }
    await changePasswordUser(data)
      .then((res) => {
        setLoading(false);
        // console.log(res);
        message.success("Đổi mật khẩu thành công");
        dispatch(
          setModalEditUser({
            isModalEditUserOpen: false,
            data: {},
          })
        );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error("Đổi mật khẩu thất bại");
      });
  };

  const [userId, setUserId] = useState(data?.key ?? null);
  const [roomId, setRoomId] = useState(null);
  const handleChangeRoom = (value) => {
    setRoomId(value);
  };

  const deparmentList = useSelector((state) => state.searchPhongban.data);

  const onChangeRoom = async () => {
    try {
      Loading.standard("Đang thay đổi phòng ban truy cập...");
      const res = await addToDepartment(userId, roomId);
      if (res.status === 200) {
        Loading.remove();
        message.success("Thay đổi phòng ban truy cập thành công");
        dispatch(
          setModalEditUser({
            isModalEditUserOpen: false,
            data: {},
          })
        );
      }
    } catch (error) {
      Loading.remove();
      message.error("Thay đổi phòng ban truy cập thất bại");
      console.log("Error while trying to change room: ", error);
    }
  };

  useEffect(() => {
    dispatch(getAllDepartment()); // Dispatch the thunk
  }, [dispatch]);
  return (
    <>
      {editPermission === true ? (
        <Modal
          title={
            <p style={{ fontSize: 28, fontWeight: 700 }}>
              Thay đổi phòng ban truy cập
            </p>
          }
          open={true}
          onOk={onChangeRoom}
          onCancel={handleCancel}
          okText="Lưu"
          okButtonProps={{ style: { backgroundColor: "#E61D23" } }}
        >
          <Select
            defaultValue=""
            style={{ width: "100%", marginBottom: 20 }}
            onChange={(value) => handleChangeRoom(value)}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {deparmentList.map((item) => {
              const { _id, departmentName } = item;
              return (
                <Select.Option key={_id} value={_id}>
                  {departmentName}
                </Select.Option>
              );
            })}
          </Select>
        </Modal>
      ) : (
        <Modal
          title={
            <span style={{ fontSize: "26px", fontWeight: "bold" }}>
              Đổi mật khẩu
            </span>
          }
          open={isModalEditUserOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          confirmLoading={loading}
          okText="Đổi mật khẩu"
          width={"60%"}
          okButtonProps={{ style: { backgroundColor: "#E61D23" } }}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <div className={cx("HoVaTen")}>
            {/* <div className={cx("label-input")}>
                  <label>Họ người dùng</label>
                  <Input
                    onChange={handleChangeFirstName}
                    placeholder="Nhập họ người dùng"
                    type="text"
                    value={firstName}
                  />
                  {errors.firstName && (
                    <div className={cx("error")}>* {errors.firstName}</div>
                  )}
                </div> */}
            <div className={cx("label-input")}>
              <label style={{ marginBottom: 5 }}>Username:</label>
              <Input
                onChange={handleChangeUsername}
                placeholder="Nhập tên người dùng đăng nhập"
                type="text"
                value={username}
              />
              {errors.username && (
                <div className={cx("error")}>* {errors.username}</div>
              )}
            </div>
          </div>

          <div className={cx("label-input")}>
            <label style={{ marginBottom: 5 }}>Mật khẩu mới</label>
            <Input.Password
              onChange={handleChangePassword1}
              placeholder="Nhập mật khẩu mới"
              visibilityToggle={{
                visible: passwordVisible1,
                onVisibleChange: setPasswordVisible1,
              }}
              value={password1}
            />
            {errors.password && (
              <div className={cx("error")}>* {errors.password}</div>
            )}
          </div>
          <div className={cx("label-input")}>
            <label style={{ marginBottom: 5 }}>Nhập lại mật khẩu</label>
            <Input.Password
              onChange={handleChangePassword2}
              placeholder="Nhập lại mật khẩu"
              visibilityToggle={{
                visible: passwordVisible2,
                onVisibleChange: setPasswordVisible2,
              }}
              value={password2}
            />
            {errors.password && (
              <div className={cx("error")}>* {errors.password}</div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
}

export default ModalEditUser;
