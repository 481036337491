import React, { useEffect, useId, useState } from "react";
import { Modal, Input, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setModalFilter,
  modalFilterSelector,
} from "../../../store/modal/modalSlice";
import "./ModalFilter.scss";
import dayjs from "dayjs";
import "dayjs/locale/vi";

dayjs.locale("vi");

const ModalFilter = ({ type }) => {
  const dispatch = useDispatch();
  const id = useId();
  const { isModalFilterOpen } = useSelector(modalFilterSelector);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [userName, setUserName] = useState("");

  const handleCancel = () => {
    dispatch(setModalFilter({ isModalFilterOpen: false, data: {} }));
  };
  const handleChooseDate = (e, type) => {
    if (type === "startDate") {
      setStartDate(dayjs(e).format());
    } else {
      setEndDate(dayjs(e).format());
    }
  };

  const handleOk = () => {
    dispatch(
      setModalFilter({
        // isModalFilterOpen: false,
        data: {
          fromDate: startDate,
          toDate: endDate,
          author: userName,
          type: type,
          pagination: {
            page: 1,
            limit: 10,
          },
        },
      })
    );
  };

  // console.log(startDate, endDate);

  return (
    <Modal
      key={id}
      centered
      title={<span style={{ fontSize: "24px" }}>Bộ lọc</span>}
      open={isModalFilterOpen}
      onCancel={handleCancel}
      cancelButtonProps={{ style: { height: 50, width: 100 } }}
      footer={[
        <div className="modal-footer" key={"modalFilterFooter"}>
          <button
            className="modalButtonFilter"
            onClick={handleOk}
            style={{
              height: 40,
              width: 90,
              border: "none",
            }}
          >
            Lọc
          </button>
        </div>,
      ]}
      width={"40vw"}
    >
      <div className="modal-filter-container">
        <div className="modalSection">
          <div className="date-container">
            <label className="modalLabel">Từ ngày</label>
            <DatePicker
              onChange={(e) => handleChooseDate(e, "startDate")}
              className="datepicker"
              value={startDate && dayjs(startDate)}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </div>
          <div className="date-container">
            <label className="modalLabel">Đến ngày</label>
            <DatePicker
              onChange={(e) => handleChooseDate(e, "endDate")}
              className="datepicker"
              value={endDate && dayjs(endDate)}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </div>
        </div>
        <div className="modalSection">
          <div className="date-container">
            <label className="modalLabel">
              Theo tên tài khoản đăng bài viết
            </label>
            <Input
              placeholder="Nhập tên tài khoản đăng bài viết"
              onChange={(e) => setUserName(e.target.value)}
              value={userName}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { ModalFilter };
