import React, { useState, useId } from 'react';
import { Button, ConfigProvider, Modal } from 'antd';
import { modalScheduleSelector, setModalSchedule, setModalLoading, modalLoadingSelector, modalEditSheduleSelector, setModalEditShedule } from '../../../store/modal/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Select, TimePicker, DatePicker, message } from 'antd';
import { deleteSchedule } from '../../../services/scheduleServices';
import './ModalEditSchedule.scss'
import dayjs from 'dayjs';
import 'dayjs/locale/vi'

dayjs.locale('vi')

const ModalEditSchedule = () => {
	const dispatch = useDispatch()
	const { isModalEditShedule, data, id, title } = useSelector(modalEditSheduleSelector)
	const modalEditSchedule = useSelector(modalEditSheduleSelector)
	const modalLoading = useSelector(modalLoadingSelector)

	const [type, setType] = useState(modalEditSchedule?.type)
	const [date, setDate] = useState(data?.date ?? dayjs().format('DD/MM/YYYY'))
	const [start, setStart] = useState(data?.start ?? '00:00')
	const [end, setEnd] = useState(data?.end ?? '00:00')
	const [loading, setLoading] = useState(false)
	const [leader, setLeader] = useState(modalEditSchedule?.leader || '')
	const [squadMember, setSquadMember] = useState(title || modalEditSchedule?.squadMember || '')
	const reverseParseDayShift = (start, end) => {
		if (start === '00:00' && end === '07:30' || start === '17:00' && end === '23:59') {
			return 'Tối';
		} else if (start === '07:30' && end === '11:30') {
			return 'Sáng';
		} else {
			return 'Chiều';
		}
	}
	const [dayShift, setDayShift] = useState(type == 'lịch trực' ? reverseParseDayShift(start, end) : 'Ngày')
	// console.log(modalEditSchedule);
	// console.log(date, start, end);

	const handleCancel = () => {
		dispatch(setModalEditShedule({
			isModalEditShedule: false,
			id: null,
			type: '',
			data: {
				date: null,
				start: null,
				end: null
			}
		}))
	}

	const handleDelete = async () => {
		// console.log(title, type, date, start, end, description, leader);
		setLoading(true)
		await deleteSchedule(id).then((res) => {
			// console.log(res);
			setLoading(false)
			message.success(`Xoá ${type} thành công`)
			dispatch(setModalEditShedule({
				isModalEditShedule: false,
			}))
			dispatch(setModalLoading(false))
		}).catch((err) => {
			console.log(err);
			message.error(`Xoá ${type} thất bại, ${err}!`)
		})
	}

	const handleChangeLeader = (e) => {
		setLeader(e.target.value)
	}

	return (
		<>
			<Modal
				key={data?.id}
				title={<span style={{ fontSize: 24 }}>{title}</span>}
				open={isModalEditShedule}
				onOk={handleDelete}
				confirmLoading={loading}
				onCancel={handleCancel}
				width={800}
				footer={[
					<Button key="back" onClick={handleCancel}>
						Hủy
					</Button>,
					<Button key="submit" type="primary" danger loading={loading} onClick={handleDelete}>
						Xoá
					</Button>,
				]}
			>
				<div className='modal-schedule'>
					<div className='select-container'>
						<span>Loại lịch</span>
						<Input
							// onChange={handleChangeLeader}
							// placeholder={type == 'lịch trực' ? 'Người trực' : 'Người công tác'}
							style={{ marginTop: 10 }}
							value={type}
							contentEditable={false}
							onMouseDown={(e) => e.preventDefault()}
						/>
					</div>
					<div className='description-container'>
						<span>{type == 'lịch trực' ? 'Người trực' : 'Người công tác'}</span>
						<Input
							onChange={handleChangeLeader}
							style={{ marginTop: 10 }}
							placeholder={type == 'lịch trực' ? squadMember : leader}
							value={type == 'lịch trực' ? squadMember : leader}
							contentEditable={false}
							onMouseDown={(e) => e.preventDefault()}
						/>
					</div>
					<div className='choose-date-container'>
						<div className='choose-date-title'>
							<span>Ngày thêm {type}</span>
							{type == 'lịch trực' && (
								<span style={{ alignSelf: 'flex-start', width: '62%' }}>Ca trực</span>
							)}
							{type !== 'lịch trực' && (<span style={{ alignSelf: 'flex-start', width: '62%' }}>Thời gian bắt đầu</span>)}
						</div>
						<div className='choose-date-input'>
							<ConfigProvider>
								<DatePicker
									// onChange={handleChooseDate}
									className='input-choose-date'
									value={dayjs(date, 'DD/MM/YYYY')}
									// inputReadOnly={true}
									allowClear={false}
									contentEditable={false}
									open={false}
									onFocus={(e) => e.target.blur()}
								/>
							</ConfigProvider>
							{type == 'lịch trực' && (
								<Input
									style={{ width: '10%', textAlign: 'center' }}
									value={dayShift}
									contentEditable={false}
									onMouseDown={(e) => e.preventDefault()}
								/>
							)}
							{type !== 'lịch trực' && (
								<>
									<TimePicker
										format={'HH:mm'}
										className='input-choose-date'
										value={dayjs(start, 'hh:mm')}
										inputReadOnly={true}
										allowClear={false}
										onMouseDown={(e) => e.preventDefault()}
										open={false}
									/>
								</>
							)}
						</div>
					</div>
				</div>
			</Modal >
		</>
	);
};

export default ModalEditSchedule;