import { useMemo } from "react";

export const useCalendarEvents = (listSchedule) => {
	const filterNightShift = listSchedule?.filter(item => item.start === '23:58' && item.end === '23:59');
	const renderScheduleNightShift = listSchedule?.map(item => {
		if (item.start != '23:58' && item.end != '23:59') {
			const dateParts = item.date.split('/');
			const [day, month, year] = dateParts.map(Number);

			if (item.start === '11:58' && item.end === '11:59') {
				return {
					id: item._id,
					title: item.squadMember,
					start: new Date(year, month - 1, day, 7, 30),
					end: new Date(year, month - 1, day, 11, 30),
				};
			}
			else {
				return {
					id: item._id,
					title: item.squadMember,
					start: new Date(year, month - 1, day, 13, 0),
					end: new Date(year, month - 1, day, 17, 0),
				};
			}
		}
	});
	return useMemo(() => {
		if (filterNightShift.length > 0 && filterNightShift != []) {
			const splitScheduleShift = []
			filterNightShift?.map(item => {
				const dateParts = item.date.split('/');
				const [day, month, year] = dateParts.map(Number);
				// console.log(item);
				const shift1 = {
					id: item._id,
					title: item.squadMember,
					start: new Date(year, month - 1, day, 17, 0),
					end: new Date(year, month - 1, day, 23, 59),
				}

				const shift2 = {
					id: item._id,
					title: item.squadMember,
					start: new Date(year, month - 1, day + 1, 0, 0),
					end: new Date(year, month - 1, day + 1, 7, 30),
				}
				splitScheduleShift.push(shift1, shift2)
			})
			splitScheduleShift.map(item => {
				return renderScheduleNightShift.push(item)
			})
			// console.log(renderScheduleNightShift);
			return renderScheduleNightShift
		} else {
			return listSchedule?.map((item) => {
				const dateParts = item.date.split('/');
				const [day, month, year] = dateParts.map(Number);

				const startTimeParts = item.start.split(':');
				const [startHour, startMinute] = startTimeParts.map(Number);

				const endTimeParts = item.end.split(':');
				const [endHour, endMinute] = endTimeParts.map(Number);
				// console.log(startHour, startMinute, endHour, endMinute);
				return {
					id: item._id,
					title: item.squadMember,
					start: new Date(year, month - 1, day, startHour, startMinute),
					end: new Date(year, month - 1, day, endHour, endMinute),
					leader: item.leader,
				};
			});
		}
	}, [listSchedule]);
};
