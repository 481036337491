import React from "react";
import { Modal, Input, Upload, Flex, Select } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import Dragger from "antd/es/upload/Dragger";
import { useDispatch } from "react-redux";
import { setLayout } from "../../../store/layouts/LayoutSlice";

const ModalAddLibrary = ({
  addNameTitle,
  isModalOpen,
  handleOk,
  handleCancel,
  labelTitleData,
  handleModalTitle,
  setDescriptionData,
  uploadProps,
  options,
  addName,
  labelDescriptionData,
  loading,
}) => {
  const dispatch = useDispatch();
  const handleLayoutChange = (value) => {
    dispatch(setLayout(value));
  };
  return (
    <Modal
      centered
      confirmLoading={loading}
      title={<span style={{ fontSize: "24px" }}>{addNameTitle}</span>}
      open={isModalOpen}
      onOk={handleOk}
      okText={<span>Lưu</span>}
      onCancel={handleCancel}
      cancelButtonProps={{ style: { height: 50, width: 100 } }}
      okButtonProps={{
        style: { height: 50, width: 100, backgroundColor: "#E61D23" },
      }}
      width={"60vw"}
    >
      <div className="modalSection">
        <label className="modalLabel">
          Tiêu đề {addName == "Tiêu đề" ? "" : addName}{" "}
          <span style={{ color: "red" }}>(*)</span>
        </label>
        <Input
          placeholder="Nhập tiêu đề"
          value={labelTitleData}
          onChange={handleModalTitle}
          required
        />
      </div>
      {addName != "Tiêu đề" && (
        <>
          <div className="modalSection">
            <label className="modalLabel">Mô tả {addName}</label>
            <Flex vertical gap={32}>
              <TextArea
                value={labelDescriptionData}
                onChange={(e) => setDescriptionData(e.target.value)}
                placeholder="Nhập mô tả bài viết"
                style={{
                  height: 120,
                  resize: "none",
                }}
              />
            </Flex>
          </div>
          <div className="modalSection">
            <label className="modalLabel">
              Thêm ảnh {addName != "Hình ảnh" ? addName : null}
            </label>
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Kéo thả hoặc click vào đây để tải ảnh lên
              </p>
              <p className="ant-upload-hint">Hỗ trợ tải lên một tệp duy nhất</p>
            </Dragger>
          </div>
          <Select
            placeholder="Chọn layout"
            style={{
              width: 120,
              height: 50,
              marginTop: 20,
              marginBottom: 20,
            }}
            onChange={(value) => handleLayoutChange(value)}
            options={options}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          />
        </>
      )}
    </Modal>
  );
};

export { ModalAddLibrary };
