import { createSlice } from "@reduxjs/toolkit";
import { getAllDepartment } from "../info/infoThunk";

export const searchPhongBanSlice = createSlice({
  name: "searchPhongban",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {
    departmentAdd: (state, action) => {
      state.data.push(action.payload);
    },
    updateDepartment: (state, action) => {
      const { _id, departmentName, department_id } = action.payload;
      const existingScreen = state.data.find((screen) => screen._id === _id);
      if (existingScreen) {
        existingScreen.department_id = department_id;
        existingScreen.departmentName = departmentName;
      }
    },
    searchDepartment: (state, action) => {
      if (action.payload.trim() === "") {
        state.filteredData = [];
      } else {
        const searchTerm = action.payload.toLowerCase();
        state.filteredData = state.data.filter((screen) => {
          const screenName = screen.screenName ? screen.screenName.toLowerCase() : "";
          const departmentName = screen.departmentName ? screen.departmentName.toLowerCase() : "";
          return screenName.includes(searchTerm) || departmentName.includes(searchTerm);
        });
      }
    },
    clearSearch: (state) => {
      state.filteredData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDepartment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllDepartment.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getAllDepartment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  departmentAdd,
  searchDepartment,
  clearSearch,
  updateDepartment,
} = searchPhongBanSlice.actions;

export default searchPhongBanSlice.reducer;