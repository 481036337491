import AxiosClient from "./axiosClient";

const API_ENDPOINT = "schedules/";

// API Tạo lịch trình
export const createSchedule = (data) => {
  return AxiosClient.post(API_ENDPOINT, data);
};

export const deleteSchedule = (id) => {
  return AxiosClient.delete(API_ENDPOINT + `${id}`);
};

export const getListSchedules = () => {
  return AxiosClient.get(API_ENDPOINT);
};

export const getLichCongTacNgay = (date) => {
  return AxiosClient.get(API_ENDPOINT + `getbydate`, {
    params: { date: date },
  });
};

export const getScheduleTable = () => {
  return AxiosClient.get(API_ENDPOINT + `table`);
};

export const createScheduleTable = (data, options = {}) => {
  return AxiosClient.put(API_ENDPOINT + `table`, { ...data, ...options });
};
