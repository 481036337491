import { useCallback } from 'react';
import dayjs from 'dayjs';
import { setModalEditShedule, setModalSchedule } from '../../../store/modal/modalSlice';

export const useOpenModalSchedule = (dispatch, type, setSelectedDay, listSchedule) => {
	const handleAddSchedule = useCallback((event) => {
		dispatch(
			setModalSchedule({
				isModalShedule: true,
				type,
				data: {
					date: dayjs(event.start).format('DD/MM/YYYY'),
					start: dayjs(event.start).format('HH:mm'),
					end: dayjs(event.end).format('HH:mm'),
					listSchedule
				},
			})
		);
	}, [dispatch, type, listSchedule]);

	const handleClickDay = useCallback((event) => {
		dispatch(
			setModalSchedule({
				isModalShedule: true,
				type,
				data: {
					date: dayjs(event).format('DD/MM/YYYY'),
					start: null,
					end: null,
				},
			})
		);
		setSelectedDay(null);
	}, [dispatch, type, setSelectedDay, listSchedule]);


	const handleEditSchedule = useCallback((event) => {
		// console.log(event);
		dispatch(
			setModalEditShedule({
				id: event?.id,
				title: event?.title,
				leader: event?.leader,
				isModalEditShedule: true,
				type,
				data: {
					date: dayjs(event?.start).format('DD/MM/YYYY'),
					start: dayjs(event?.start).format('HH:mm'),
					end: dayjs(event?.end).format('HH:mm'),
				},
			})
		);
	}, [dispatch, type])

	return { handleAddSchedule, handleClickDay, handleEditSchedule };
};
