import React, { useState, useRef, useEffect } from 'react'
import { Modal, Input, Upload, Flex, Image, message } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import TextArea from "antd/es/input/TextArea";
import Dragger from "antd/es/upload/Dragger";
import './ModalEditLibrary.scss'
import { useDispatch, useSelector } from 'react-redux';
import { modalEditLibrarySelector, setModalEditLibrary, setModalLoading } from '../../../store/modal/modalSlice';
import { editCollections, editFileCollections } from '../../../services/collectionServices';

const ModalEditLibrary = ({ refetch }) => {
	const dispatch = useDispatch()
	const { isModalEditOpen, data } = useSelector(modalEditLibrarySelector);
	const [loading, setLoading] = useState(false);
	const {
		imageUrl, title, description,
		id, content, type,
	} = data;

	const [descriptionData, setDescriptionData] = useState(description);
	const [titleData, setTitleData] = useState(title);
	const [contentData, setContentData] = useState(content);
	const [fileList, setFileList] = useState([]);
	const [imagePreview, setImagePreview] = useState(imageUrl);

	const handleEdit = async () => {
		setLoading(true)
		const dataSend = {
			title: titleData,
			description: descriptionData,
			content: contentData,
			file: fileList[0] ?? null,
		}
		// console.log(dataSend);

		if (data?.type == 'video' || data?.type == 'image' || data?.type == 'banner') {
			await editFileCollections(id, dataSend)
				.then((res) => {
					// console.log(res)
					message.success("Sửa bài viết thành công")
					dispatch(setModalEditLibrary({ isModalEditOpen: false, data: {} }))
					dispatch(setModalLoading(true))
					setLoading(false)
				})
				.catch((err) => {
					console.log(err)
					message.error(`Sửa bài viết thất bại ${err}`, 2000)
					setLoading(false)
				})
		}
		else {
			await editCollections(id, dataSend)
				.then((res) => {
					// console.log(res)
					message.success("Sửa bài viết thành công")
					dispatch(setModalEditLibrary({ isModalEditOpen: false, data: {} }))
					dispatch(setModalLoading(true))
					setLoading(false)
				})
				.catch((err) => {
					console.log(err)
					message.error(`Sửa bài viết thất bại ${err}`, 2000)
					setLoading(false)
				})
		}
	}

	const handleCancel = () => {
		dispatch(setModalEditLibrary({ isModalEditOpen: false, data: {} }))
	}

	const uploadProps = {
		name: "file",
		multiple: false,
		beforeUpload: (file) => {
			const reader = new FileReader();
			reader.onload = () => {
				if (type === "video") {
					setFileList([file]);
				} else {
					setFileList([file]);
					setImagePreview(reader.result);
				}
			};
			reader.readAsDataURL(file);
		},
		onRemove: () => {
			setFileList([]);
		},
		fileList,
	};

	return (
		<Modal
			centered
			title={<span style={{ fontSize: "24px" }}>Chỉnh sửa {type}</span>}
			open={isModalEditOpen}
			onOk={handleEdit}
			okText={<span>Lưu</span>}
			onCancel={handleCancel}
			cancelButtonProps={{ style: { height: 50, width: 100 } }}
			okButtonProps={{
				style: { height: 50, width: 100, backgroundColor: "#E61D23" },
			}}
			confirmLoading={loading}
			width={type === 'title' ? "40vw" : "80vw"}
		>
			<div className="modalSection">
				<label className="modalLabel">
					Tiêu đề {type} <span style={{ color: "red" }}>(*)</span>
				</label>
				<Input
					placeholder="Nhập tiêu đề"
					value={titleData}
					onChange={(e) => setTitleData(e.target.value)}
					required
				/>
			</div>
			{type !== 'title' ? (
				<>
					<div className="modalSection">
						<label className="modalLabel">Mô tả {type}</label>
						<Flex vertical gap={32}>
							<TextArea
								value={descriptionData}
								maxLength={100}
								onChange={(e) => setDescriptionData(e.target.value)}
								placeholder="Nhập mô tả bài viết"
								style={{
									height: 120,
									resize: "none",
								}}
							/>
						</Flex>
					</div>
					<div className="modalSection">
						<label className="modalLabel">Thêm ảnh {type}</label>
						<Dragger {...uploadProps} style={{ position: 'relative' }} >
							{imagePreview && type !== 'video' ? (
								<Image
									preview={false}
									height={200}
									src={imagePreview}
								/>
							) : (
								<div className='video-edit' >
									<video controls className='video-edit-library' onClick={(e) => e.stopPropagation()}>
										<source src={imagePreview} type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								</div>
							)}
							{!imagePreview && (
								<>
									<p className="ant-upload-drag-icon">
										<InboxOutlined />
									</p>
									<p className="ant-upload-text">
										Kéo thả hoặc click vào đây để tải ảnh lên
									</p>
									<p className="ant-upload-hint">Hỗ trợ tải lên một tệp duy nhất</p>
								</>
							)}
						</Dragger>
					</div >
				</>
			) : null}
		</Modal >
	)
}

export { ModalEditLibrary }