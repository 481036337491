import { Flex, Input, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import BaiVietComponent from "../../BaiViet/BaiViet";
import { useDispatch, useSelector } from "react-redux";
import { layoutSelector, setLayout } from "../../../store/layouts/LayoutSlice";

function ModalBlog({
  isModalOpen,
  handleOk,
  handleCancel,
  addName,
  addNameTitle,
  labelTitleData,
  handleModalTitle,
  setDescriptionData,
  labelDescriptionData,
  handleBaiVietChange,
  options,
  loading,
}) {
  const dispatch = useDispatch();
  const layoutSelected = useSelector(layoutSelector);
  console.log("layoutSelected", layoutSelected);
  const handleLayoutChange = (value) => {
    dispatch(setLayout(value));
  };
  return (
    <Modal
      title={<span style={{ fontSize: "24px" }}>{addNameTitle}</span>}
      confirmLoading={loading}
      open={isModalOpen}
      onOk={handleOk}
      okText={<span>Lưu</span>}
      onCancel={handleCancel}
      cancelButtonProps={{ style: { height: 50, width: 100 } }}
      okButtonProps={{
        style: { height: 50, width: 100, backgroundColor: "#E61D23" },
      }}
      width={"80vw"}
    >
      <div className="modalSection">
        <label className="modalLabel">
          Tiêu đề {addName} <span style={{ color: "red" }}>(*)</span>
        </label>
        <Input
          placeholder="Nhập tiêu đề"
          value={labelTitleData}
          onChange={handleModalTitle}
          required
        />
      </div>
      <div className="modalSection">
        <label className="modalLabel">Mô tả {addName}</label>
        <Flex vertical gap={32}>
          <TextArea
            onChange={(e) => setDescriptionData(e.target.value)}
            value={labelDescriptionData}
            placeholder="Nhập mô tả bài viết"
            style={{
              height: 120,
              resize: "none",
            }}
          />
        </Flex>
      </div>
      <div className="modalSection">
        <label className="modalLabel">Nội dung {addName}</label>
        <BaiVietComponent onDataChange={handleBaiVietChange} />
      </div>
      <Select
        defaultValue={""}
        placeholder="Chọn layout"
        style={{
          width: 120,
          height: 50,
          marginTop: 20,
          marginBottom: 20,
        }}
        onChange={(value) => handleLayoutChange(value)}
        options={options}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      />
    </Modal>
  );
}

export default ModalBlog;
