import { createSlice } from '@reduxjs/toolkit'
import { getListSchedulesThunk } from '../schedules/schedulesThunk'

const initialState = {
    listSchedule: []
}

const scheduleSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getListSchedulesThunk.fulfilled, (state, action) => {
            state.listSchedule = action.payload
        })
    }
})

export const listScheduleSelector = (state) => state.schedule.listSchedule

export const { } = scheduleSlice.actions
export default scheduleSlice.reducer;