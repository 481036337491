import AxiosClient from "./axiosClient";

export const getContentById = (data) => {
  const { id, departmentName, image } = data;
  return AxiosClient.get(`departments/${id}`, { departmentName, image });
};

export const getInfoDepartment = () => {
  return AxiosClient.get(`departments/auth`);
};

export const getDepartmentAll = () => {
  return AxiosClient.get(`departments/`);
};

export const putDepartment = (data) => {
  const { id, departmentName, image } = data;
  return AxiosClient.put(`departments/${id}`, { departmentName, image });
};
