import HeaderSearch from "../../../components/Header/HeaderAdmin";
import Marquee from "../../../components/Marquee/Marquee";
import React, { useEffect, useState } from "react";
import Flag from "../../../assets/vietnam.png";
import "./Lich.scss";
import CalendarCustom from "../../../components/Calendar/Calendar";
import Tabs from "./Tabs/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { getListSchedulesThunk } from "../../../store/schedules/schedulesThunk";
import { listScheduleSelector } from "../../../store/schedules/schedulesSlice";
import CalendarTable from "../../../components/Calendar/CalendarTable";
import {
  modalLoadingSelector,
  modalScheduleSelector,
  setModalSchedule,
} from "../../../store/modal/modalSlice";

function Lich(params) {
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState("lichtruc");

  const listSchedule = useSelector(listScheduleSelector);
  const modalLoading = useSelector(modalLoadingSelector);

  // Tối ưu việc lọc danh sách lịch
  const filterSchedules = (type) =>
    listSchedule?.filter((item) => item.type === type);
  const listLichTruc = filterSchedules("lichTruc");
  const listLichCongTac = filterSchedules("lichCongTac");
  // Tối ưu việc xử lý selectedTab
  const addName =
    selectedTab === "lichtruc"
      ? "Thêm lịch trực"
      : selectedTab === "lichcongtac"
      ? "Thêm lịch công tác"
      : "Lưu lịch";
  // Tối ưu việc khởi tạo TABS
  const TABS = {
    lichtruc: <CalendarCustom type="lịch trực" listSchedule={listLichTruc} />,
    lichcongtac: (
      <CalendarCustom type="lịch công tác" listSchedule={listLichCongTac} />
    ),
    lichdangbang: (
      <CalendarTable/>
    ),
  };

  useEffect(() => {
    dispatch(getListSchedulesThunk());
  }, [modalLoading]);

  const handleOpenModalSchedule = (e) => {
    e.stopPropagation();
    const type =
      selectedTab === "lichtruc"
        ? "lịch trực"
        : selectedTab === "lichcongtac"
        ? "lịch công tác"
        : "lịch đạng bảng";
    dispatch(
      setModalSchedule({
        isModalShedule: true,
        type: type,
        data: {},
      })
    );
  };

  return (
    <div className="lich_contain">
      <Marquee img={Flag}>
        Trung thành, Dũng cảm - Mưu trí, Sáng tạo - Vì hạnh phúc nhân dân - Vì
        bình yên Thành phố
      </Marquee>
      <HeaderSearch
        name="Lịch"
        scheduleType={selectedTab}
        addName={addName}
        onBtnAdd={handleOpenModalSchedule}
        type={""}
      />
      <div className="schedule-container">
        <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        {TABS[selectedTab]}
      </div>
    </div>
  );
}

export default Lich;
