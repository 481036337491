import { Button, Image, message, Modal, Spin } from "antd";
import { BsThreeDots } from "react-icons/bs";
import {
  setModalEditLibrary,
  setModalLoading,
  setModalEditBlog,
} from "../../store/modal/modalSlice";
import { useDispatch } from "react-redux";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import {
  deleteCollections,
  sendCollection,
  verifyCollection,
} from "../../services/collectionServices";
import HandleEevent from "./handleEevent";
import { getRole } from "../../utils/localStorage";
import { useEffect, useState } from "react";
import { libraryRefesh } from "../../store/refesh/refeshSlice";
import Notiflix from "notiflix";

const screenWidthPercentage = window.innerWidth * 0.08;

const EditComponent = ({ record, hideAdminAsignment }) => {
  const [checkRole, setCheckRole] = useState("");
  const dispatch = useDispatch();

  const handleEdit = (data) => {
    // console.log("Edit data: ", data);
    if (data?.type == "blog") {
      dispatch(
        setModalEditBlog({
          isModalEditBlogOpen: true,
          data: {
            ...data,
            type: data?.type,
          },
        })
      );
    } else {
      dispatch(
        setModalEditLibrary({
          isModalEditOpen: true,
          data: {
            ...data,
            type: data?.type,
          },
        })
      );
    }
  };

  const handleDelete = async (data) => {
    console.log("Delete data: ", data);
    await deleteCollections(data.id)
      .then((res) => {
        message.success("Xóa thành công");
        dispatch(setModalLoading(true));
      })
      .catch((err) => {
        message.error(`Xóa thất bại ${err}`);
      });
  };

  const handleSendRequest = async (id) => {
    await sendCollection(id)
      .then((res) => {
        console.log(res);
        Notiflix.Notify.success("Gán cho admin thành công");
        dispatch(libraryRefesh());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const handleCheckRole = async () => {
      const checkRole = await getRole();
      setCheckRole(checkRole);
    };
    handleCheckRole();
  }, []);

  return (
    <div style={{ cursor: "pointer" }}>
      <Menu>
        <MenuButton
          height={30}
          as={IconButton}
          aria-label="Options"
          icon={<BsThreeDots />}
          style={{ border: "none", backgroundColor: "transparent" }}
        />
        <MenuList style={{ minWidth: "120px" }}>
          {checkRole !== "ADMIN" ||
            (hideAdminAsignment === true && (
              <MenuItem
                style={{
                  border: "none",
                  borderBottom: "0.5px solid #D6D6D6",
                  borderRadius: "0",
                  width: "160px",
                }}
                onClick={() => handleSendRequest(record.id)}
              >
                Gán cho admin
              </MenuItem>
            ))}

          <MenuItem
            style={{
              border: "none",
              borderBottom: "0.5px solid #D6D6D6",
              borderRadius: "0",
              width: "160px",
            }}
            onClick={() => handleEdit(record)}
          >
            Chỉnh sửa
          </MenuItem>
          <MenuItem
            style={{
              border: "none",
              borderRadius: "0",
            }}
            onClick={() => handleDelete(record)}
          >
            Xóa
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
};

const bannerColumns = [
  { title: "STT", dataIndex: "key", align: "center" },
  {
    title: "Tiêu đề banner",
    dataIndex: "title",
    align: "center",
    render: (text, record) => {
      //  console.log(text);
      return (
        <div
          className="headline"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div>
            <Image
              width={200}
              height={200}
              src={record?.imageUrl}
              alt="Banner"
              loading="lazy"
              fallback="https://via.placeholder.com/150"
            />
          </div>
          <span style={{ marginLeft: 20, width: 700, textAlign: "left" }}>
            {text}
          </span>
        </div>
      );
    },
    sorter: (a, b) => (a.title || "").localeCompare(b.title || ""),
  },
  {
    title: "Người đăng",
    align: "center",
    dataIndex: "author",
    render: (text) => (
      <div className="acc-post">
        <span style={{ marginLeft: 10 }}>{text}</span>
      </div>
    ),
    sorter: (a, b) => a.author.localeCompare(b.author),
  },
  {
    title: "Ngày đăng",
    dataIndex: "createdAt",
    align: "center",
    render: (text) => {
      const date = new Date(text);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    sorter: (a, b) => a.author.localeCompare(b.author),
  },
  {
    dataIndex: "action",
    align: "center",
    render: (_, record) => {
      //  check nếu department_id để biết được bài viết chưa được duyệt
      if (record.department_id.length > 0) {
        return <EditComponent record={record} />;
      } else {
        return <HandleEevent record={record} />;
      }
    },
  },
];

const baiVietColumns = [
  { title: "STT", dataIndex: "key", align: "center" },
  {
    title: "Tiêu đề bài viết",
    dataIndex: "title",
    align: "center",
    width: 200,
    render: (text, record) => (
      <div
        className="headline"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          width: "300px",
        }}
      >
        <span style={{ marginLeft: 10, minWidth: 130, textAlign: "left" }}>
          {text}
        </span>
      </div>
    ),
    sorter: (a, b) => (a.title || "").localeCompare(b.title || ""),
  },
  {
    title: "Mô tả bài viết",
    dataIndex: "title",
    // width: 200,
    align: "center",
    render: (text, record) => {
      return (
        <div
          className="headline"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "300px",
            height: "100px",
          }}
        >
          <span
            style={{
              marginLeft: 10,
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textAlign: "left",
            }}
          >
            {record?.description}
          </span>
        </div>
      );
    },
    sorter: (a, b) => (a.title || "").localeCompare(b.title || ""),
  },
  {
    title: "Tài khoản đăng bài",
    align: "center",
    dataIndex: "author",
    render: (text) => (
      <div className="acc-post">
        <span style={{ marginLeft: 10 }}>{text}</span>
      </div>
    ),
    sorter: (a, b) => (a.title || "").localeCompare(b.title || ""),
  },
  {
    title: "Ngày đăng",
    dataIndex: "createdAt",
    align: "center",
    render: (text) => {
      const date = new Date(text);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    sorter: (a, b) => (a.title || "").localeCompare(b.title || ""),
  },
  {
    dataIndex: "action",
    align: "center",
    render: (_, record) => {
      //  check nếu department_id để biết được bài viết chưa được duyệt
      if (record.department_id.length > 0) {
        return <EditComponent record={record} />;
      } else {
        return <HandleEevent record={record} />;
      }
    },
  },
];

const videoColumns = [
  { title: "STT", dataIndex: "key", align: "center" },
  {
    title: "Tiêu đề video",
    dataIndex: "title",
    align: "center",
    render: (text, record) => {
      // console.log(record);
      return (
        <div
          className="headline"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <video width={200} controls>
              <source src={record?.imageUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <span style={{ marginLeft: 10, width: 130 }}>{text}</span>
        </div>
      );
    },
    sorter: (a, b) => (a.title || "").localeCompare(b.title || ""),
  },
  {
    title: "Thời lượng",
    align: "center",
    render: (text) => (
      <div className="acc-post">
        <span style={{ marginLeft: 10 }}>{text.duration}</span>
      </div>
    ),
    align: "center",
    render: (text) => (
      <div className="acc-post">
        <span style={{ marginLeft: 10 }}>{text.duration}</span>
      </div>
    ),
  },
  {
    title: "Người đăng",
    align: "center",
    dataIndex: "author",
    render: (text) => (
      <div className="acc-post">
        <span style={{ marginLeft: 10 }}>{text}</span>
      </div>
    ),
    sorter: (a, b) => a.author.localeCompare(b.author),
  },
  {
    title: "Ngày đăng",
    dataIndex: "createdAt",
    align: "center",
    render: (text) => {
      const date = new Date(text);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    sorter: (a, b) => a.author.localeCompare(b.author),
  },
  {
    dataIndex: "action",
    align: "center",
    render: (_, record) => {
      //  check nếu department_id để biết được bài viết chưa được duyệt
      if (record.department_id.length > 0) {
        return <EditComponent record={record} />;
      } else {
        return <HandleEevent record={record} />;
      }
    },
  },
];

const hinhAnhColumns = [
  { title: "STT", dataIndex: "key", align: "center" },
  {
    title: "Tiêu đề hình ảnh",
    dataIndex: "title",
    align: "center",
    render: (text, record) => (
      <div
        className="headline"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div>
          <Image
            width={200}
            height={200}
            src={record?.imageUrl}
            alt="Banner"
            loading="lazy"
            fallback="https://via.placeholder.com/150"
          />
        </div>
        <span style={{ marginLeft: 20, width: 700, textAlign: "left" }}>
          {text}
        </span>
      </div>
    ),
    sorter: (a, b) => (a.title || "").localeCompare(b.title || ""),
  },
  {
    title: "Người đăng",
    align: "center",
    dataIndex: "author",
    render: (text) => (
      <div className="acc-post">
        <span style={{ marginLeft: 10 }}>{text}</span>
      </div>
    ),
    sorter: (a, b) => a.author.localeCompare(b.author),
  },
  {
    title: "Ngày đăng",
    dataIndex: "createdAt",
    align: "center",
    render: (text) => {
      const date = new Date(text);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    sorter: (a, b) => a.author.localeCompare(b.author),
  },
  {
    dataIndex: "action",
    align: "center",
    render: (_, record) => {
      //  check nếu department_id để biết được bài viết chưa được duyệt
      if (record.department_id.length > 0) {
        return <EditComponent record={record} />;
      } else {
        return <HandleEevent record={record} />;
      }
    },
  },
];

const titleColumns = [
  { title: "STT", dataIndex: "key", align: "center" },
  {
    title: "Tiêu đề layout",
    dataIndex: "titleLayout",
    align: "center",
    render: (text, record) => (
      <div
        className="headline"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <span
          style={{
            marginLeft: 20,
            width: 700,
            textAlign: "left",
            fontSize: 20,
          }}
        >
          {record?.title}
        </span>
      </div>
    ),
    // sorter: (a, b) => (a.title || "").localeCompare(b.title || ""),
  },
  {
    dataIndex: "action",
    align: "center",
    render: (_, record) => {
      //  check nếu department_id để biết được bài viết chưa được duyệt
      if (record.department_id.length > 0) {
        return <EditComponent record={record} />;
      } else {
        return <HandleEevent record={record} />;
      }
    },
  },
];

const calendarColumns = [
  { title: "STT", dataIndex: "key", align: "center" },
  {
    title: "Hình ảnh",
    dataIndex: "title",
    align: "center",
    render: (text, record) => (
      <div
        className="headline"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div>
          <Image
            width={200}
            height={200}
            src={record?.imageUrl}
            alt="schedule"
            loading="lazy"
            fallback="https://via.placeholder.com/150"
          />
        </div>
        <span style={{ marginLeft: 20, width: 700, textAlign: "left" }}>
          {text}
        </span>
      </div>
    ),
    sorter: (a, b) => (a.title || "").localeCompare(b.title || ""),
  },
  {
    dataIndex: "action",
    align: "center",
    render: (_, record) => {
      //  check nếu department_id để biết được bài viết chưa được duyệt
      if (record.department_id.length > 0) {
        return <EditComponent record={record} hideAdminAsignment={true} />;
      } else {
        return <HandleEevent record={record} />;
      }
    },
  },
];

export {
  bannerColumns,
  baiVietColumns,
  videoColumns,
  hinhAnhColumns,
  titleColumns,
  calendarColumns,
};
