import React from 'react'
import { FaCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { setModalEditShedule } from '../../../store/modal/modalSlice';
import _ from 'lodash'

function ScheduleList({ listSchedule, dateNow, type }) {
	const dispatch = useDispatch()

	const listDateSchedule = listSchedule?.map((item) => item?.date);
	// Sử dụng Set để loại bỏ các ngày trùng lặp
	const uniqueDateSchedule = Array.from(new Set(listDateSchedule));
	// console.log(uniqueDateSchedule);

	const handleChooseDate = (e) => {
		// console.log(e);
		const chosedDate = listSchedule?.find((item) => item?.date === e);
		// console.log(chosedDate);
		dispatch(setModalEditShedule({
			isModalEditShedule: true,
			id: chosedDate?._id,
			leader: chosedDate?.leader,
			squadMember: chosedDate?.squadMember,
			type: type,
			data: {
				date: chosedDate?.date,
				start: chosedDate?.start,
				end: chosedDate?.end
			}
		}))
	}
	// console.log(listSchedule);

	const parseStartTime = (start) => {
		// console.log(start);
		if (start === '11:58') {
			return '07:30';
		} else if (start === '17:58') {
			return '13:00';
		}
		else {
			return '17:00';
		}
	}
	return (
		<div className='list-schedule'>
			{uniqueDateSchedule?.map((date) => (
				<div key={date} className='schedule-item' onClick={() => handleChooseDate(date)}>
					<span className='schedule-item--title'>{dateNow == date ? 'Hôm nay' : 'Ngày'} ({date})</span>
					{listSchedule?.map((item) => {
						// console.log(item);
						if (item?.date === date) {
							return (
								<div className='schedule-item-room' key={item?._id}>
									<FaCircle color='red' />
									<div className='schedule-item-room-body'>
										<span className='schedule-item-room-body--content'>{item.squadMember || item.leader}</span>
										<span className='schedule-item-room-body--time'>{parseStartTime(item?.start)}</span>
									</div>
								</div>
							)
						}
					})}
				</div>
			))}
		</div>
	)
}

export default ScheduleList