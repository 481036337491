import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import style from "./DanhSachphongBan.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";
import { Modal, message } from "antd";
import AxiosClient from "../../../services/axiosClient";
import {
  setPhongban3Selected,
  setPhongban4Selected,
  setPhongban5Selected,
  setPhongbanSelected,
} from "../../../store/phongban/phongbanSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAllDepartment } from "../../../store/info/infoThunk";

const cx = classNames.bind(style);
export default function DanhSachphongBan() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deparmentList = useSelector((state) => state.searchPhongban.data);
  const searchDepartment = useSelector(
    (state) => state.searchPhongban.filteredData
  );

  console.log("deparmentList", deparmentList);
  console.log("searchDepartment", searchDepartment);

  useEffect(() => {
    dispatch(getAllDepartment()); // Dispatch the thunk
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPhongbanSelected([]));
    dispatch(setPhongban3Selected([]));
    dispatch(setPhongban4Selected([]));
    dispatch(setPhongban5Selected([]));
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState("");
  const showModal = (_id) => {
    setId(_id);
    setIsModalOpen(true);
  };

  const deletePhongBan = async () => {
    const res = await AxiosClient.delete(`departments/${id}`);
    if (res.status === 200) {
      message.success("Xóa phòng ban thành công");
      dispatch(getAllDepartment());
    }
  };

  const handleOk = async () => {
    await deletePhongBan();
    navigate("/phongban");
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={cx("container")}>
      {searchDepartment && searchDepartment.length > 0
        ? searchDepartment.map((item) => {
            const { _id, departmentName, image, countScreen } = item;
            return (
              <React.Fragment key={_id}>
                <div
                  className={cx("itemList")}
                  onClick={() => navigate(`/phongbanDetails/${_id}`)}
                >
                  <div className={cx("imgBg")}>
                    <img src={image} alt={departmentName} />
                  </div>
                  <div className={cx("departmentSection")}>
                    <div className={cx("departmentName")}>{departmentName}</div>
                    <div className={cx("numberOfScreen")}>
                      {countScreen} màn hình
                    </div>
                  </div>
                  <div
                    className={cx("deleteDepartment")}
                    onClick={(e) => {
                      e.stopPropagation(); // This stops the event from propagating further.
                      showModal(_id);
                    }}
                  >
                    <FaRegTrashAlt size={24} />
                    <div className={cx("deleteTitke")}>
                      <span>Xóa phòng ban</span>
                    </div>
                  </div>
                </div>
                <Modal
                  title={`Xóa phòng ban`}
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  okText="Xóa"
                  cancelText="Hủy"
                  okButtonProps={{
                    style: { backgroundColor: "#E61D23", color: "white" },
                  }}
                >
                  <p>Bạn có chắc chắn muốn xóa phòng ban này không?</p>
                </Modal>
              </React.Fragment>
            );
          })
        : deparmentList && deparmentList.length > 0
        ? deparmentList.map((item) => {
            const { _id, departmentName, image, countScreen } = item;
            return (
              <React.Fragment key={_id}>
                <div
                  className={cx("itemList")}
                  onClick={() => navigate(`/phongbanDetails/${_id}`)}
                >
                  <div className={cx("imgBg")}>
                    <img src={image} alt={departmentName} />
                  </div>
                  <div className={cx("departmentSection")}>
                    <div className={cx("departmentName")}>{departmentName}</div>
                    <div className={cx("numberOfScreen")}>
                      {countScreen} màn hình
                    </div>
                  </div>
                  <div
                    className={cx("deleteDepartment")}
                    onClick={(e) => {
                      e.stopPropagation(); // This stops the event from propagating further.
                      showModal(_id);
                    }}
                  >
                    <FaRegTrashAlt size={24} />
                    <div className={cx("deleteTitke")}>
                      <span>Xóa phòng ban</span>
                    </div>
                  </div>
                </div>
                <Modal
                  title={`Xóa phòng ban`}
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  okText="Xóa"
                  cancelText="Hủy"
                  okButtonProps={{
                    style: { backgroundColor: "#E61D23", color: "white" },
                  }}
                >
                  <p>Bạn có chắc chắn muốn xóa phòng ban này không?</p>
                </Modal>
              </React.Fragment>
            );
          })
        : null}
    </div>
  );
}
