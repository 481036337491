import React from "react";
import "./ModalLogin.scss";
import { FaCheckCircle } from "react-icons/fa";
export default function ModalLogin({ success, failed, onClose, message }) {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        {success && (
          <div className="success-message">
            <div className="success-icon">
              <FaCheckCircle size={102} />
            </div>
            <div className="success-title-desc">
              <div className="success-title">
                Đã gửi yêu cầu thay đổi mật khẩu
              </div>
              <div className="success-desc">
                Thông báo yêu cầu thay đổi mật khẩu của bạn đã được gửi đến
                Admin. Vui lòng đợi Admin duyệt yêu cầu của bạn nhé.
              </div>
            </div>
          </div>
        )}
        {failed && (
          <div className="failed-message">
            <div className="success-message">
              <div className="failed-icon">
                <FaCheckCircle size={102} />
              </div>
              <div className="success-title-desc">
                <div className="failed-title">Không tìm thấy tài khoản</div>
                <div className="success-desc">
                  {message === "User not found" ? (
                    <>
                      Bạn đã nhập sai{" "}
                      <strong style={{ color: "red" }}>tên đăng nhập</strong>.
                      Hệ thống kiểm tra không thấy tên đăng nhập bạn vừa nhập.
                    </>
                  ) : (
                    message
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
