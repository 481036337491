import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import user from "./user/userSlice";
import schedule from "./schedules/schedulesSlice";
import modal from "./modal/modalSlice";
import phongban from "./phongban/phongbanSlice";
import screens from "./screen/screenSlide";
import NavTabSlice from "./navTab/NavTabSlice";
import refeshReducer from "./refesh/refeshSlice";
import searchPhongbanReducer from "./phongban/searchPhongBanSlice";
import tableReducer from "./table/tableSlice";
import LayoutSlice from "./layouts/LayoutSlice";
export const store = configureStore({
  reducer: {
    phongban,
    searchPhongban: searchPhongbanReducer,
    user,
    schedule,
    modal,
    screens,
    NavTabSlice,
    refesh: refeshReducer,
    table: tableReducer,
    layout: LayoutSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

setupListeners(store.dispatch);
