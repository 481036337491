import { createAsyncThunk } from "@reduxjs/toolkit";
import { getInfoUser } from "../../services/userServices";
import {
  getInfoDepartment,
  getDepartmentAll,
} from "../../services/departmentsServices";
import { getScreens } from "../../services/screenServices";

export const getUserInfo = createAsyncThunk("getUserInfo", async () => {
  const user = await getInfoUser();
  // console.log(user.data);
  return user.data;
});

export const getDepartmentInfo = createAsyncThunk(
  "getDepartmentInfo",
  async () => {
    const department = await getInfoDepartment();
    // console.log(department.data);
    return department.data;
  }
);

export const getScreensList = createAsyncThunk("screens", async () => {
  const screens = await getScreens();
  return screens.data;
});


export const getAllDepartment = createAsyncThunk("getDepartmentAll", async () => {
  try {
    const department = await getDepartmentAll(); // API call
    return department.data;
  } catch (error) {
    console.error("API call error:", error); // Log any errors
    throw error;
  }
});
