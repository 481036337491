import React from "react";
import "./Button.scss";

export const Button = ({ children, ...props }) => {
  return (
    <button
      type="submit"
      className="button"
      style={{ backgroundColor: "#E61D23" }}
      {...props}
    >
      {children}
    </button>
  );
};

export const ButtonAdd = ({ children, ...props }) => {
  return (
    <button type="submit" className="btn-add" {...props}>
      {children}
    </button>
  );
};

export const ButtonLogout = ({ children, ...props }) => {
  return (
    <button className="btn-logout" {...props}>
      {children}
    </button>
  );
};
