import React, { useState, useRef } from "react";
import vector from "../../assets/vector.png";
import { getContentById } from "../../services/contentServices";
import SimpleImageSlider from "react-simple-image-slider";
import AxiosClient from "../../services/axiosClient";

const LayoutSection = ({ label, imageUrls, number, data }) => {
  const [listImage, setListImage] = useState([]);
  const contentItemRef = useRef(null);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // data.forEach((item) => {
  //   getContentById(item._id).then((res) => {
  //     const total = [];
  //     total.push(res.data);
  //     setDataContent(total);
  //   });
  // });

  const resuilt = data.map((item) => {
    return {
      url: `${AxiosClient}collections/${item.collection_id[0].content}`,
    };
  });

  // console.log(contentItemRef.current.clientWidth);

  return (
    <div className={`screenlayout_section screenlayout${number}`}>
      <div className="title_items2">
        <span className="label">{label}</span>
        <img
          className="img_vector"
          width={87}
          height={7}
          src={vector}
          alt="Vector"
        />
      </div>
      <div className="content_item" ref={contentItemRef}>
        <SimpleImageSlider
          width={contentItemRef?.current?.clientWidth ? contentItemRef.current.clientWidth : 410}
          height={400}
          images={resuilt}
          autoPlay={true}
          autoPlayDelay={6.0}
          loop={true}
          // style={{ width: '100%', height: 'auto' }}
        />
      </div>
    </div>
  );
};

export default LayoutSection;
