import { createSlice } from '@reduxjs/toolkit'
import { act } from 'react'

const initialState = {
	modalShedule: {
		isModalShedule: false,
		type: '',
		data: {
			date: null,
			start: null,
			end: null
		}
	},
	modalEditShedule: {
		isModalEditShedule: false,
		id: null,
		type: '',
		data: {
			date: null,
			start: null,
			end: null
		}
	},
	modalEditLibrary: {
		isModalEditOpen: false,
		data: {}
	},
	modalFilter: {
		isModalFilterOpen: false,
		data: {}
	},

	modalEditBlog: {
		isModalEditBlogOpen: false,
		data: {}
	},

	modalEditUser: {
		isModalEditUserOpen: false,
		isModalEditPermissionOpen: false,
		data: {}
	},

	loading: true
}

const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		setModalSchedule(state, action) {
			// console.log(action.payload);
			state.modalShedule = action.payload
		},
		setModalEditLibrary(state, action) {
			// console.log("modalEditLibrary", action.payload);
			state.modalEditLibrary = action.payload
		},
		setModalFilter(state, action) {
			state.modalFilter = action.payload
		},
		setModalLoading(state, action) {
			state.loading = !state.loading
		},
		setModalEditShedule(state, action) {
			state.modalEditShedule = action.payload
		},
		setModalEditBlog(state, action) {
			state.modalEditBlog = action.payload
		},
		setModalEditUser(state, action) {
			state.modalEditUser = action.payload
		},
	},
})

export const modalScheduleSelector = (state) => state.modal.modalShedule
export const modalLoadingSelector = (state) => state.modal.loading
export const modalEditLibrarySelector = (state) => state.modal.modalEditLibrary
export const modalFilterSelector = (state) => state.modal.modalFilter
export const modalEditSheduleSelector = (state) => state.modal.modalEditShedule
export const modalEditBlogSelector = (state) => state.modal.modalEditBlog
export const modalEditUserSelector = (state) => state.modal.modalEditUser
export const modalEditPermissonSelector = (state) => state.modal.modalEditUser.isModalEditPermissionOpen
export const {
	setModalSchedule, setModalLoading, setModalEditLibrary,
	setModalFilter, setModalEditShedule, setModalEditBlog,
	setModalEditUser
} = modalSlice.actions

export default modalSlice.reducer;