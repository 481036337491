import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CgScreen } from "react-icons/cg";
import { IoChevronBack } from "react-icons/io5";
import { Button, Image, message } from "antd";
import classNames from "classnames/bind";
import style from "./HeaderPban.module.scss";
import {
  createLayout,
  postPreviewLayout,
  createLayoutDetail,
} from "../../services/layoutServices";
import { useDispatch, useSelector } from "react-redux";
import {
  phongban3Selector,
  phongban4Selector,
  phongban5Selector,
  phongbanLayoutSelector,
  phongbanSelector,
  postPreviewSelector,
  setPhongban3Selected,
  setPhongban4Selected,
  setPhongban5Selected,
  setPhongbanLayout,
  setPhongbanSelected,
  setPostPreview,
} from "../../store/phongban/phongbanSlice";
import _ from "lodash";

const cx = classNames.bind(style);
function HeaderPBan({
  isDetails,
  pbName,
  timeEnd,
  userName,
  onBtnPost,
  onBtnPreview,
  size,
  showBackButton,
  onBackButtonClick,
  id,
}) {
  const [loading, setLoading] = useState(false);
  const phongbanLayout = useSelector(phongbanLayoutSelector);

  const phongbanSelected = useSelector(phongbanSelector);

  const phongban3Selected = useSelector(phongban3Selector);
  const phongban4Selected = useSelector(phongban4Selector);
  const phongban5Selected = useSelector(phongban5Selector);
  const dispatch = useDispatch();
  useEffect(() => {
    data_send();
  }, [
    phongbanLayout?.slogan,
    phongbanSelected,
    phongban5Selected,
    phongban3Selected,
    phongban4Selected,
  ]);

  const data_send = () => {
    const data = {
      ...phongbanLayout,
      layout: [
        {
          layout_position: 2,
          data: phongbanSelected ? phongbanSelected : [],
        },
        {
          layout_position: 3,
          data: phongban3Selected ? phongban3Selected : [],
        },
        {
          layout_position: 4,
          data: phongban4Selected ? phongban4Selected : [],
        },
        {
          layout_position: 5,
          data: phongban5Selected ? phongban5Selected : [],
        },
      ],
    };
    // console.log(data);
    dispatch(setPostPreview(data));
    return data;
  };

  const handleSubmitLayout = async () => {
    try {
      setLoading(true);
      const res = await createLayoutDetail(id, data_send());
      console.log("ressss", res);
      if (res.status === 200) {
        message.success("Tạo layout thành công");
        setLoading(false);
      }
    } catch (error) {
      message.error(`Mỗi layout chỉ được thêm 1 bài viết hoặc 1 tiêu đề`);
      setLoading(false);
    }
  };

  const checkPhongbanLayout = () => {
    if (!phongbanSelected?.length || !phongban3Selected?.length) {
      return true;
    } else {
      return false;
    }
  };
  // console.log(pbName);
  // console.log(isDetails);
  return (
    <div className={cx("headerContainer")}>
      {showBackButton && (
        <div
          className={cx("back-button")}
          onClick={onBackButtonClick}
          style={{ cursor: "pointer" }}
        >
          <IoChevronBack size={26} style={{ marginRight: "8px" }} />
        </div>
      )}
      <div className={cx("phongban_name")}>
        <span className={cx("name")}>
          <strong>{phongbanLayout.departmentName || pbName}</strong>
        </span>
        <span className={cx("note")}>
          Lần cập nhập cuối {timeEnd} Bởi {userName}
        </span>
      </div>
      <div className={cx("item_right")}>
        {!isDetails && !checkPhongbanLayout() && (
          <div className={cx("item", "preview")}>
            <button className={cx("btn_preview")} onClick={onBtnPreview}>
              Xem trước
              <CgScreen style={{ paddingLeft: 10 }} size={35} />
            </button>
          </div>
        )}
        {pbName && !checkPhongbanLayout() && !loading && (
          <div className={cx("item", "post")}>
            <button className={cx("btn_post")} onClick={handleSubmitLayout}>
              Đăng
            </button>
          </div>
        )}
        {!checkPhongbanLayout() && loading && (
          <div className={cx("item", "post")}>
            <button
              className={cx("btn_post")}
              style={{ backgroundColor: "gray", color: "white" }}
            >
              Đăng
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
export default HeaderPBan;
