import React, { useState } from "react";
import classNames from "classnames/bind";
import style from "./Layout2.module.scss";
import { Image } from "antd";

const cx = classNames.bind(style);
function layout2() {
  return (
    <>
      <div className={cx("LayoutContainer")}>
        <div className={cx("title")}>
          <div className={cx("image")}>
            <Image />
          </div>
          <div className={cx("name")}>
            <span>Tiêu đề khung</span>
          </div>
        </div>
        <div className={cx("status")}>
          <div className={cx("perDay")}>Mỗi ngày</div>
          <div className={cx("fixed")}>Cố định</div>
        </div>
      </div>
    </>
  );
}
export default layout2;
