import React, { useCallback, useEffect, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { Input, message } from "antd";
import classNames from "classnames/bind";
import styles from "./HeaderAdmin.module.scss";
import { getRole } from "../../utils/localStorage";
import { CgScreen } from "react-icons/cg";
import {
  phongban3Selector,
  phongban4Selector,
  phongban5Selector,
  phongbanLayoutSelector,
  phongbanSelector,
  postPreviewSelector,
  setPostPreview,
} from "../../store/phongban/phongbanSlice";
import { useDispatch, useSelector } from "react-redux";
import { IoChevronBack } from "react-icons/io5";
import {
  createLayout,
  createLayoutDetail,
} from "../../services/layoutServices";
import { departmentInfoSelector } from "../../store/user/userSlice";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import AxiosClient from "../../services/axiosClient";
import { clearSearch, searchScreen } from "../../store/screen/screenSlide";
import { activeTabSelector } from "../../store/navTab/NavTabSlice";
import _ from "lodash";
import {
  modalFilterSelector,
  setModalFilter,
} from "../../store/modal/modalSlice";
import { set } from "date-fns";
import { getScreensList } from "../../store/info/infoThunk";
import { searchDepartment } from "../../store/phongban/searchPhongBanSlice";
import { setLoading as setLoadingSearchTable } from "../../store/table/tableSlice";

const cx = classNames.bind(styles);

function HeaderAdmin({
  name,
  addName,
  onBtnAdd,
  onBtnPreview,
  showBackButton,
  onBackButtonClick,
  scheduleType,
  type,
}) {
  const dispatch = useDispatch();
  const role = getRole();

  // const departmentInfo = useSelector(departmentInfoSelector);
  // const postPreview = useSelector(postPreviewSelector);
  const activeTab = useSelector(activeTabSelector);
  const phongbanLayout = useSelector(phongbanLayoutSelector);
  const phongbanSelected = useSelector(phongbanSelector);
  const phongban3Selected = useSelector(phongban3Selector);
  const phongban4Selected = useSelector(phongban4Selector);
  const phongban5Selected = useSelector(phongban5Selector);
  const { data } = useSelector(modalFilterSelector);
  const [search, setSearch] = useState("");
  const [searchLibrary, setSearchLibrary] = useState(data?.title ?? "");
  const [value] = useDebounce(search, 1000);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleSearch = async () => {
      if (activeTab === "") {
        dispatch(searchScreen(value.trim()));
      } else if (activeTab === "phongban") {
        dispatch(searchDepartment(value.trim()));
      }
    };

    handleSearch();
  }, [value, activeTab, dispatch]); // This effect depends on `value` and `activeTab`

  useEffect(() => {
    // console.log("da vao dayyyyyyyyyyyyy");
    data_send();
  }, [
    phongbanLayout?.slogan,
    phongbanSelected,
    phongban5Selected,
    phongban3Selected,
    phongban4Selected,
  ]);

  useEffect(() => {
    setSearchLibrary("");
  }, [type]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const checkPhongbanLayout = () => {
    if (!phongbanSelected?.length || !phongban3Selected?.length) {
      return true;
    } else {
      return false;
    }
  };

  const data_send = () => {
    const data = {
      ...phongbanLayout,
      layout: [
        {
          layout_position: 2,
          data: phongbanSelected ? phongbanSelected : [],
        },
        {
          layout_position: 3,
          data: phongban3Selected ? phongban3Selected : [],
        },
        {
          layout_position: 4,
          data: phongban4Selected ? phongban4Selected : [],
        },
        {
          layout_position: 5,
          data: phongban5Selected ? phongban5Selected : [],
        },
      ],
    };

    dispatch(setPostPreview(data));
    return data;
  };

  const searchDebounce = useCallback(
    _.debounce((text) => {
      if (text.trim() === "") {
        dispatch(
          setModalFilter({
            data: {
              title: "",
              type: type,
              author: null,
              pagination: {
                page: 1,
                limit: 10,
              },
            },
          })
        );
        return;
      }
      dispatch(
        setModalFilter({
          data: {
            title: text,
            type: type,
            author: null,
            pagination: {
              page: 1,
              limit: 10,
            },
          },
        })
      );
      dispatch(setLoadingSearchTable(false));
    }, 2000),
    [type, dispatch]
  );

  const handleSearchLibrary = (e) => {
    const dataSearch = e.target.value;
    setSearchLibrary(dataSearch);
    searchDebounce(dataSearch);
  };

  const handleSubmit = async () => {
    // console.log(phongbanLayout)
    setLoading(true);
    await createLayout(data_send())
      .then((res) => {
        console.log(res);
        message.success("Tạo layout thành công");
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        message.error(`Mỗi layout chỉ được thêm 1 bài viết hoặc 1 tiêu đề`);
      });
  };
  // console.log(addName);
  return (
    <div className={cx("HeaderContainer")}>
      {showBackButton && (
        <div
          className={cx("back-button")}
          onClick={onBackButtonClick}
          style={{ cursor: "pointer" }}
        >
          <IoChevronBack size={26} style={{ marginRight: "8px" }} />
        </div>
      )}
      <div className={cx("name_contain")}>
        <span className={cx("name")}>{name}</span>
      </div>
      <div className={cx("search_contain")}>
        {activeTab === "thuvien" ? (
          <Input
            style={{ fontSize: 16 }}
            placeholder="Tìm kiếm"
            size="large"
            prefix={<IoIosSearch size={16} />}
            value={searchLibrary}
            onChange={(e) => {
              console.log("searching");
              dispatch(setLoadingSearchTable(true));
              handleSearchLibrary(e);
            }}
          />
        ) : activeTab === "phongban" ? (
          <Input
            style={{ fontSize: 16 }}
            placeholder="Tìm kiếm"
            size="large"
            prefix={<IoIosSearch size={16} />}
            value={search}
            onChange={handleSearchChange}
          />
        ) : (
          <Input
            style={{ fontSize: 16 }}
            placeholder="Tìm kiếm"
            size="large"
            prefix={<IoIosSearch size={16} />}
            value={search}
            onChange={handleSearchChange}
          />
        )}
      </div>
      <div className={cx("add_contain")}>
        <div className={cx("item", "add")}>
          <div className={cx("item", "preview")}>
            {name == "Phòng Ban" && !checkPhongbanLayout() && (
              <button className={cx("btn_preview")} onClick={onBtnPreview}>
                <span>Xem trước</span>
                <CgScreen style={{ paddingLeft: 10 }} size={35} />
              </button>
            )}
            {addName === "Thêm màn hình" &&
            role == "DEPARTMENT" ? null : name != "Lịch" ? (
              <button className={cx("btn_add")} onClick={onBtnAdd}>
                <span>{addName}</span>
              </button>
            ) : null}
            {name == "Phòng Ban" && !loading && (
              <button className={cx("btn_add")} onClick={handleSubmit}>
                <span>Đăng</span>
              </button>
            )}
            {name == "Thư viện" ? (
              <button className={cx("btn_add")} onClick={onBtnAdd}>
                <span>{addName}</span>
              </button>
            ) : null}
            {name == "Phòng Ban" && loading && (
              <button
                className={cx("btn_add")}
                style={{ backgroundColor: "gray", color: "white" }}
              >
                <span>Đăng</span>
              </button>
            )}
            {name == "Lịch" && scheduleType == "lichdangbang" ? null : name ==
              "Lịch" ? (
              <button className={cx("btn_add")} onClick={onBtnAdd}>
                <span>{addName}</span>
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default HeaderAdmin;
