import AxiosClient from "./axiosClient";

export const createScreen = (data) => {
  return AxiosClient.post("screens", data);
};

export const updateScreen = (id, data) => {
  return AxiosClient.put(`screens/${id}`, data);
};

export const getScreens = () => {
  return AxiosClient.get("screens");
};

export const delScreen = (id) => {
  return AxiosClient.delete(`screens/${id}`);
};
