import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./Home.scss";
import headLeftLogo from "../../../../assets/header_logo_left.png";
import headRightLogo from "../../../../assets/header_logo_right.png";
import redLine from "../../../../assets/redLine.png";
import bgTitle from "../../../../assets/titile_bg_section.png";
import { getLayouts } from "../../../../services/contentServices";
import { API_ENDPOINT } from "../../../../services/axiosClient";
import { format, startOfWeek, addDays, set } from "date-fns";
import { vi } from "date-fns/locale";
import Notiflix from "notiflix";
import { useDispatch, useSelector } from "react-redux";
import {
  phongbanLayoutSelector,
  postPreviewSelector,
  setPostPreview,
  setPreviewLayout,
} from "../../../../store/phongban/phongbanSlice";
import { postPreviewLayout } from "../../../../services/layoutServices";
import { message } from "antd";
import CalendarTable from "../../../../components/Calendar/CalendarTable";
import { getScheduleTable } from "../../../../services/scheduleServices";
import TextArea from "antd/es/input/TextArea";

function Home() {
  const dispatch = useDispatch();
  const [isShowCalen, setIsShowCalen] = useState(0);
  const [week, setWeek] = useState([]);
  const [timeSlide, setTimeSlide] = useState(20000);
  const postPreview = useSelector(postPreviewSelector);
  const slidesRef = useRef(null);
  const slidesRef3 = useRef(null);
  const slidesRef4 = useRef(null);
  const blogRef = useRef(null);
  const blog_scroolRef = useRef(null);

  const [countSlider2, setCountSlider2] = useState(0);
  const [widthSlide2, setWidthSlide2] = useState(0);

  const [countSlider3, setCountSlider3] = useState(0);
  const [widthSlide3, setWidthSlide3] = useState(0);

  const [countSlider4, setCountSlider4] = useState(0);
  const [widthSlide4, setWidthSlide4] = useState(0);

  const [headingName, setHeadingName] = useState("");
  const [titleDepartment, setTitleDepartment] = useState("");
  const [slogan, setSlogan] = useState("");
  const [layout2, setLayout2] = useState([]);
  const [layout3, setLayout3] = useState([]);
  const [layout4, setLayout4] = useState([]);
  const [layout5, setLayout5] = useState([]);
  const [title2, setTitle2] = useState("");
  const [title3, setTitle3] = useState("");
  const [title4, setTitle4] = useState("");

  const [scheduleTableData, setScheduleTableData] = useState([]);
  const phongbanLayout = useSelector(phongbanLayoutSelector);
  // layout2
  useEffect(() => {
    const interval = setInterval(() => {
      setCountSlider2((prev) =>
        prev >= layout2?.data?.length - 1 ? 0 : prev + 1
      );
    }, timeSlide);

    return () => clearInterval(interval);
  }, [layout2?.data?.length]);

  useEffect(() => {
    console.log("layout5 😘", layout5);
  }, [layout5]);

  useEffect(() => {
    const width = slidesRef.current?.clientWidth / layout2?.data?.length;
    if (countSlider2 == 0) {
      setWidthSlide2(0);
    } else {
      setWidthSlide2(width * countSlider2);
    }
  }, [countSlider2]);
  // layout3
  useEffect(() => {
    const interval = setInterval(() => {
      setCountSlider3((prev) =>
        prev >= layout3?.data?.length - 1 ? 0 : prev + 1
      );
    }, timeSlide);

    return () => clearInterval(interval);
  }, [layout3?.data?.length]);

  useEffect(() => {
    const width = slidesRef3.current?.clientWidth / layout3?.data?.length;
    if (countSlider3 == 0) {
      setWidthSlide3(0);
    } else {
      setWidthSlide3(width * countSlider3);
    }
  }, [countSlider3]);
  // layout4
  useEffect(() => {
    const interval = setInterval(() => {
      setCountSlider4((prev) =>
        prev >= layout4?.data?.length - 1 ? 0 : prev + 1
      );
    }, timeSlide);

    return () => clearInterval(interval);
  }, [layout4?.data?.length]);

  useEffect(() => {
    const width = slidesRef4.current?.clientWidth / layout4?.data?.length;
    if (countSlider4 == 0) {
      setWidthSlide4(0);
    } else {
      setWidthSlide4(width * countSlider4);
    }
  }, [countSlider4]);

  console.log("postPreview", postPreview);

  const getTable = async () => {
    const res = await getScheduleTable();
    console.log("banr", res.data[0]);
    setScheduleTableData(res.data[0]);
    // // Cập nhật dataCalendarTable với dữ liệu từ API
    // if (res.data && res.data.length > 0) {
    //   const apiData = res.data[0].data;
    //   setDataCalendarTable(apiData);
    // }
  };

  // lấy dữ liệu lịch dạng bảng nếu có
  useEffect(() => {
    getTable();
  }, []);

  // console.log("layout5 👍👍👍", layout5);

  // lấy dữ liệu contet của phòng ban
  useEffect(() => {
    const fetchData = async () => {
      await postPreviewLayout(postPreview)
        .then((res) => {
          // console.log(res?.data);
          const layout2 = res?.data.layoutScreen.find(
            (item) => item.layout_position == 2
          );
          const layout3 = res?.data.layoutScreen.find(
            (item) => item.layout_position == 3
          );
          const layout4 = res?.data.layoutScreen.find(
            (item) => item.layout_position == 4
          );
          const layout5 = res?.data.layoutScreen.find(
            (item) => item.layout_position == 5
          );

          console.log("res 🤣", layout5.data[0]);

          // setTimeSlide(res?.data.delayTime * 1000);
          setTitleDepartment(res?.data.departmentTitle);
          setHeadingName(res?.data.departmentName);

          setSlogan(res?.data.slogan);
          setLayout2(layout2);
          setLayout3(layout3);
          setLayout4(layout4);
          if (layout5?.data[0].data) {
            setLayout5(layout5?.data[0].data);
          } else {
            setLayout5(layout5?.data[0]);
          }
          // Notiflix.Loading.remove();
        })
        .catch((err) => {
          if (
            err?.response?.data?.message ==
            "Title or blog item is duplicated in layout"
          ) {
            message.error("Mỗi layout chỉ được thêm 1 bài viết", 5);
          }
          // else {
          //   message.error("Không thể xem !!!");
          // }
          // Notiflix.Loading.remove();
          console.log(err);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (layout2) {
      const titleLayout2 = layout2?.title;
      // console.log(titleLayout2)
      setTitle2(titleLayout2);
    }
    if (layout3) {
      const titleLayout3 = layout3?.title;
      // console.log(titleLayout3)
      setTitle3(titleLayout3);
    }
    if (layout4) {
      const titleLayout4 = layout4?.title;
      // console.log(titleLayout4)
      setTitle4(titleLayout4);
    }

    dispatch(
      setPreviewLayout({
        slogan: slogan,
        headingName: headingName,
        departmentTitle: titleDepartment,
        layout2: {
          ...layout2,
          title: layout2?.title,
        },
        layout3: {
          ...layout3,
          title: layout3?.title,
        },
        layout4: {
          ...layout4,
          title: layout4?.title,
        },
        layout5: layout5,
      })
    );
  }, [layout2, layout3, layout4]);

  // console.log(layout2, layout3, layout4, layout5);

  // xử lý ẩn hiện lịch trực và lịch công tác với nhau
  useEffect(() => {
    let calenderInterval;
    // Ensure layout5 is an object before calling Object.keys
    if (Object.keys(layout5 ?? {}).length > 1) {
      calenderInterval = setInterval(() => {
        setIsShowCalen((prev) => (prev + 1) % 2); // Thay đổi trạng thái tuần tự từ 0 -> 1
      }, 20000);
    } else if (layout5?.lichTruc && !layout5?.lichCongTac) {
      setIsShowCalen(1); // Đặt isShowCalen thành 1 nếu chỉ có lichTruc mà không có lichCongTac
    } else {
      return;
    }

    return () => clearInterval(calenderInterval);
  }, [layout2, layout3, layout4, layout5]);

  console.log("isShowCalen", isShowCalen);

  // lấy các ngày trong tuần hiện tại
  useEffect(() => {
    const today = new Date();
    const start = startOfWeek(today, { weekStartsOn: 1 }); // Tuần bắt đầu từ thứ 2
    const daysOfWeek = [
      "Thứ 2",
      "Thứ 3",
      "Thứ 4",
      "Thứ 5",
      "Thứ 6",
      "Thứ 7",
      "Chủ Nhật",
    ];
    const weekArray = daysOfWeek.map((day, index) => {
      const currentDay = addDays(start, index);
      return {
        day: day,
        date: format(currentDay, "dd/MM/yyyy", { locale: vi }),
      };
    });

    setWeek(weekArray);
  }, []);

  // set height của blog
  const blogHeight = blogRef.current?.clientHeight;
  const blogScroolHeight = blog_scroolRef.current?.clientHeight;
  document.documentElement.style.setProperty("--heightBlog", `${blogHeight}px`);

  return (
    <div className="home-pb">
      <div className="header">
        <div className="header_left">
          <div className="bg"></div>
          <img className="logo" src={headLeftLogo} alt="" />
        </div>
        <div className="header_center">
          <div className="heading">{titleDepartment}</div>
          <div className="title">{headingName}</div>
          <div className="slogan">{slogan}</div>
        </div>
        <div className="header_right">
          <div className="bg"></div>
          <img className="logo" src={headRightLogo} alt="" />
        </div>
      </div>
      <div className="body">
        <div className="body_section">
          {layout2?.data?.length > 0 && (
            <div className="layout2">
              <div className="heading">
                <img className="bg" src={bgTitle} />
                <div className="headingBox">{title2}</div>
                <img className="redLine" src={redLine} alt="" />
              </div>
              <div className="body">
                <div className="slider">
                  <div
                    className="slides"
                    ref={slidesRef}
                    style={{
                      width: `${100 * layout2?.data?.length}%`,
                      transform: `translateX(-${widthSlide2}px)`,
                    }}
                  >
                    {layout2?.data?.map((item, index) => {
                      return (
                        <div className="slide" key={index}>
                          {item?.collection?.type === "blog" ? (
                            <div className="blog" ref={blogRef}>
                              <div
                                className="blog_scrool"
                                ref={blog_scroolRef}
                                style={{
                                  animationDuration: `${
                                    blogScroolHeight * 0.1
                                  }s`,
                                }}
                              >
                                <div className="title">
                                  {item.collection.title}
                                </div>
                                <div className="description">
                                  {item.collection.description}
                                </div>
                                <div
                                  className="content"
                                  dangerouslySetInnerHTML={{
                                    __html: item.collection.content,
                                  }}
                                />
                              </div>
                            </div>
                          ) : item?.collection?.type === "image" ||
                            item?.collection?.type === "banner" ? (
                            <img
                              src={`${API_ENDPOINT}collections/download/${item.collection?.content}`}
                              alt="hình ảnh"
                            />
                          ) : (
                            <video autoPlay muted loop>
                              <source
                                src={`${API_ENDPOINT}collections/download/${item.collection?.content}`}
                                type="video/mp4"
                              />
                            </video>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
          {layout3?.data?.length > 0 && (
            <div className="layout3">
              <div className="heading">
                <img className="bg" src={bgTitle} />
                <div className="headingBox">{title3}</div>
                <img className="redLine" src={redLine} alt="" />
              </div>
              <div className="body">
                <div className="slider">
                  <div
                    className="slides"
                    ref={slidesRef3}
                    style={{
                      width: `${100 * layout3?.data?.length}%`,
                      transform: `translateX(-${widthSlide3}px)`,
                    }}
                  >
                    {layout3?.data?.map((item, index) => {
                      return (
                        <div className="slide" key={index}>
                          {item?.collection?.type === "blog" ? (
                            <div className="blog" ref={blogRef}>
                              <div
                                className="blog_scrool"
                                ref={blog_scroolRef}
                                style={{
                                  animationDuration: `${
                                    blogScroolHeight * 0.1
                                  }s`,
                                }}
                              >
                                <div className="title">
                                  {item.collection?.title}
                                </div>
                                <div className="description">
                                  {item.collection?.description}
                                </div>
                                <div
                                  className="content"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.collection?.content,
                                  }}
                                />
                              </div>
                            </div>
                          ) : item?.collection?.type === "image" ||
                            item?.collection?.type === "banner" ? (
                            <img
                              src={`${API_ENDPOINT}collections/download/${item?.collection?.content}`}
                              alt="hình ảnh"
                            />
                          ) : (
                            <video autoPlay muted loop>
                              <source
                                src={`${API_ENDPOINT}collections/download/${item?.collection?.content}`}
                                type="video/mp4"
                              />
                            </video>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
          {layout4?.data?.length > 0 && (
            <div className="layout4">
              <div className="heading">
                <img className="bg" src={bgTitle} />
                <div className="headingBox">{title4}</div>
                <img className="redLine" src={redLine} alt="" />
              </div>
              <div className="body">
                <div className="slider">
                  <div
                    className="slides"
                    ref={slidesRef4}
                    style={{
                      width: `${100 * layout4?.data.length}%`,
                      transform: `translateX(-${widthSlide4}px)`,
                    }}
                  >
                    {layout4.data?.map((item, index) => {
                      return (
                        <div className="slide" key={index}>
                          {item?.collection?.type === "blog" ? (
                            <div className="blog" ref={blogRef}>
                              <div
                                className="blog_scrool"
                                ref={blog_scroolRef}
                                style={{
                                  animationDuration: `${
                                    blogScroolHeight * 0.1
                                  }s`,
                                }}
                              >
                                <div className="title">
                                  {item.collection?.title}
                                </div>
                                <div className="description">
                                  {item.collection?.description}
                                </div>
                                <div
                                  className="content"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.collection?.content,
                                  }}
                                />
                              </div>
                            </div>
                          ) : item?.collection?.type === "image" ||
                            item?.collection?.type === "banner" ? (
                            <img
                              src={`${API_ENDPOINT}collections/download/${item?.collection?.content}`}
                              alt="hình ảnh"
                            />
                          ) : (
                            <video autoPlay muted loop>
                              <source
                                src={`${API_ENDPOINT}collections/download/${item?.collection?.content}`}
                                type="video/mp4"
                              />
                            </video>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {layout5 && (
          <div className="body_calender">
            {layout5?.lichDangBang ? (
              <div className={`layout5 show`}>
                <div className="heading">{scheduleTableData.title}</div>
                <div className="calender">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <div>
                            <span>Nội dung</span>
                          </div>
                        </th>
                        {week.map((item, index) => (
                          <th key={index}>
                            <div>
                              <span>{item.day}</span>
                              <span>{item.date}</span>
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {scheduleTableData.data.map((row, rowIndex) => (
                        <tr key={rowIndex} className={"table-row"}>
                          {row.map((col, colIndex) => (
                            <td key={colIndex} className={"table-items"}>
                              {col}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <>
                {layout5?.lichCongTac && isShowCalen === 0 && (
                  <div className={`layout5 show`}>
                    <div className="heading">Lịch công tác</div>
                    <div className="calender">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <div>
                                <span>Nội dung</span>
                              </div>
                            </th>
                            {week.map((item, index) => (
                              <th key={index}>
                                <div>
                                  <span>{item.day}</span>
                                  <span>{item.date}</span>
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Lịch sáng</td>
                            {layout5.lichCongTac.map((item, index) => (
                              <td key={index}>{item[0]}</td>
                            ))}
                          </tr>
                          <tr>
                            <td>Lịch chiều</td>
                            {layout5.lichCongTac.map((item, index) => (
                              <td key={index}>{item[1]}</td>
                            ))}
                          </tr>
                          <tr>
                            <td>Lãnh đạo trực</td>
                            {layout5.lichCongTac.map((item, index) => (
                              <td key={index}>{item[2]}</td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {layout5?.lichTruc && isShowCalen === 1 && (
                  <div className={`layout5 show`}>
                    <div className="heading">Lịch trực</div>
                    <div className="calender">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <div>
                                <span>Nội dung</span>
                              </div>
                            </th>
                            {week.map((item, index) => (
                              <th key={index}>
                                <div>
                                  <span>{item.day}</span>
                                  <span>{item.date}</span>
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Ca sáng</td>
                            {layout5.lichTruc.map((item, index) => (
                              <td key={index}>{item[0]}</td>
                            ))}
                          </tr>
                          <tr>
                            <td>Ca chiều</td>
                            {layout5.lichTruc.map((item, index) => (
                              <td key={index}>{item[1]}</td>
                            ))}
                          </tr>
                          <tr>
                            <td>Ca tối</td>
                            {layout5.lichTruc.map((item, index) => (
                              <td key={index}>{item[2]}</td>
                            ))}
                          </tr>
                          <tr>
                            <td>Chỉ huy trực</td>
                            {layout5.lichTruc.map((item, index) => (
                              <td key={index}>{item[3]}</td>
                            ))}
                          </tr>
                          <tr>
                            <td>Lãnh đạo trực</td>
                            {layout5.lichTruc.map((item, index) => (
                              <td key={index}>{item[4]}</td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {!(layout5?.lichCongTac && isShowCalen === 0) &&
                  !(layout5?.lichTruc && isShowCalen === 1) && (
                    <div className="ScheduleImage">
                      <img
                        className="ScheduleImage-img"
                        src={`${API_ENDPOINT}collections/download/${layout5?.content}`}
                      />
                    </div>
                  )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
