import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import ThuVien from "../pages/Tab/ThuVien/ThuVien";
import { useState } from "react";
import PhongBan from "../pages/Tab/PhongBan/PhongBan";
import PhongBanDetails from "../pages/Tab/PhongBan/PhongBanDetails";
import InfoComponent from "../components/Info/InfoComponent";
import BaoCao from "../pages/Tab/BaoCao/BaoCao";

const ProtectedRoutesAdmin = () => {
  const [permission, setPermission] = useState("admin");
  return (
    <Routes>
      <Route path="/" element={<Home />}>
        {/* <Route path="/manhinh" element={<ManHinh />} /> */}
        <Route path="/admin/info" element={<InfoComponent />} />
        <Route path="/phongban" element={<PhongBan />} />
        <Route path="/phongbanDetails/:id" element={<PhongBanDetails />} />
        <Route path="/thuvien" element={<ThuVien />} />
        <Route path="/baoCao" element={<BaoCao />} />
      </Route>
    </Routes>
  );
};

export default ProtectedRoutesAdmin;
