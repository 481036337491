import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  layoutSelected: "",
};

const LayoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setLayout: (state, action) => {
      state.layoutSelected = action.payload; 
    },
  },
});

export const layoutSelector = (state) => state.layout.layoutSelected;

export const { setLayout } = LayoutSlice.actions;
export default LayoutSlice.reducer;