import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames/bind";
import style from "./BaoCao.module.scss";
import { Button, Table } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import Marquee from "../../../components/Marquee/Marquee";
import Flag from "../../../assets/vietnam.png";
import { exportExcel, showReport } from "../../../services/reportServices";
import Notiflix, { Loading } from "notiflix";

const cx = classNames.bind(style);
export default function BaoCao() {
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      align: "center",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "title",
      align: "center",
    },
    {
      title: "Loại nội dung",
      dataIndex: "contentType",
      key: "contentType",
      align: "center",
    },
    {
      title: "Thao tác",
      dataIndex: "action",
      key: "action",
      align: "center",
    },
    {
      title: "Ngày đăng bài",
      dataIndex: "updatedDate",
      key: "updatedDate",
      align: "center",
    },
    {
      title: "Tài khoản đăng bài",
      dataIndex: "uploadAccount",
      key: "uploadAccount",
      align: "center",
    },
  ];

  const [showData, setShowData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  // lấy ngày đầu tháng
  function firstDayOfMonth() {
    const date = new Date();
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const day = firstDayOfMonth.getDate();
    const month = (firstDayOfMonth.getMonth() + 1).toString().padStart(2, "0"); // Thêm số 0 vào trước nếu tháng < 10
    const year = firstDayOfMonth.getFullYear();

    const formattedDate = `${year}-${month}-${day}`;
    setFromDate(formattedDate);
  }

  // lấy ngày cuối tháng
  function lastDayOfMonth() {
    const date = new Date();
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const day = lastDayOfMonth.getDate();
    const month = (lastDayOfMonth.getMonth() + 1).toString().padStart(2, "0"); // Thêm số 0 vào trước nếu tháng < 10
    const year = lastDayOfMonth.getFullYear();

    const formattedDate = `${year}-${month}-${day}`;
    setToDate(formattedDate);
  }

  const params = {
    toDate: fromDate,
    fromDate: toDate,
    limit,
    page,
  };

  console.log("limit", limit);

  // Lấy dữ liệu từ API
  const getReport = async () => {
    try {
      Loading.circle("Đang tải dữ liệu...");
      const response = await showReport(params);
      console.log("response", response.data.logs.length);

      if (response.status === 200) {
        setShowData(response.data.logs);
        Loading.remove();
        // Tăng limit lên nếu như số lượng dữ liệu lấy về bằng limit
        if (response.data.logs.length >= limit) {
          setLimit((prev) => prev + 100);
        }
      }
    } catch (error) {
      Loading.remove();
      console.log("Error: when fetching report data", error);
      throw Error("Error: when fetching report data");
    }
  };

  // Các hàng dữ liệu
  const rowData = showData.map((item, index) => {
    const { typeLog, description, createdAt, user, department } = item;
    return {
      key: index,
      stt: index + 1,
      title: description,
      contentType: typeLog,
      action: department,
      // format ngày tháng
      updatedDate: new Date(createdAt).toLocaleDateString("vi-VN"),
      uploadAccount: user,
    };
  });

  useCallback(() => {
    firstDayOfMonth();
    lastDayOfMonth();
  }, []);

  useEffect(() => {
    getReport();
  }, [limit]);

  const handleDownloadExcel = async () => {
    try {
      Loading.circle("Đang tải xuống dữ liệu...");
      const res = await exportExcel();

      if (res.status === 200) {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `BaoCao.xlsx`);

        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url); // Clean up the blob
        Loading.remove();
      } else {
        throw new Error("Failed to download file");
      }
    } catch (error) {
      Loading.remove();
      console.log("Error when download report file", error);
    }
  };

  return (
    <div className={cx("container")}>
      <Marquee img={Flag}>
        Trung thành, Dũng cảm - Mưu trí, Sáng tạo - Vì hạnh phúc nhân dân - Vì
        bình yên Thành phố
      </Marquee>
      <div className={cx("header")}>
        <div className={cx("title")}> Thống kê</div>
        <div className={cx("export-excel")}>
          <Button
            onClick={() => handleDownloadExcel()}
            shape="default"
            type="link"
            icon={<DownloadOutlined />}
            style={{
              backgroundColor: "#E61D23",
              color: "white",
              borderRadius: "8px",
              padding: "20px 10px",
            }}
          >
            <strong className={cx("export-title")}>
              Xuất file Excel thống kê
            </strong>
          </Button>
        </div>
      </div>
      <div className={cx("preview-section")}>
        <Table
          columns={columns}
          dataSource={rowData}
          pagination={{ pageSize: 10 }}
        />
      </div>
    </div>
  );
}
