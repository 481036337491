import React from "react";
import classNames from "classnames/bind";
import style from "./AccessComponent.module.scss";
const cx = classNames.bind(style);

export default function AccessComponent() {
  return (
    <div className={cx("container")}>
      <div className={cx("user-info-section")}>
        <div className={cx("header-section")}>
          <div className={cx("title-desc")}>
            <div className={cx("title")}>
              <span>Quyền truy cập</span>
            </div>
            <div className={cx("desc")}>
              <span>
                Sắp xếp mọi người thành các nhóm và phân công vai trò để dễ dàng
                quản lý quyền truy cập của họ .
              </span>
            </div>
          </div>
        </div>
        <div className={cx("perm")}>
          <div className={cx("perm-type")}>
            <span style={{ fontWeight: "bold", marginBottom: "10px" }}>
              2 loại quyền truy cập
            </span>
            <span style={{ color: "gray", fontWeight: "bold" }}>Tên</span>
          </div>

          <div className={cx("perm-details")}>
            <div className={cx("perm-title")}>
              <span style={{ fontWeight: "bold", color: "gray" }}>
                Chi tiết quyền
              </span>
            </div>
          </div>
        </div>
        {/* quyền quản trị */}
        <div className={cx("perm")}>
          <div className={cx("perm-type")}>
            <span style={{ fontWeight: "bold" }}>Nhóm quản trị</span>
          </div>

          <div className={cx("perm-details")}>
            <div className={cx("perm-title")}>
              <span>
                Quản trị chung hệ thống, được phép đăng nhập, thêm, xóa, sửa đổi
                thông tin người dùng. Thay đổi giao diện của các phòng ban. Quản
                trị thiết bị kết nối đến hệ thống
              </span>
            </div>
          </div>
        </div>

        {/* quyền quản lý phòng ban */}
        <div className={cx("perm")}>
          <div className={cx("perm-type")}>
            <span style={{ fontWeight: "bold" }}>Nhóm quản lý phòng ban</span>
          </div>

          <div className={cx("perm-details")}>
            <div className={cx("perm-title")}>
              <span>
                Đăng những nội dung sử dụng cho phòng ban. Thêm lịch trực cho
                phòng ban. Gán những bài đăng cũng như lịch trực lên màn hình
                của phòng ban, có thể chỉnh sửa hoặc xóa những bài đăng đã gán
                lên màn hình của phòng ban. Riêng những bài đăng do quản trị đã
                gán lên màn hình.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
