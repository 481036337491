import React, { useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./CensorRequest.module.scss";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { BsThreeDots } from "react-icons/bs";
import { Button, Table } from "antd";
import {
  acceptRequestPassword,
  getRequestPassword,
} from "../../../../services/userServices";
import { Notify } from "notiflix";

const cx = classNames.bind(styles);
export default function CensorRequest() {
  const [request, setRequest] = React.useState(0);
  const [data, setData] = React.useState([]);
  //   các cột của bảng
  const columns = [
    {
      title: "Tên",
      dataIndex: "username",
      key: "username",
      align: "center",
    },
    {
      title: "Quyền truy cập",
      dataIndex: "role",
      key: "role",
      align: "center",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      align: "center",
    },
  ];

  // Lấy dữ liệu từ API
  useEffect(() => {
    handleGetRequest();
  }, []);

  async function handleGetRequest() {
    try {
      // Lấy dữ yêu cầu từ API
      const res = await getRequestPassword();
      setData(res.data);
    } catch (error) {
      console.log("Error when get request from API: ", error);
    }
  }

  //   Hàm xử lý khi người dùng từ chối yêu cầu cấp lại mật khẩu
  const handleReject = async (username) => {
    const res = await acceptRequestPassword(username, "REJECT");
    if (res.status === 200) {
      Notify.success(res.data.message);
      handleGetRequest();
    }
  };

  //  Hàm xử lý khi người dùng đồng ý yêu cầu cấp lại mật khẩu
  const handleAccept = async (username) => {
    const res = await acceptRequestPassword(username, "ACCEPT");
    if (res.status === 200) {
      Notify.success(res.data.message);
      handleGetRequest();
    }
  };

  // chuyển đôi dữ liệu để hiển thị lên bảng
  const transformedData = data.map((item, index) => {
    const { _id, username, password } = item;
    return {
      key: _id,
      username,
      role: <div className={cx("role-style")}>QL phòng ban</div>,
      action: (
        <div className={cx("button-section")}>
          <Button size="middle" onClick={() => handleReject(username)}>
            Từ chối
          </Button>
          <Button
            onClick={() => handleAccept(username)}
            size="middle"
            type="primary"
            style={{
              marginLeft: "8px",
              backgroundColor: "#E61D23",
              color: "white",
            }}
          >
            Đồng ý
          </Button>
        </div>
      ),
    };
  });

  return (
    <div className={cx("container")}>
      <div className={cx("user-info-section")}>
        <div className={cx("header-section")}>
          <div className={cx("title-desc")}>
            <div className={cx("title")}>
              <span>Duyệt mật khẩu</span>
            </div>
            <div className={cx("desc")}>
              <span>
                Duyệt yêu cầu cấp lại mật khẩu của các tài khoản trong hệ thống
              </span>
            </div>
          </div>
        </div>
        <div className={cx("perm")}>
          <div className={cx("perm-search")}>
            <input placeholder="Tìm kiếm" />
          </div>
        </div>

        {/* Số lượng yêu cầu cấp lại mật khẩu */}
        <div className={cx("counterRequest-section")}>
          <div className={cx("counterRequest")}>
            {request} yêu cầu cấp lại mật khẩu.
          </div>
        </div>

        {/* Bảng hiển thị thông tin tài khoản yêu cầu cấp lại mật khẩu */}
        <div className={cx("userList-table")}>
          <Table
            dataSource={transformedData}
            columns={columns}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
}
