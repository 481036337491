import React, { useCallback, useState } from "react";
import "./Tabs.scss";
import { Button, message } from "antd";
import { useSelector } from "react-redux";
import { phongbanLayoutSelector } from "../../store/phongban/phongbanSlice";
import { createLayout } from "../../services/layoutServices";

const Tabs = ({
  children,
  isAdmin,
  vertical,
  defaultActiveTab = 0,
  bgWhite = false,
  onTabClick,
}) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const tabsClass = vertical ? "tabs-vertical" : "";

  const handleTabClick = useCallback(
    (index, isDisabled, label) => {
      if (!isDisabled) {
        setActiveTab(index);
        if (onTabClick) {
          onTabClick(label);
        }
      }
    },
    [onTabClick]
  );

  const tabs = children
    ? React.Children.map(children, (tab, index) => {
        if (!tab) return null;
        const { disabled: isDisabled, label, children: tabContent } = tab.props;
        const isActive = index === activeTab;
        const tabClasses = `tab-item ${isActive ? "active" : ""} ${
          isDisabled ? "disabled" : ""
        }`;
        return {
          nav: (
            <div
              key={`nav-${index}`}
              className={tabClasses} // Add 'disabled' class if the tab is disabled
              onClick={() => handleTabClick(index, isDisabled, label)}
            >
              {label}
            </div>
          ),
          content: isActive ? (
            <div key={`content-${index}`} className="tab-pane active">
              {tabContent}
            </div>
          ) : null,
        };
      })
    : [];

  const containerClass = isAdmin ? "tabs-admin-container" : "tabs-container";
  const navClass = isAdmin ? "tabs-admin-nav" : "tabs-nav";

  const phongbanLayout = useSelector(phongbanLayoutSelector);

  return (
    <div className={containerClass}>
      <div className={navClass}>{tabs.map((tab) => tab.nav)}</div>
      {isAdmin && (
        <div className={"uploadBtn"}>
          {/* <Button
            style={{
              backgroundColor: "#E61D23",
              color: "white",
              width: "200px",
              padding: "12px",
              marginTop: "7px",
            }}
            onClick={handleSubmitLayout}
          >
            Đăng
          </Button> */}
        </div>
      )}
      <div
        className="tab-content"
        style={bgWhite ? { backgroundColor: "white" } : {}}
      >
        {tabs.find((tab, index) => index === activeTab)?.content}
      </div>
    </div>
  );
};

export default Tabs;
