import React, { useEffect, useState, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import classNames from "classnames/bind";
import style from "./BaiViet.module.scss";

const cx = classNames.bind(style);

const BaiVietComponent = ({ data, onDataChange }) => {
  const [value, setValue] = useState(data ?? "");
  const quillRef = useRef(null);

  const handleChange = (content, delta, source, editor) => {
    const htmlContent = editor.getHTML();
    setValue(htmlContent);
    onDataChange(htmlContent); // Gọi callback và truyền dữ liệu mới
  };

  useEffect(() => {
    if (quillRef.current) {
      // Truy cập trực tiếp vào instance của Quill
      const quillInstance = quillRef.current.getEditor();
      // Thêm các sự kiện hoặc xử lý cần thiết
    }
  }, []);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      [{ font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"], // remove formatting button
      [{ direction: "rtl" }], // text direction
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ header: 1 }, { header: 2 }], // custom button values
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "background",
    "align",
    "direction",
    "script",
    "code-block",
  ];

  return (
    <div>
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={handleChange}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default BaiVietComponent;