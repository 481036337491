import React from "react";
import { Tabs } from "antd";
import { MdOutlineArticle } from "react-icons/md";
import { CgDisplayFullwidth } from "react-icons/cg";
import { MdOutlineVideoLibrary } from "react-icons/md";
import { GrSchedules } from "react-icons/gr";
import { MdTitle } from "react-icons/md";
import { FaImages } from "react-icons/fa";

import styled from "styled-components";
import { getRole } from "../../utils/localStorage";

const { TabPane } = Tabs;

// Styled components for tabs and hover effects
const Tab = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  user-select: none;
  border-radius: 6px;

  &:hover {
    background-color: #e61d23;
    color: #fff;
  }
`;

const CustomTabs = styled(Tabs)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #fff;
`;

const tabIconStyles = {
  marginRight: "8px",
  fontSize: "24px",
};

const TabsCol = ({
  activeLayout,
  activeTab,
  handleTabClick,
  RenderTabContent,
  bannerColumns,
  baiVietColumns,
  videoColumns,
  hinhAnhColumns,
  lichTrucColumns,
  tieuDeColumns,
  hinhLichTrucColumns,
}) => {
  // console.log(activeTab)
  const role = getRole();
  return (
    <CustomTabs
      tabPosition="left"
      activeKey={activeTab}
      onChange={handleTabClick}
    >
      <TabPane
        tab={
          <Tab
            style={{
              backgroundColor: activeTab == "banner" && "#e61d23",
              color: activeTab == "banner" && "white",
            }}
          >
            <CgDisplayFullwidth style={tabIconStyles} />
            Banner
          </Tab>
        }
        key="banner"
      >
        {RenderTabContent(bannerColumns)}
      </TabPane>
      <TabPane
        tab={
          <Tab
            style={{
              backgroundColor: activeTab == "baiViet" && "#e61d23",
              color: activeTab == "baiViet" && "white",
            }}
          >
            <MdOutlineArticle style={tabIconStyles} />
            Bài viết
          </Tab>
        }
        key="baiViet"
      >
        {RenderTabContent(baiVietColumns)}
      </TabPane>
      <TabPane
        tab={
          <Tab
            style={{
              backgroundColor: activeTab == "thuvien" && "#e61d23",
              color: activeTab == "thuvien" && "white",
            }}
          >
            <MdOutlineVideoLibrary style={tabIconStyles} />
            Video
          </Tab>
        }
        key="thuvien"
      >
        {RenderTabContent(videoColumns)}
      </TabPane>
      <TabPane
        tab={
          <Tab
            style={{
              backgroundColor: activeTab == "hinhAnh" && "#e61d23",
              color: activeTab == "hinhAnh" && "white",
            }}
          >
            <FaImages style={tabIconStyles} />
            Hình ảnh
          </Tab>
        }
        key="hinhAnh"
      >
        {RenderTabContent(hinhAnhColumns)}
      </TabPane>
      <TabPane
        tab={
          <Tab
            style={{
              backgroundColor: activeTab == "tieuDe" && "#e61d23",
              color: activeTab == "tieuDe" && "white",
            }}
          >
            <MdTitle style={tabIconStyles} />
            Tiêu đề
          </Tab>
        }
        key="tieuDe"
      >
        {RenderTabContent(tieuDeColumns)}
      </TabPane>

      {role !== "ADMIN" && activeLayout === "5" && (
        <TabPane
          tab={
            <Tab
              style={{
                backgroundColor: activeTab == "lich" && "#e61d23",
                color: activeTab == "lich" && "white",
              }}
            >
              <GrSchedules style={tabIconStyles} />
              Lịch
            </Tab>
          }
          key="lich"
        >
          {RenderTabContent(lichTrucColumns)}
        </TabPane>
      )}

      {role !== "ADMIN" && activeLayout === "5" && (
        <TabPane
          tab={
            <Tab
              style={{
                backgroundColor: activeTab == "hinhLichTruc" && "#e61d23",
                color: activeTab == "hinhLichTruc" && "white",
              }}
            >
              <GrSchedules style={tabIconStyles} />
              Hình của lịch
            </Tab>
          }
          key="hinhLichTruc"
        >
          {RenderTabContent(hinhLichTrucColumns)}
        </TabPane>
      )}
    </CustomTabs>
  );
};

export default TabsCol;
