import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});
export const selectLoading = (state) => state.table.loading;
export const { setLoading } = tableSlice.actions;
export default tableSlice.reducer;
