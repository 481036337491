import axios from "axios";
import { stringify, parse } from "qs";
import { getToken } from "../utils/localStorage";

// export const API_ENDPOINT = "http://10.139.254.41:5000/";
// export const API_ENDPOINT = "http://192.168.0.100:5000/";
export const API_ENDPOINT = 'http://171.244.40.118:5000/';

// export const API_ENDPOINT = "http://20.2.89.247:5000/";

const AxiosClient = axios.create({
  baseURL: API_ENDPOINT,
  responseType: "json",
  timeout: 50000,
  paramsSerializer: {
    encode: parse,
    serialize: stringify,
  },
});

AxiosClient.interceptors.request.use(
  async (config) => {
    const newConfig = config;
    const token = getToken();

    // Check if the request is for forgotPassword
    if (newConfig.url.includes("forgotPassword")) {
      // Remove the Authorization header if it exists
      delete newConfig.headers.Authorization;
    } else if (token) {
      // Add the Authorization header for other requests
      newConfig.headers.Authorization = `Bearer ${token}`;
    }

    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default AxiosClient;
