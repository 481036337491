import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import style from "./Screen.module.scss";
import styleModal from "./Home.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getScreensList } from "../../store/info/infoThunk";
import { PiScreencastBold } from "react-icons/pi";
import { Button, Dropdown, List, message, Modal, Spin } from "antd";
import { delScreen, updateScreen } from "../../services/screenServices";
import { getRole } from "../../utils/localStorage";
import { FaRegTrashCan } from "react-icons/fa6";
import { DownOutlined, LoadingOutlined, UserOutlined } from "@ant-design/icons";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { BsThreeDots } from "react-icons/bs";
import { clearSearch, screenUpdated } from "../../store/screen/screenSlide";
import Notiflix from "notiflix";
import { getDepartmentAll } from "../../services/departmentsServices";

const cx = classNames.bind(style);
const cx2 = classNames.bind(styleModal);

export default function Screen() {
  const dispatch = useDispatch();

  const screens = useSelector((state) => state.screens.data);
  const searchScreens = useSelector((state) => state.screens.filteredData);
  const screenStatus = useSelector((state) => state.screens.status);
  const error = useSelector((state) => state.screens.error);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [currentDataToEdit, setCurrentDataToEdit] = useState(null);
  const [editNameScreen, setEditNameScreen] = useState("");
  const [departmentSelect, setDepartmentSelect] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [currentIdToDelete, setCurrentIdToDelete] = useState(null);
  const [loadingEditScreen, setLoadingEditScreen] = useState(false);

  const handleMenuClick = (e) => {
    const departmentFind = departmentList.find((item) => item.key === e.key);
    console.log("departmentFind", departmentFind);
    setDepartmentSelect(departmentFind);
  };

  const menuProps = {
    items: departmentList,
    onClick: handleMenuClick,
  };

  const handleOkEdit = async () => {
    setLoadingEditScreen(true);
    await handleEditScreen();
  };

  const handleEditScreen = async () => {
    const data = {
      screenName: editNameScreen
        ? editNameScreen
        : currentDataToEdit.screenName,
      department_id: [
        departmentSelect?.key
          ? departmentSelect.key
          : departmentList.find(
              (item) => item.label === currentDataToEdit.departmentName
            )?.key,
      ],
    };
    updateScreen(currentDataToEdit._id, data)
      .then((res) => {
        Notiflix.Notify.success("Cập nhật thành công thành công");
        setEditNameScreen("");
        setCurrentDataToEdit(null);
        setDepartmentSelect(null);
        dispatch(screenUpdated(res.data));
        console.log(res.data);
        setIsModalEditOpen(false);
        setLoadingEditScreen(false);
      })
      .catch((err) => {
        Notiflix.Notify.failure("Tạo không thành công");
        setIsModalEditOpen(false);
        setLoadingEditScreen(false);
      });
  };

  const handleCancelEdit = () => {
    setIsModalEditOpen(false);
    setCurrentDataToEdit(null);
  };

  // Modal xóa màn hình
  const showModalDeleteWithId = (_id) => {
    setCurrentIdToDelete(_id);
    setIsModalDeleteOpen(true);
  };

  const handleOkDelete = async () => {
    await handleDelScreen(currentIdToDelete);
    setIsModalDeleteOpen(false);
    setCurrentIdToDelete(null);
    dispatch(clearSearch());
  };

  const handleCancelDelete = () => {
    setIsModalDeleteOpen(false);
    setCurrentIdToDelete(null);
  };

  const role = getRole();

  //  get list departments
  useEffect(() => {
    getDepartmentAll()
      .then((res) => {
        const customDepartments = res.data.map((item) => {
          return {
            label: item?.departmentName,
            key: item?._id,
            icon: <UserOutlined />,
          };
        });

        setDepartmentList(customDepartments);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (screenStatus === "idle") {
      dispatch(getScreensList());
    }
  }, [screenStatus, dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getScreensList());
    }, 1000 * 60 * 5); // 5 minutes

    return () => clearInterval(interval);
  }, [dispatch]);

  if (screenStatus === "loading") {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Spin
          style={{ transform: "translateY(-50px)" }}
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 100,
              }}
              spin
            />
          }
        />
      </div>
    );
  }

  if (screenStatus === "failed") {
    return <div>{error}</div>;
  }

  const handleDelScreen = async (id) => {
    await delScreen(id)
      .then(() => {
        message.success("Xóa màn hình thành công");
        dispatch(getScreensList());
      })
      .catch(() => message.error("Xóa màn hình thất bại"));
  };

  const showModalEditWithData = (data) => {
    console.log("dât", data);

    setCurrentDataToEdit(data);
    setEditNameScreen(data.screenName);
    setDepartmentSelect({
      label: data.departmentName,
      key: data.department_id,
    });
    setIsModalEditOpen(true);
  };

  const menu = (
    <div
      style={{
        maxHeight: "200px",
        overflowY: "auto",
        backgroundColor: "white",
        borderRadius: "5px",
        position: "absolute",
        right: 0,
        left: 0,
        top: 40,
      }}
    >
      <List
        dataSource={departmentList}
        style={{ padding: "0 10px" }}
        renderItem={(item) => (
          <List.Item key={item.key} onClick={() => handleMenuClick(item)}>
            {item.label}
          </List.Item>
        )}
      />
    </div>
  );

  return (
    <div className={cx("screenContainer")}>
      <div className={cx("totalSection")}>
        <div className={cx("total")}>Tất cả ( {screens.length} )</div>
      </div>
      <ul className={cx("screenList")}>
        {searchScreens && searchScreens.length > 0 ? (
          searchScreens.map((item) => {
            const { _id, screenName, isActive, departmentName } = item;
            return (
              <li className={cx("screenItems")} key={_id}>
                <div className={cx("titleAndIconItem")}>
                  <div className={cx("icon")}>
                    <PiScreencastBold />
                  </div>
                  <div className={cx("screenTtitle")}>{screenName}</div>
                </div>

                <div className={cx("departmentAndDepartmentName")}>
                  <div className={cx("department")}>Phòng ban</div>
                  <div className={cx("departmentName")}>{departmentName}</div>
                </div>

                <div className={cx("containerBtn")}>
                  {isActive ? (
                    <div className={cx("statusDevice")}>Đang hoạt động</div>
                  ) : (
                    <div className={cx("isActive")}>Dừng hoạt động</div>
                  )}
                  {role !== "DEPARTMENT" && (
                    <div className={cx("deleteDevice")}>
                      <Menu>
                        <MenuButton
                          height={30}
                          as={IconButton}
                          aria-label="Options"
                          icon={<BsThreeDots />}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                        />
                        <MenuList style={{ minWidth: "120px" }}>
                          <MenuItem
                            style={{
                              border: "none",
                              borderBottom: "0.5px solid #D6D6D6",
                              borderRadius: "0",
                              width: "160px",
                            }}
                            onClick={() => showModalEditWithData(item)}
                          >
                            Chỉnh sửa
                          </MenuItem>
                          <MenuItem
                            style={{
                              border: "none",
                              borderRadius: "0",
                            }}
                            onClick={() => showModalDeleteWithId(_id)}
                          >
                            Xóa
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </div>
                  )}
                </div>
                <Modal
                  title="Xóa màn hình"
                  open={isModalDeleteOpen}
                  onOk={handleOkDelete}
                  okButtonProps={{
                    style: { backgroundColor: "#E61D23", color: "white" },
                  }}
                  confirmLoading={confirmLoading}
                  onCancel={handleCancelDelete}
                >
                  <p>
                    {isActive
                      ? "TV đang hoạt động, bạn có chắc muốn xóa không?"
                      : "Bạn có chắc muốn xóa không?"}
                  </p>
                </Modal>
                <Modal
                  title="CHỈNH SỬA MÀN HÌNH"
                  open={isModalEditOpen}
                  onOk={handleOkEdit}
                  confirmLoading={loadingEditScreen}
                  onCancel={handleCancelEdit}
                  okText="Lưu màn hình"
                  okButtonProps={{
                    style: { backgroundColor: "#E61D23", color: "white" },
                  }}
                  cancelButtonProps={{ style: { display: "none" } }}
                >
                  <div className={cx2("form-addScreeen")}>
                    <div className={cx2("box")}>
                      <span className={cx2("title")}>Tên màn hình</span>
                      <input
                        className={cx2("input")}
                        placeholder="Nhập tên màn hình"
                        value={editNameScreen}
                        onChange={(e) => {
                          return setEditNameScreen(e.target.value);
                        }}
                      />
                    </div>
                    <div className={cx2("box")}>
                      <span className={cx2("title")}>Thêm vào phòng ban</span>
                      <div className="scrollable-container">
                        <Dropdown
                          overlay={menu}
                          placement="bottomCenter"
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                        >
                          <Button
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              {departmentSelect
                                ? departmentSelect.label
                                : "Chọn phòng ban bạn muốn thêm"}
                            </span>
                            <span style={{ fontWeight: 600, fontSize: "16px" }}>
                              <DownOutlined />
                            </span>
                          </Button>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </Modal>
              </li>
            );
          })
        ) : screens && screens.length > 0 ? (
          screens.map((item) => {
            const { _id, screenName, isActive, departmentName } = item;
            return (
              <li className={cx("screenItems")} key={_id}>
                <div className={cx("titleAndIconItem")}>
                  <div className={cx("icon")}>
                    <PiScreencastBold />
                  </div>
                  <div className={cx("screenTtitle")}>{screenName}</div>
                </div>

                <div className={cx("departmentAndDepartmentName")}>
                  <div className={cx("department")}>Phòng ban</div>
                  <div className={cx("departmentName")}>{departmentName}</div>
                </div>

                <div className={cx("containerBtn")}>
                  {isActive ? (
                    <div className={cx("statusDevice")}>Đang hoạt động</div>
                  ) : (
                    <div className={cx("isActive")}>Dừng hoạt động</div>
                  )}
                  {role !== "DEPARTMENT" && (
                    <div
                      className={cx("deleteDevice")} // Cập nhật điều kiện để disable khi isActive là true
                    >
                      <Menu size={24}>
                        <MenuButton
                          height={30}
                          as={IconButton}
                          aria-label="Options"
                          icon={<BsThreeDots />}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                        />
                        <MenuList style={{ minWidth: "120px" }}>
                          <MenuItem
                            style={{
                              border: "none",
                              borderBottom: "0.5px solid #D6D6D6",
                              borderRadius: "0",
                              width: "160px",
                            }}
                            onClick={() => showModalEditWithData(item)}
                          >
                            Chỉnh sửa
                          </MenuItem>
                          <MenuItem
                            style={{
                              border: "none",
                              borderRadius: "0",
                            }}
                            onClick={() => showModalDeleteWithId(_id)}
                          >
                            Xóa
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </div>
                  )}
                </div>
                <Modal
                  title="Xóa màn hình"
                  open={isModalDeleteOpen}
                  onOk={handleOkDelete}
                  okButtonProps={{
                    style: { backgroundColor: "#E61D23", color: "white" },
                  }}
                  confirmLoading={confirmLoading}
                  onCancel={handleCancelDelete}
                >
                  <p>
                    {isActive
                      ? "TV đang hoạt động, bạn có chắc muốn xóa không?"
                      : "Bạn có chắc muốn xóa không?"}
                  </p>
                </Modal>
                <Modal
                  title="CHỈNH SỬA MÀN HÌNH"
                  open={isModalEditOpen}
                  onOk={handleOkEdit}
                  confirmLoading={loadingEditScreen}
                  onCancel={handleCancelEdit}
                  okText="Lưu màn hình"
                  okButtonProps={{
                    style: { backgroundColor: "#E61D23", color: "white" },
                  }}
                  cancelButtonProps={{ style: { display: "none" } }}
                >
                  <div className={cx2("form-addScreeen")}>
                    <div className={cx2("box")}>
                      <span className={cx2("title")}>Tên màn hình</span>
                      <input
                        className={cx2("input")}
                        placeholder="Nhập tên màn hình"
                        value={editNameScreen}
                        onChange={(e) => {
                          return setEditNameScreen(e.target.value);
                        }}
                      />
                    </div>
                    <div className={cx2("box")}>
                      <span className={cx2("title")}>Thêm vào phòng ban</span>
                      <div className="scrollable-container">
                        <Dropdown
                          overlay={menu}
                          placement="bottomCenter"
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                        >
                          <Button
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span style={{ fontSize: "15px" }}>
                              {departmentSelect
                                ? departmentSelect.label
                                : "Chọn phòng ban bạn muốn thêm"}
                            </span>
                            <span style={{ fontWeight: 600, fontSize: "16px" }}>
                              <DownOutlined />
                            </span>
                          </Button>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </Modal>
              </li>
            );
          })
        ) : (
          <div>No screens found.</div>
        )}
      </ul>
    </div>
  );
}
