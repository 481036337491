import React, { useState, useEffect } from "react";
import Marquee from "../../../components/Marquee/Marquee";
import Flag from "../../../assets/vietnam.png";
import acc from "../../../assets/acc.png";
import headlineImage from "../../../assets/banner_test.png";
import {
  Image,
  Table,
  Tabs,
  Checkbox,
  Button,
  ConfigProvider,
  message,
  Radio,
  Space,
  Input,
} from "antd";
import TabsCol from "../../../components/Tabs/TabsCol";
import classNames from "classnames/bind";
import style from "./AddContent.module.scss";
import AxiosClient, { API_ENDPOINT } from "../../../services/axiosClient";
import { Loading, Notify } from "notiflix";
import { changeDateType } from "../../../utils/changeDateType";
import { useDispatch, useSelector } from "react-redux";
import {
  phongban3Selector,
  phongban4Selector,
  phongban5Selector,
  phongbanSelector,
  setPhongban3Selected,
  setPhongban4Selected,
  setPhongban5Selected,
  setPhongbanSelected,
} from "../../../store/phongban/phongbanSlice";
import lichCongTac from "../../../assets/lichCongTac.png";
import lichTruc from "../../../assets/lichTruc.png";

const cx = classNames.bind(style);

const AddContent = ({
  columnTitles,
  isMargee = true,
  handleCancelAddContent,
  activeKey,
}) => {
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("banner");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [dataTableLich, setDataTableLich] = useState([
    {
      key: "1",
      dataIndex: "lichTruc",
      text: "Lịch trực",
    },
    {
      key: "2",
      dataIndex: "lichCongTac",
      text: "Lịch công tác",
    },
    {
      key: "3",
      dataIndex: "lichDangBang",
      text: "Lịch dạng bảng",
    },
  ]);
  const dispatch = useDispatch();
  const phongbanSelected = useSelector(phongbanSelector);
  const phongban3Selected = useSelector(phongban3Selector);
  const phongban4Selected = useSelector(phongban4Selector);
  const phongban5Selected = useSelector(phongban5Selector);

  const handleTabClick = (key) => {
    setActiveTab(key);
  };

  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setSelectedRowKeys([]);
      var response = null;
      var query = "";
      switch (activeTab) {
        case "Banner":
          query = "banner";
          break;
        case "baiViet":
          query = "blog";
          break;
        case "thuvien":
          query = "video";
          break;
        case "hinhAnh":
          query = "image";
          break;
        case "lich":
          query = "schedules";
          break;
        case "tieuDe":
          query = "title";
          break;
        case "hinhLichTruc":
          query = "schedule";
          break;
        default:
          query = "banner";
      }
      try {
        Loading.dots("Đang tải dữ liệu...");
        response = await AxiosClient.get(
          `/collections/${query}?limit=1000&page=1`
        );
      } catch (err) {
        console.log(err);
      }
      Loading.remove();

      const data = response.data.collections.map((item, index) => {
        console.log("iteem 😘", item);
        
        return {
          id: item._id,
          key: index + 1,
          headline_content: item.title,
          headline_image: `${API_ENDPOINT}collections/download/${item.content}`,
          content: `${API_ENDPOINT}collections/download/${item.content}`,
          time_up: changeDateType(item.createdAt),
          acc_name: item.author,
          acc_image: acc,
          type: item.type,
          description: item.description,
          duration: item.duration,
          title: item.title ? item.title : "",
        };
      });
      setDataTable(data);
      // console.log(data);
    };
    fetchData();
  }, [activeTab]);

  // Các cột dữ liệu cho từng loại nội dung
  const bannerColumns = [
    {
      title: columnTitles.stt,
      dataIndex: "key",
      width: 75,
      align: "center",
    },
    {
      title: "Tên",
      width: "45%",
      dataIndex: "headline",
      render: (text, record) => (
        <div className={cx("headline")}>
          <Image
            width={150}
            src={record.headline_image}
            alt="headline"
            className={cx("headline-image")}
          />
          <span style={{ marginLeft: 10, width: 200, height: "100%" }}>
            {record.headline_content}
          </span>
        </div>
      ),
      sorter: (a, b) => a.headline_content.localeCompare(b.headline_content),
    },
    { title: columnTitles.time_up, dataIndex: "time_up" },
    {
      title: columnTitles.accPost,
      dataIndex: "acc_post",
      render: (text, record) => (
        <div className={cx("acc-post")}>
          <Image
            width={50}
            height={40}
            src={record.acc_image}
            alt="account"
            className={cx("acc-image")}
          />
          <span style={{ marginLeft: 10 }}>{record.acc_name}</span>
        </div>
      ),
      sorter: (a, b) => a.acc_name.localeCompare(b.acc_name),
    },
  ];

  const baiVietColumns = [
    {
      title: columnTitles.stt,
      dataIndex: "key",
      width: 75,
      align: "center",
    },
    {
      title: "Tiêu đề bài viết",
      width: "40%",
      dataIndex: "article",
      align: "center",
      render: (text, record) => (
        <div
          className="headline"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            textAlign: "left",
          }}
        >
          <span style={{ marginLeft: 10, overflowWrap: "break-word" }}>
            {record.title}
          </span>
        </div>
      ),
      sorter: (a, b) => a.article.localeCompare(b.article),
    },
    { title: columnTitles.time_up, dataIndex: "time_up" },
    { title: "Mô tả bài viết", dataIndex: "article_description" },
    {
      title: columnTitles.accPost,
      dataIndex: "acc_post",
      render: (text, record) => (
        <div className={cx("acc-post")}>
          <Image
            width={50}
            height={40}
            src={record.acc_image}
            alt="account"
            className={cx("acc-image")}
          />
          <span
            style={{ marginLeft: 10, width: "65%", overflowWrap: "break-word" }}
          >
            {record.acc_name}
          </span>
        </div>
      ),
      sorter: (a, b) => a.acc_name.localeCompare(b.acc_name),
    },
  ];

  const videoColumns = [
    {
      title: columnTitles.stt,
      dataIndex: "key",
      width: 75,
      align: "center",
    },
    {
      title: "Tiêu đề Video",
      dataIndex: "video",
      width: "50%",
      render: (text, record) => (
        <div className={cx("headline")}>
          <video width={200} controls>
            <source src={record.headline_image} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <span style={{ marginLeft: 10, width: 130 }}>
            {record.headline_content}
          </span>
        </div>
      ),
      sorter: (a, b) => a.headline_content.localeCompare(b.headline_content),
    },
    { title: columnTitles.time_up, dataIndex: "time_up" },
    { title: "Thời lượng", dataIndex: "duration", align: "center" },
    {
      title: columnTitles.accPost,
      dataIndex: "acc_post",
      render: (text, record) => (
        <div className={cx("acc-post")}>
          <Image
            width={40}
            src={record.acc_image}
            alt="account"
            className={cx("acc-image")}
          />
          <span style={{ marginLeft: 10 }}>{record.acc_name}</span>
        </div>
      ),
      sorter: (a, b) => a.acc_name.localeCompare(b.acc_name),
    },
  ];

  const hinhAnhColumns = [
    {
      title: columnTitles.stt,
      dataIndex: "key",
      width: 75,
      align: "center",
    },
    {
      title: "Tiêu đề Hình ảnh",
      dataIndex: "headline_content",
      render: (text, record) => (
        <div className={cx("headline")}>
          <Image
            width={150}
            src={record.headline_image}
            alt="headline"
            className={cx("headline-image")}
          />
          <span style={{ marginLeft: 10, width: 130 }}>
            {record.headline_content}
          </span>
        </div>
      ),
      sorter: (a, b) => a.headline_content.localeCompare(b.headline_content),
    },
    { title: columnTitles.time_up, dataIndex: "time_up" },
    {
      title: columnTitles.accPost,
      dataIndex: "acc_post",
      render: (text, record) => (
        <div className={cx("acc-post")}>
          <Image
            width={40}
            src={record.acc_image}
            alt="account"
            className={cx("acc-image")}
          />
          <span style={{ marginLeft: 10 }}>{record.acc_name}</span>
        </div>
      ),
      sorter: (a, b) => a.acc_name.localeCompare(b.acc_name),
    },
  ];

  const commonColumns = [
    {
      title: columnTitles.stt,
      dataIndex: "key",
      width: 75,
      align: "center",
    },
    {
      title: columnTitles.headline,
      dataIndex: "headline_content",
      render: (text, record) => (
        <div className={cx("headline")}>
          <Image
            width={150}
            src={record.headline_image}
            alt="headline"
            className={cx("headline-image")}
          />
          <span style={{ marginLeft: 10, width: 130 }}>{text}</span>
        </div>
      ),
      sorter: (a, b) => a.headline_content.localeCompare(b.headline_content),
    },
    { title: columnTitles.time_up, dataIndex: "time_up" },
    {
      title: columnTitles.accPost,
      dataIndex: "acc_post",
      render: (text, record) => (
        <div className={cx("acc-post")}>
          <Image
            width={40}
            src={record.acc_image}
            alt="account"
            className={cx("acc-image")}
          />
          <span style={{ marginLeft: 10 }}>{record.acc_name}</span>
        </div>
      ),
      sorter: (a, b) => a.acc_name.localeCompare(b.acc_name),
    },
  ];

  const titleColumns = [
    {
      title: columnTitles.stt,
      dataIndex: "key",
      width: 75,
      align: "center",
    },
    {
      title: columnTitles.headline,
      dataIndex: "headline_content",
      render: (text, record) => (
        <div className={cx("headline")}>
          <span style={{ marginLeft: 10, width: 130 }}>{text}</span>
        </div>
      ),
      sorter: (a, b) => a.headline_content.localeCompare(b.headline_content),
    },
    { title: columnTitles.time_up, dataIndex: "time_up" },
    {
      title: columnTitles.accPost,
      dataIndex: "acc_post",
      render: (text, record) => (
        <div className={cx("acc-post")}>
          <Image
            width={40}
            src={record.acc_image}
            alt="account"
            className={cx("acc-image")}
          />
          <span style={{ marginLeft: 10 }}>{record.acc_name}</span>
        </div>
      ),
      sorter: (a, b) => a.acc_name.localeCompare(b.acc_name),
    },
  ];

  const lichtrucColumns = [
    {
      title: "Chọn lịch",
      dataIndex: "lich",
      width: "15%",
      render: (text, record) => {
        // console.log("🤖🤖🤖🤖🤖🤖 ", record);
        return (
          <div div className={cx("headline")}>
            <Image
              width={80}
              height={80}
              src={record?.key == 1 ? lichTruc : lichCongTac}
              alt="headline"
              className={cx("headline-image")}
            />
          </div>
        );
      },
      // sorter: (a, b) => a.headline_content.localeCompare(b.headline_content),
    },
    {
      title: "Loại lịch",
      dataIndex: "type",
      width: "75%",
      render: (text, record) => {
        return (
          <div div className={cx("headline")}>
            <span style={{ marginLeft: 10, fontSize: 20 }}>{record.text}</span>
          </div>
        );
      },
      // sorter: (a, b) => a.acc_name.localeCompare(b.acc_name),
    },
  ];

  const hinhLichTrucColumns = [
    {
      title: columnTitles.stt,
      dataIndex: "key",
      width: 75,
      align: "center",
    },
    {
      title: "Hình ảnh",
      dataIndex: "headline_content",
      render: (text, record) => (
        <div className={cx("headline")}>
          <Image
            width={150}
            src={record.headline_image}
            alt="headline"
            className={cx("headline-image")}
          />
          <span style={{ marginLeft: 10, width: 130 }}>
            {record.headline_content}
          </span>
        </div>
      ),
      sorter: (a, b) => a.headline_content.localeCompare(b.headline_content),
    },
    { title: columnTitles.time_up, dataIndex: "time_up" },
    {
      title: columnTitles.accPost,
      dataIndex: "acc_post",
      render: (text, record) => (
        <div className={cx("acc-post")}>
          <Image
            width={40}
            src={record.acc_image}
            alt="account"
            className={cx("acc-image")}
          />
          <span style={{ marginLeft: 10 }}>{record.acc_name}</span>
        </div>
      ),
      sorter: (a, b) => a.acc_name.localeCompare(b.acc_name),
    },
  ];

  const checkboxColumn = {
    dataIndex: "select",
    key: "select",
    align: "center",
    width: 75,
    fixed: "right", // Đảm bảo cột này luôn ở bên phải
    render: (_, record) => (
      <Checkbox
        checked={selectedRowKeys.includes(record.key)}
        onChange={(e) => handleCheckboxChange(record.key, e.target.checked)}
      />
    ),
  };

  const checkboxLichColumn = {
    dataIndex: "select",
    key: "select",
    align: "center",
    width: 75,
    fixed: "right", // Đảm bảo cột này luôn ở bên phải
    render: (_, record) => (
      <Checkbox
        checked={selectedRowKeys.includes(record.key)}
        onChange={(e) => handleCheckboxChange(record.key, e.target.checked)}
      />
    ),
  };

  // Thêm cột checkbox vào cuối mỗi mảng cột
  bannerColumns.push(checkboxColumn);
  baiVietColumns.push(checkboxColumn);
  videoColumns.push(checkboxColumn);
  hinhAnhColumns.push(checkboxColumn);
  commonColumns.push(checkboxColumn);
  lichtrucColumns.push(checkboxLichColumn);
  titleColumns.push(checkboxColumn);
  hinhLichTrucColumns.push(checkboxColumn);

  // Bước 1: Tạo hàm xử lý sự kiện khi hàng được nhấn
  const handleRowClick = (record) => {
    // console.log(record);
    const selectedKeyIndex = selectedRowKeys.indexOf(record.key);
    const newSelectedRowKeys = [...selectedRowKeys];
    console.log("newSelectedRowKeys", newSelectedRowKeys);

    if (selectedKeyIndex >= 0) {
      newSelectedRowKeys.splice(selectedKeyIndex, 1); // Loại bỏ khóa nếu đã tồn tại
    } else {
      newSelectedRowKeys.push(record.key); // Thêm khóa nếu chưa tồn tại
    }

    setSelectedRowKeys(newSelectedRowKeys);
  };

  // Bước 2: Cập nhật các hàng trong bảng
  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  // Bước 3: Cập nhật hàm handleCheckboxChange (nếu cần)
  const handleCheckboxChange = (key, checked) => {
    const newSelectedRowKeys = checked
      ? [...selectedRowKeys, key]
      : selectedRowKeys.filter((selectedKey) => selectedKey !== key);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // console.log("💕💕💕", phongban5Selected);

  const handleSelectedItems = () => {
    // lọc ra các hàng được chọn
    const dataResult = dataTable.filter((item) =>
      selectedRowKeys.includes(item.key)
    );
    const dataLichResult = dataTableLich.filter((item) =>
      selectedRowKeys.includes(item.key)
    );
    console.log("🤖🤖🤖🤖🤖🤖 dataResult ", dataResult);
    console.log("🤣🤣🤣🤣 lich ", dataLichResult);
    const converDataLich = () => {
      if (dataLichResult.length < 2)
        return {
          id: dataLichResult[0]?.dataIndex,
          dataId: dataLichResult[0]?.dataIndex,
          type: "schedules",
        };
      else if (
        dataLichResult.length === 2 &&
        dataLichResult.some((item) => item.dataIndex === "lichTruc") &&
        dataLichResult.some((item) => item.dataIndex === "lichCongTac")
      )
        return {
          id: "lichTruc&lichCongTac",
          dataId: "lichTruc&lichCongTac",
          type: "schedules",
        };
      else if (dataLichResult.length === 3) {
        Notify.warning("Không được thêm 3 lịch cùng lúc", { timeout: 3000 });
        return null;
      } else {
        Notify.warning("Chỉ có thể thêm lịch trực và lịch công tác cùng lúc", {
          timeout: 3000,
        });
        return null;
      }
    };

    // console.log("💕💕💕", dataLichResult, "😘😘😘", phongban5Selected);

    let combinedArray = [];
    let combinedArrayLich = [];

    const result = (combinedArray) => {
     return combinedArray.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    };

    // const isValidLayout = (layouts) => {
    //   const hasBlog = layouts.some(layout => layout.type === "blog");
    //   if (hasBlog) {
    //     return !layouts.some(layout => ["image", "video", "banner"].includes(layout.type));
    //   }
    //   return true;
    // }

    const checkValidLayouts = (layouts) => {
      // Loại bỏ các phần tử trùng lặp
      const uniqueLayouts = layouts.reduce((acc, current) => {
        if (!acc.find((item) => item.id === current.id)) {
          acc.push(current);
        }
        return acc;
      }, []);

      // Kiểm tra tính hợp lệ của mảng layout sau khi đã loại bỏ trùng lặp
      // const hasBlog = uniqueLayouts.some((layout) => layout.type === "blog");
      // const hasInvalidContent = uniqueLayouts.some((layout) =>
      //   ["image", "video", "banner"].includes(layout.type)
      // );

      // Kiểm tra số lượng tiêu đề trong các layout 2, 3, và 4
      const layout2Count = uniqueLayouts.filter(
        (layout) => layout.type === "title"
      ).length;
      const layout3Count = uniqueLayouts.filter(
        (layout) => layout.type === "title"
      ).length;
      const layout4Count = uniqueLayouts.filter(
        (layout) => layout.type === "title"
      ).length;

      if (layout2Count > 1 || layout3Count > 1 || layout4Count > 1) {
        return false;
      }

      return true;
    };

    if (activeKey === "2") {
      combinedArray = [
        ...(Array.isArray(phongbanSelected) ? phongbanSelected : []),
        ...dataResult,
      ];
      checkValidLayouts(combinedArray)
        ? dispatch(setPhongbanSelected(result(combinedArray)))
        : message.error("Layout chỉ nên có 1 tiêu đề!!!");
    } else if (activeKey === "3") {
      combinedArray = [
        ...(Array.isArray(phongban3Selected) ? phongban3Selected : []),
        ...dataResult,
      ];
      checkValidLayouts(combinedArray)
        ? dispatch(setPhongban3Selected(result(combinedArray)))
        : message.error("Layout chỉ nên có 1 tiêu đề!!!");
    } else if (activeKey === "4") {
      combinedArray = [
        ...(Array.isArray(phongban4Selected) ? phongban4Selected : []),
        ...dataResult,
      ];
      if (checkValidLayouts(combinedArray)) {
        dispatch(setPhongban4Selected(result(combinedArray)));
      } else {
        message.error("Layout chỉ nên có 1 tiêu đề!!!");
      }
    } else if (activeKey === "5") {
      // Lọc dataResult để chỉ bao gồm lịch trực, lịch công tác và hình lịch trực
      const filteredDataResult = dataResult.filter(
        (item) => item.type === "schedules" || item.type === "schedule"
      );
      console.log("filteredDataResult", filteredDataResult);

      if (filteredDataResult.length >= 1) {
        combinedArrayLich = [...filteredDataResult];
      } else {
        combinedArrayLich = [converDataLich()];
      }
      console.log("combinedArrayLich", combinedArrayLich);

      dispatch(setPhongban5Selected(result(combinedArrayLich)));
    }
    handleCancelAddContent();
  };
  // console.log(dataTable)
  // Render nội dung cho từng tab
  const RenderTabContent = (originalColumns, title) => (
    <div className={cx("item_AddContent")}>
      <div className={cx("title_AddContent")}>
        <span className={cx("title_AddContent")}>{title}</span>
      </div>

      {/* Nội dung của từng cột */}
      <Table
        style={{ height: "70vh", overflowY: "scroll", scrollbarWidth: "none" }}
        columns={originalColumns}
        dataSource={
          originalColumns[0].dataIndex == "lich" ? dataTableLich : dataTable
        }
        pagination={false}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        scroll={{
          y: 500,
        }}
      />

      {/* Footer hiển thị số lượng nội dung đã chọn */}
      <div className={cx("action-footer-table")}>
        {selectedRowKeys.length > 0 ? (
          <div className={cx("selected-text")}>
            <p
              style={{
                margin: 0,
                padding: 0,
                textAlign: "center",
              }}
            >
              Đã chọn
            </p>
            <div className={cx("selected-number")}>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  color: "#fff",
                }}
              >
                {selectedRowKeys.length}
              </p>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {/* Config màu mặc định cho button "Thêm nội dung" */}
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#e61d23",
            },
          }}
        >
          <Button
            onClick={handleSelectedItems}
            type="primary"
            className={cx("btn_AddContent")}
            disabled={selectedRowKeys.length === 0}
          >
            Thêm nội dung
          </Button>
        </ConfigProvider>
      </div>
    </div>
  );

  return (
    <div className={cx("AddContent_contain")}>
      {isMargee ? (
        <Marquee img={Flag}>
          Trung thành, Dũng cảm - Mưu trí, Sáng tạo - Vì hạnh phúc nhân dân - Vì
          bình yên Thành phố
        </Marquee>
      ) : null}
      <TabsCol
        activeLayout={activeKey}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
        RenderTabContent={RenderTabContent}
        bannerColumns={bannerColumns}
        baiVietColumns={baiVietColumns}
        videoColumns={videoColumns}
        hinhAnhColumns={hinhAnhColumns}
        lichTrucColumns={lichtrucColumns}
        tieuDeColumns={titleColumns}
        hinhLichTrucColumns={hinhLichTrucColumns}
      />
    </div>
  );
};

AddContent.defaultProps = {
  columnTitles: {
    stt: "STT",
    headline: "Tiêu đề",
    time_up: "Ngày",
    accPost: "Người thêm",
  },
};

export default AddContent;
