import React from 'react'
import './Tabs.scss'

function Tabs({ selectedTab, setSelectedTab }) {
	return (
		<div className="tabs-navigation">
			<div
				className={`tab ${selectedTab === 'lichtruc' ? 'selected' : ''}`}
				onClick={() => setSelectedTab('lichtruc')}
			>
				Lịch trực
			</div>
			<div
				className={`tab ${selectedTab === 'lichcongtac' ? 'selected' : ''}`}
				onClick={() => setSelectedTab('lichcongtac')}
			>
				Lịch công tác
			</div>
			<div
				className={`tab ${selectedTab === 'lichdangbang' ? 'selected' : ''}`}
				onClick={() => setSelectedTab('lichdangbang')}
			>
				Lịch dạng bảng
			</div>
		</div>
	)
}

export default Tabs