import { createSlice } from "@reduxjs/toolkit";
import { set } from "date-fns";

const initialState = {
  phongbanSelected: [],

  phongban3Selected: [],

  phongban4Selected: [],

  phongban5Selected: [],

  phongbanLayout: {
    delayTime: null,
    departmentId: "",
    departmentName: "",
    slogan: "",
    layout: [],
  },

  previewLayout: {
    slogan: "",
    layout2: null,
    layout3: null,
    layout4: null,
    layout5: null,
  },

  postPreview: {
    departmentId: "",
    departmentName: "",
    slogan: "",
    delayTime: 0,
    layout: [],
  },
};

const phongbanSlice = createSlice({
  name: "phongban",
  initialState,
  reducers: {
    setPhongbanSelected: (state, action) => {
      state.phongbanSelected = action.payload;
    },
    setPhongban3Selected: (state, action) => {
      state.phongban3Selected = action.payload;
    },
    setPhongban4Selected: (state, action) => {
      state.phongban4Selected = action.payload;
    },
    setPhongban5Selected: (state, action) => {
      state.phongban5Selected = action.payload;
    },
    setPhongbanLayout: (state, action) => {
      state.phongbanLayout = action.payload;
    },
    setPreviewLayout: (state, action) => {
      state.previewLayout = action.payload;
    },
    setPostPreview: (state, action) => {
      state.postPreview = action.payload;
    },
  },
});

export const phongbanSelector = (state) => state.phongban.phongbanSelected;
export const phongban3Selector = (state) => state.phongban.phongban3Selected;
export const phongban4Selector = (state) => state.phongban.phongban4Selected;
export const phongban5Selector = (state) => state.phongban.phongban5Selected;

export const postPreviewSelector = (state) => state.phongban.postPreview;
export const previewLayoutSelector = (state) => state.phongban.previewLayout;
export const phongbanLayoutSelector = (state) => state.phongban.phongbanLayout;

export const {
  setPhongbanSelected,
  setPhongban3Selected,
  setPhongban4Selected,
  setPhongban5Selected,
  setPhongbanLayout,
  setPreviewLayout,
  setPostPreview,
} = phongbanSlice.actions;
export default phongbanSlice.reducer;
