import AxiosClient from "./axiosClient";

const API_ENDPOINT = "log/";
export const showReport = async (data) => {
  const { toDate, fromDate, limit, page } = data;
  return AxiosClient.get(
    `${API_ENDPOINT}?toDate=${toDate}&fromDate=${fromDate}&limit=${limit}&page=${page}`
  );
};

export const exportExcel = async () => {
  return AxiosClient.get(API_ENDPOINT + "exportExcel", {
    responseType: "blob",
  });
};
