import { Button, Image, Input, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import coverPhoto from "../../assets/cover_photo_Q1.jpg";
import "./Setting.scss";
import { Axios } from "axios";
import AxiosClient, { API_ENDPOINT } from "../../services/axiosClient";
import Notiflix, { Notify } from "notiflix";
import { useDispatch, useSelector } from "react-redux";
import {
  phongbanLayoutSelector,
  setPhongbanLayout,
} from "../../store/phongban/phongbanSlice";

const defaultPath = "collections/download/blog/";
function Setting({ currentRoom }) {
  const dispatch = useDispatch();
  const phongbanLayout = useSelector(phongbanLayoutSelector);
  const id = currentRoom.departmentId;
  const [fileList, setFileList] = React.useState([]);
  const [image, setImage] = useState(() => {
    if (currentRoom?.image) {
      return currentRoom.image.includes(API_ENDPOINT)
        ? currentRoom.image
        : `${API_ENDPOINT}${defaultPath}${currentRoom.image}`;
    }
    return null;
  });
  const [departmentName, setDepartmentName] = React.useState(
    currentRoom?.departmentName
  );

  console.log("currentRoom", currentRoom);
  

  const [time, setTime] = useState(phongbanLayout?.delayTime || currentRoom?.delayTime || 6 );
  const handleChangeTime = (e) => {
    setTime(e.target.value);
  };

  const uploadProps = {
    name: "file",
    customRequest: async (options) => {
      const { file } = options;
      if (file.type.startsWith("image/") === false) {
        // Nếu file không phải là ảnh, thông báo lỗi và dừng
        options.onError(new Error("Chỉ được phép upload ảnh."));
        Notiflix.Notify.failure("Chỉ được phép upload ảnh.");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await AxiosClient.post(
          `/collections/upload`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "LOCAL_STORAGE_AUTH_KEY"
              )}`,
            },
          }
        );
        if (response.status === 201) {
          // gọi tới api để getImage
          setImage(
            `${API_ENDPOINT}collections/download/blog/${response.data.file.filename}`
          );
        }

        // console.log("Upload response", response);
        options.onSuccess(response.data, file);
        Notify.success("Cập nhật ảnh thành công");
      } catch (error) {
        console.error("Upload error", error);
        options.onError(error);
      }
    },
    onChange: (info) => {
      if (info.file.status === "done") {
        // console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        // console.log(`${info.file.name} file upload failed.`);
      }
    },
    onRemove: () => {
      setFileList([]);
    },
    fileList,
  };

  const handleChangeRoom = (e) => {
    setDepartmentName(e.target.value);
  };

  const role = localStorage.getItem("LOCAL_STORAGE_AUTH_ROLE");

  // cập nhật lên server
  const handleUpdateData = async () => {
    try {
      const res = await AxiosClient.put(
        `departments/${currentRoom.departmentId}`,
        {
          departmentName,
          image,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "LOCAL_STORAGE_AUTH_KEY"
            )}`,
          },
        }
      );

      const updateDelayTime = await AxiosClient.put(
        `layouts/updateDelayTime/${id}`,
        {
          delayTime: time,
        },
        {
          header: {
            Authorization: `Bearer ${localStorage.getItem(
              "LOCAL_STORAGE_AUTH_KEY"
            )}`,
          },
        }
      );
      console.log("updateDelayTime", updateDelayTime);
      console.log("res", res);
      if (res.status === 200) {
        Notify.success("Cập nhật thông tin thành công");
        dispatch(setPhongbanLayout({ ...phongbanLayout, delayTime: time }));
      }
    } catch (error) {
      Notify.failure("Cập nhật thông tin thất bại");
    }
  };

  return (
    <div className="setting_contain">
      <div className="cover_photo">
        <div className="cover_photo_label">
          <span>Ảnh bìa phòng ban</span>
        </div>
        <div className="cover_photo_image">
          <Image
            src={image}
            width="100%"
            height="100%"
            style={{ borderRadius: 20 }}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Upload {...uploadProps} className="uploadBtn-section">
            <Button
              className="uploadBtn"
              size="large"
              style={{ width: id && role !== "DEPARTMENT" ? "80%" : "auto" }}
            >
              Thay đổi ảnh bìa
            </Button>
          </Upload>
        </div>
      </div>
      <div className="name">
        {/* <div className="cover_photo_label" style={{ marginTop: "10px" }}>
          <span>Tên phòng ban</span>
        </div>
        <Input
          className="name_content"
          onChange={handleChangeRoom}
          value={departmentName}
        /> */}
      </div>
      <div className="time">
        <div className="cover_photo_label ">
          <span>Thời gian chờ của nội dung</span>
        </div>
        <div className="time_content">
          <Input
            className="item_time1"
            size="small"
            style={{ textAlign: "center" }}
            value={time}
            onChange={handleChangeTime}
          />
          {/* <div className="item_time2">
            <span style={{ fontSize: "16px" }}>X</span>
          </div> */}
        </div>
      </div>
      <div>
        <Button
          style={{ padding: "20px", backgroundColor: "#E61D23" }}
          type="primary"
          block
          onClick={handleUpdateData}
        >
          Lưu
        </Button>
      </div>
    </div>
  );
}
export default Setting;
