import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SlCalender } from "react-icons/sl";
import { CiClock2 } from "react-icons/ci";
import {
  Modal,
  Button,
  Tabs,
  DatePicker,
  TimePicker,
  Select,
  Image,
  ConfigProvider,
  message,
} from "antd";
import moment from "moment";
import classNames from "classnames/bind";
import style from "./Layout3.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  phongban3Selector,
  phongban4Selector,
  phongban5Selector,
  phongbanLayoutSelector,
  phongbanSelector,
  setPhongban3Selected,
  setPhongban4Selected,
  setPhongban5Selected,
  setPhongbanLayout,
  setPhongbanSelected,
} from "../../store/phongban/phongbanSlice";
import dayjs from "dayjs";
import { getRole } from "../../utils/localStorage";
import lichTruc from "../../assets/lichTruc.png";
import lichCongTac from "../../assets/lichCongTac.png";
import { userInfoSelector } from "../../store/user/userSlice";
import { MdOutlineSubtitles } from "react-icons/md";
import { BsFileEarmarkPost } from "react-icons/bs";
import _ from "lodash";
import img_error from "../../assets/img_error.png";

const cx = classNames.bind(style);
const { TabPane } = Tabs;
const { Option } = Select;

const daysOfWeek = [
  {
    id: 2,
    name: "T2",
  },
  {
    id: 3,
    name: "T3",
  },
  {
    id: 4,
    name: "T4",
  },
  {
    id: 5,
    name: "T5",
  },
  {
    id: 6,
    name: "T6",
  },
  {
    id: 7,
    name: "T7",
  },
  {
    id: 8,
    name: "CN",
  },
];

function Layout3({ item, activeKey, department }) {
  const {
    id,
    title,
    acc_image,
    acc_name,
    headline_content,
    content,
    key,
    time_up,
    isDefault,
    date,
    hours,
    type,
  } = item;
  // console.log(item);
  const role = getRole();
  // console.log("😒😒😒😒", isDefault);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [specificDate, setSpecificDate] = useState(null);
  const [specificTime, setSpecificTime] = useState(null);
  const [toggleTime, setToggleTime] = useState(false);
  const [timestamp, setTimestamp] = useState("");
  const dispatch = useDispatch();
  const phongbanLayout = useSelector(phongbanLayoutSelector);

  const phongbanSelected = useSelector(phongbanSelector);
  const phongban3Selected = useSelector(phongban3Selector);
  const phongban4Selected = useSelector(phongban4Selector);
  const phongban5Selected = useSelector(phongban5Selector);

  const showModal = () => {
    if (role !== "ADMIN" && isDefault) {
      message.error("Bạn không có quyền chỉnh sửa bài viết mặc định");
      return;
    }
    setIsModalVisible(true);
  };

  const updatePhongban = (phongban, setPhongbanAction) => {
    if (!_.isArray(phongban)) return;
    const updatedPhongban = phongban?.map((item) => {
      if (item?.id === id) {
        return {
          ...item,
          dataId: id,
          daily: selectedDays,
          specificDate,
          specificTime,
          date: specificDate,
          hours: specificTime,
          timestamp,
        };
      }
      return item;
    });
    console.log(updatedPhongban);
    dispatch(setPhongbanAction(updatedPhongban));
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setToggleTime(true);

    updatePhongban(phongbanSelected, setPhongbanSelected);
    updatePhongban(phongban3Selected, setPhongban3Selected);
    updatePhongban(phongban4Selected, setPhongban4Selected);
    updatePhongban(phongban5Selected, setPhongban5Selected);
  };

  useEffect(() => {
    combineDateTime(specificDate?.dateString, specificTime?.timeString);
  }, [specificDate, specificTime]);

  const handleCancel = () => {
    setSelectedDays([]);
    setSpecificDate(null);
    setSpecificTime(null);
    setIsModalVisible(false);
  };

  const handleDayChange = (day) => {
    const currentIndex = selectedDays.indexOf(day);
    const newSelectedDays = [...selectedDays];

    if (currentIndex === -1) {
      newSelectedDays.push(day);
    } else {
      newSelectedDays.splice(currentIndex, 1);
    }

    setSelectedDays(newSelectedDays);
    setSpecificDate(null);
    setSpecificTime(null);
  };

  const handleDateChange = (date, dateString) => {
    setSpecificDate({ date, dateString });
    setSelectedDays([]);
  };

  const handleTimeChange = (time, timeString) => {
    setSpecificTime({ time, timeString });
    setSelectedDays([]);
  };

  const combineDateTime = (dateString, timeString) => {
    const combinedString = `${dateString} ${timeString}`;
    const momentObj = moment(combinedString, "DD-MM-YYYY hh:mm A");

    if (momentObj.isValid()) {
      momentObj.add(7, "hours");
      const isoString = momentObj.toISOString();
      setTimestamp(isoString);
    }
  };
  // console.log("✅✅✅✅✅✅✅✅✅✅✅", item);
  const handleRenderTime = (item) => {
    if (isDefault) {
      return (
        <>
          {/* <div
            className={cx("status")}
            style={{ cursor: "pointer" }}
            onClick={() => showModal()}
          >
            <div className={cx("daily")}>
              <span>Mỗi ngày</span>
            </div>
            <div className={cx("staticContent")}>
              <span>Nội dung cố định</span>
            </div>
          </div> */}

          {!item.specificDate && !item.specificTime && (
            <ul className={cx("status")} onClick={() => showModal()}>
              {daysOfWeek.map((day) => (
                <li
                  key={day.id}
                  className={cx("item_status", {
                    selectedItem: item.daily
                      ? item.daily.includes(day.name)
                      : selectedDays.includes(day.name),
                  })}
                  style={{
                    backgroundColor: item.daily
                      ? item.daily.includes(day.name)
                      : selectedDays.includes(day.name)
                      ? "#1D3980"
                      : "#ECECEC",
                    color: item.daily
                      ? item.daily.includes(day.name)
                      : selectedDays.includes(day.name)
                      ? "white"
                      : "#A1A2A2",
                    cursor: "pointer",
                  }}
                >
                  {day.name}
                </li>
              ))}
            </ul>
          )}
          {item?.specificDate && item?.specificTime && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid",
                width: "max-content",
                borderRadius: 8,
                padding: 10,
                marginTop: 10,
                color: "#a1a2a2",
                cursor: "pointer",
              }}
              onClick={() => setIsModalVisible(true)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  color: "black",
                }}
              >
                <SlCalender />
                <span>
                  {specificDate?.dateString
                    ? specificDate?.dateString
                    : item?.specificDate?.dateString}
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  color: "black",
                }}
              >
                <CiClock2 />
                <span>
                  {specificTime?.timeString
                    ? specificTime?.timeString?.toUpperCase()
                    : item.specificTime?.timeString?.toUpperCase()}
                </span>
              </div>
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          {!item.specificDate && !item.specificTime && (
            <ul className={cx("status")} onClick={() => showModal()}>
              {daysOfWeek.map((day) => (
                <li
                  key={day?.id}
                  className={cx("item_status", {
                    selectedItem: item.daily
                      ? item.daily.includes(day.name)
                      : selectedDays.includes(day.name),
                  })}
                  style={{
                    backgroundColor: item.daily
                      ? item.daily.includes(day.name)
                      : selectedDays.includes(day.name)
                      ? "#1D3980"
                      : "#ECECEC",
                    color: item.daily
                      ? item.daily.includes(day.name)
                      : selectedDays.includes(day.name)
                      ? "white"
                      : "#A1A2A2",
                    cursor: "pointer",
                  }}
                >
                  {day.name}
                </li>
              ))}
            </ul>
          )}

          {item?.specificDate && item?.specificTime && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid",
                width: "max-content",
                borderRadius: 8,
                padding: 10,
                marginTop: 10,
                color: "#a1a2a2",
                cursor: "pointer",
              }}
              onClick={() => setIsModalVisible(true)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  color: "black",
                }}
              >
                <SlCalender />
                <span>
                  {item?.specificDate
                    ? item?.specificDate?.dateString
                    : specificDate?.dateString}
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  color: "black",
                }}
              >
                <CiClock2 />
                <span>
                  {item?.specificTime
                    ? item?.specificTime?.timeString?.toUpperCase()
                    : specificTime?.timeString?.toUpperCase()}
                </span>
              </div>
            </div>
          )}
        </>
      );
    }
  };

  const handleDelItem = () => {
    if (role !== "ADMIN" && isDefault) {
      message.error("Bạn không có quyền chỉnh sửa bài viết mặc định");
      return;
    }
    switch (activeKey) {
      case "2":
        const result = phongbanSelected.filter(
          (value) => value.id !== item?.id
        );
        dispatch(setPhongbanSelected(result));
        break;
      case "3":
        const result3 = phongban3Selected.filter(
          (value) => value.id !== item?.id
        );
        dispatch(setPhongban3Selected(result3));
        break;
      case "4":
        const result4 = phongban4Selected.filter(
          (value) => value.id !== item?.id
        );
        dispatch(setPhongban4Selected(result4));
        break;

      case "5":
        if (Array.isArray(phongban5Selected)) {
          const result5 = phongban5Selected.filter(
            (value) => value.id !== item?.id
          );
          console.log("result5", result5);

          dispatch(setPhongban5Selected(result5));
        } else {
          console.log("phongban5Selected is not an array");
          // Optionally initialize it as an empty array or handle the error as needed
          dispatch(setPhongban5Selected([]));
        }
        break;

      default:
        console.log("khong vao cac layout 2-5");
        break;
    }
  };
  return type === "schedules" ? (
    <div className={cx("container")}>
      <div className={cx("title")}>
        <div className={cx("image-lich")}>
          {item?.dataId !== "lichTruc&lichCongTac" ? (
            item?.dataId === "lichDangBang" ? (
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  width: "600px",
                  alignItems: "center",
                }}
              >
                <Image src={lichCongTac} width={90} height={90} />
                <span style={{ whiteSpace: "nowrap" }}>Lịch dạng bảng</span>
              </div>
            ) : item?.dataId === "lichTruc" ? (
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  width: "600px",
                  alignItems: "center",
                }}
              >
                <Image src={lichTruc} width={90} height={90} />
                <span style={{ whiteSpace: "nowrap" }}>Lịch trực</span>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  width: "600px",
                  alignItems: "center",
                }}
              >
                <Image src={lichCongTac} width={90} height={90} />
                <span style={{ whiteSpace: "nowrap" }}>Lịch công tác</span>
              </div>
            )
          ) : (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  width: "600px",
                  alignItems: "center",
                }}
              >
                <Image src={lichTruc} width={90} height={90} />
                <span style={{ whiteSpace: "nowrap" }}>Lịch trực</span>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  width: "600px",
                  alignItems: "center",
                }}
              >
                <Image src={lichCongTac} width={90} height={90} />
                <span style={{ whiteSpace: "nowrap" }}>Lịch công tác</span>
              </div>
            </div>
          )}
        </div>
        <div className={cx("name")}>
          <span>{item.title}</span>
        </div>
      </div>
      <div
        className={cx("delete-container")}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          onClick={handleDelItem}
          style={{ backgroundColor: "#E61D23", color: "white" }}
        >
          Delete
        </Button>
      </div>
    </div>
  ) : (
    <div className={cx("container")}>
      <div className={cx("title")}>
        {type == "blog" || type == "title" ? (
          <div style={{ paddingRight: 30 }}>
            {type == "title" && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <MdOutlineSubtitles size={40} />
                <span style={{ fontSize: 30, fontWeight: "700" }}>Tiêu đề</span>
              </div>
            )}
            {type == "blog" && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <BsFileEarmarkPost size={35} />
                <span style={{ fontSize: 30, fontWeight: "700" }}>
                  Bài viết
                </span>
              </div>
            )}
          </div>
        ) : (
          <div className={cx("image")}>
            {type === "video" && (
              <video src={content} style={{ borderRadius: 14 }} />
            )}
            {(type === "image" || type === "banner" || type === "schedule") && (
              <>
                <Image
                  src={content}
                  style={{ borderRadius: 14 }}
                  fallback={img_error}
                />
              </>
            )}
          </div>
        )}
        <div className={cx("name")}>
          <span>{title}</span>
        </div>
      </div>

      {handleRenderTime(item)}

      <div
        className={cx("delete-container")}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          onClick={handleDelItem}
          style={{ backgroundColor: "#E61D23", color: "white" }}
        >
          Delete
        </Button>
      </div>

      <Modal
        title={
          <span style={{ fontSize: "24px", marginBottom: "12px" }}>
            Cài đặt thời gian cho bài viết
          </span>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          disabled:
            selectedDays?.length === 0 && (!specificDate || !specificTime),
        }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Theo Tuần" key="1">
            <label style={{ margin: "12px 0" }}>Chọn thứ trong tuần</label>
            <ul
              className={cx("status")}
              style={{
                listStyle: "none",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "100%",
                border: "0.5px solid #a1a2a2",
                borderRadius: "10px",
                padding: "10px",
                color: "white",
              }}
            >
              {daysOfWeek.map((day) => (
                <li
                  key={day?.id}
                  className={cx("item_status", {
                    selectedItem: selectedDays?.includes(day.name),
                  })}
                  onClick={() => handleDayChange(day.name)}
                  style={{
                    listStyle: "none",
                    flex: 1,
                    textAlign: "center",
                    backgroundColor: selectedDays?.includes(day.name)
                      ? "#1D3980"
                      : "#ECECEC",
                    color: selectedDays?.includes(day.name)
                      ? "white"
                      : "#A1A2A2",
                    padding: "4px 12px",
                    border: "0.5px solid #a1a2a2",
                    cursor: "pointer",
                  }}
                >
                  {day.name}
                </li>
              ))}
            </ul>
          </TabPane>
          <TabPane tab="Theo thời gian cụ thể" key="2">
            <label style={{ margin: "12px 0" }}>Chọn ngày cụ thể</label>
            <br />
            <DatePicker
              value={specificDate?.date}
              format={"DD-MM-YYYY"}
              onChange={handleDateChange}
            />
            <br />
            <label style={{ margin: "12px 0" }}>Chọn thời gian</label>
            <br />
            <TimePicker
              use12Hours
              format="hh:mm A"
              value={specificTime?.time}
              onChange={handleTimeChange}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
}

export default Layout3;
