import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   library: "akakakak",
};

const refeshSlice = createSlice({
   name: "refesh",
   initialState,
   reducers: {
      libraryRefesh: (state, action) => {
         state.library = Math.random();
      },
   },
});

export const { libraryRefesh } = refeshSlice.actions;
export default refeshSlice.reducer;
// selector
export const refeshSelector = state => state.refesh.library;
