import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { CgScreen } from "react-icons/cg";
import { BsPeople } from "react-icons/bs";
import { TiDocument } from "react-icons/ti";
import { IoCalendarOutline } from "react-icons/io5";
import { Image } from "antd";
import logo from "../../assets/logo-header.png";
import { getRole, getToken, removeToken } from "../../utils/localStorage";
import "./Navbar.scss";
import "./TabsColumn.scss";
import { useDispatch, useSelector } from "react-redux";
import { userInfoSelector } from "../../store/user/userSlice";
import { changeTab } from "../../store/navTab/NavTabSlice";

const Navbar = () => {
  const role = getRole();
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.NavTabSlice.activeTab);

  const userInfo = useSelector(userInfoSelector);

  const [avtiveTab, setActiveTab] = useState(false);

  useEffect(() => {
    if (role === "DATAENTRY") {
      setActiveTab(true);
      dispatch(changeTab("thuvien"));
    }
  }, []);

  // Hide navbar for specific paths
  if (
    location.pathname === "/admin/info" ||
    location.pathname === "/phongban/info"
  ) {
    return null;
  }

  const handleLogout = () => {
    removeToken();
    window.location.reload();
  };

  const handleChange = () => {
    if (role === "ADMIN") {
      navigate("/admin/info");
    } else if (role === "DEPARTMENT") {
      navigate("/phongban/info");
    }
  };

  const renderRole = (role) => {
    switch (role) {
      case "ADMIN":
        return <span style={{ fontSize: 16, color: "white" }}>Admin</span>;
      case "DATAENTRY":
        return <span style={{ fontSize: 16, color: "white" }}>Nhập liệu</span>;
      default:
        return (
          <span style={{ fontSize: 16, color: "white" }}>QL phòng ban</span>
        );
    }
  };

  return (
    <div className="navbar-container">
      <div className="navbar">
        <div className="overlay"></div>
        <div className="logo-container">
          <div className="logo">
            <Image width={69.06} src={logo} preview={false} />
            <span className="title title-1">Bộ công an</span>
            <span className="title title-2">Công an thành phố</span>
            <span className="title title-2">Hồ Chí Minh</span>
          </div>
        </div>
        <div
          className="admin"
          onClick={handleChange}
          style={{ cursor: "pointer" }}
        >
          <span>Quyền truy cập</span>
          <div>{renderRole(role)}</div>
        </div>
        <div className="tabs">
          {!avtiveTab && (
            <>
              <Link
                to="/"
                className={`tabscolumn ${activeTab === "" ? "active-tab" : ""}`}
                onClick={() => dispatch(changeTab(""))}
              >
                <CgScreen className="tabscolumn-icon" />
                <span> Màn hình</span>
              </Link>
            </>
          )}
          <Link
            to="/phongban"
            className={`tabscolumn ${
              activeTab === "phongban" ? "active-tab" : ""
            }`}
            onClick={() => dispatch(changeTab("phongban"))}
          >
            <TiDocument className="tabscolumn-icon" />
            <span>Phòng Ban</span>
          </Link>
          <Link
            to="/thuvien"
            className={`tabscolumn ${
              activeTab === "thuvien" ? "active-tab" : ""
            }`}
            onClick={() => dispatch(changeTab("thuvien"))}
          >
            <BsPeople className="tabscolumn-icon" />
            <span>Thư Viện</span>
          </Link>

          {role !== "ADMIN" && (
            <Link
              to="/lich"
              className={`tabscolumn ${
                activeTab === "lich" ? "active-tab" : ""
              }`}
              onClick={() => dispatch(changeTab("lich"))}
            >
              <IoCalendarOutline className="tabscolumn-icon" />
              <span>Lịch</span>
            </Link>
          )}

          {role === "ADMIN" && (
            <Link
              to="/baoCao"
              className={`tabscolumn ${
                activeTab === "baoCao" ? "active-tab" : ""
              }`}
              onClick={() => dispatch(changeTab("baoCao"))}
            >
              <IoCalendarOutline className="tabscolumn-icon" />
              <span>Xuất Báo Cáo</span>
            </Link>
          )}
        </div>

        {/* Hiện thông tin và Đăng xuất */}
        <div className="user">
          <div className="avatar">
            <span style={{ color: "white" }}>
              {userInfo?.username?.charAt(0).toUpperCase()}
            </span>
          </div>
          <div className="user-info">
            <span className="user-name">{userInfo?.username}</span>
          </div>
        </div>
        <div className="button-logout">
          <button className="logout" onClick={handleLogout}>
            Đăng xuất
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
