import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { Button } from "antd";
import classNames from "classnames/bind";
import styles from "./CalendarTable.module.scss";
import { set, useForm } from "react-hook-form";
import TextArea from "antd/es/input/TextArea";
import create from "@ant-design/icons/lib/components/IconFont";
import {
  createScheduleTable,
  getScheduleTable,
} from "../../services/scheduleServices";
import { Notify } from "notiflix";

const cx = classNames.bind(styles);

function CalendarTable() {
  const [dataCalendarTable, setDataCalendarTable] = useState(
    new Array(8).fill(new Array(5).fill(""))
  );

  const [dataCalendarTableForRender, setDataCalendarTableForRender] = useState(
    new Array(5).fill(new Array(8).fill(""))
  );

  const handleGetDataInput = (e, rowIndex, colIndex) => {
    if (
      !Array.isArray(dataCalendarTable) ||
      !Array.isArray(dataCalendarTableForRender)
    ) {
      console.error(
        "dataCalendarTable or dataCalendarTableForRender is not an array"
      );
      return;
    }

    const newDataCalendarTable = dataCalendarTable.map((row, rIdx) =>
      Array.isArray(row)
        ? row.map((col, cIdx) =>
            rIdx === rowIndex && cIdx === colIndex ? e.target.value : col
          )
        : row
    );

    setDataCalendarTable(newDataCalendarTable);

    const newDataCalendarTableForRender = dataCalendarTableForRender.map(
      (row, rIdx) =>
        Array.isArray(row)
          ? row.map((col, cIdx) =>
              rIdx === rowIndex && cIdx === colIndex ? e.target.value : col
            )
          : row
    );
    setDataCalendarTableForRender(newDataCalendarTableForRender);
  };

  // các giá trị mặc định của bảng
  const [title, setTitle] = useState("Lịch dạng bảng");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm(); // hàm này dùng để lấy giá trị của input

  // lấy ra các ngày trong tuần
  const [weekDates, setWeekDates] = useState([]);
  const onSubmit = (data) => console.log(data);

  // hàm lấy ra các ngày trong tuần
  const getWeekDates = () => {
    const currentDate = new Date();
    // getDate: lấy ra ngày trong tháng
    // getDay: lấy ra số thứ tự của thứ trong tuần (vd: thứ 2 (1), thứ 3 (2), ...)
    const getDay = currentDate.getDay();
    const firstDate = currentDate.getDate() - getDay + 1; // lấy ra ngày đầu tiên trong tuần
    const week = [];

    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(currentDate.setDate(firstDate + i));
      week.push(
        `${nextDate.toLocaleDateString("vi-VN", {
          weekday: "long",
        })}<br />${nextDate.toLocaleDateString("vi-VN")}`
      );
      setWeekDates(week);
    }
  };

  const currentDate = new Date().toLocaleDateString("vi-VN");

  useEffect(() => {
    getWeekDates();
    console.log("weekDates", weekDates);
    console.log("ngày hiện tại", currentDate);
  }, []);

  const hanleAddScheduleTable = async (data) => {
    try {
      const res = await createScheduleTable(data, { type: "lichDangBang" });
      console.log(res.data.data);

      if (res.status === 200) {
        Notify.success("Lưu lịch dạng bảng thành công");
        setDataCalendarTable(res.data.data);
      }
    } catch (error) {
      console.log("error when add schedule table", error);
    }
  };

  const getTable = async () => {
    const res = await getScheduleTable();
    console.log("Table lay từ API", res.data);

    // Cập nhật dataCalendarTable với dữ liệu từ API
    if (res.data && res.data.length > 0) {
      const apiData = res.data[0].data;
      setDataCalendarTable(apiData);
      setTitle(res.data[0].title);
    }
  };

  useEffect(() => {
    getTable();
  }, []);

  const isCurrentDay = (dateString) => {
    const currentDate = new Date();
    const date = new Date(dateString);
    return (
      date.getDate() === currentDate.getDate() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    );
  };

  return (
    <form>
      <table className={cx("table-container")}>
        <thead>
          <tr className={cx("table-row")}>
            <th className={cx("table-title")} colSpan="9">
              <input
                className={cx("input-title")}
                {...register("title")}
                value={title}
                style={{
                  flex: 1,
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
                onChange={(e) => setTitle(e.target.value)}
              />
            </th>
          </tr>
          <tr className={cx("table-row")}>
            <th className={cx("table-title")}>Nội dung</th>
            {Array.isArray(weekDates) &&
              weekDates.map((date, index) => (
                <th
                  key={index}
                  className={cx("table-title", {
                    "current-day": isCurrentDay(date.split("<br />")[1]),
                    "today": currentDate === date.split("<br />")[1],
                  })}
                  dangerouslySetInnerHTML={{ __html: date }}
                />
              ))}
          </tr>
        </thead>
        <tbody>
          {dataCalendarTable.map((row, rowIndex) => (
            <tr key={rowIndex} className={cx("table-row")}>
              {row.map((col, colIndex) => (
                <td key={colIndex} className={cx("table-items")}>
                  <TextArea
                    className={cx("item-input-cal")}
                    style={{ fontSize: "14px" }}
                    value={dataCalendarTable[rowIndex][colIndex]}
                    onChange={(e) => handleGetDataInput(e, rowIndex, colIndex)}
                    autoSize
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={cx("sub-btn")}>
        <Button
          onClick={() => {
            hanleAddScheduleTable({ title, data: dataCalendarTable });
          }}
          type="primary"
          className={cx("btn-save")}
          style={{ backgroundColor: "#E61D23" }}
        >
          Lưu lịch dạng bảng
        </Button>
      </div>
    </form>
  );
}

export default CalendarTable;
