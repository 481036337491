import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AxiosClient, { API_ENDPOINT } from "../../../services/axiosClient";
import HeaderPBan from "../../../components/Header/HeaderPBan";
import classNames from "classnames/bind";
import detailsStyle from "./PhongBanDetails.module.scss";
import phongBanStyle from "./PhongBan.module.scss";
import { IoIosArrowBack, IoMdAddCircleOutline } from "react-icons/io";
import { FaRegTrashAlt } from "react-icons/fa";
import { Button, ConfigProvider, Modal } from "antd";
import Notiflix, { Loading } from "notiflix";
import DropdowButton from "../../../components/Dropdown/DropdowButton";
import Layout from "../../../components/ItemLeft/Layout";
import Setting from "../../../components/ItemLeft/Setting";
import Layout1 from "../../../components/Layout/Layout1";
import { useDispatch, useSelector } from "react-redux";
import {
  phongban3Selector,
  phongban4Selector,
  phongban5Selector,
  phongbanSelector,
  postPreviewSelector,
  setPhongban3Selected,
  setPhongban4Selected,
  setPhongban5Selected,
  setPhongbanSelected,
} from "../../../store/phongban/phongbanSlice";
import Layout3 from "../../../components/Layout/Layout3";
import AddContent from "../ThuVien/AddContent";
import Tabs from "../../../components/Tabs/Tabs";
import Marquee from "../../../components/Marquee/Marquee";
import Flag from "../../../assets/vietnam.png";

const cx = classNames.bind({ ...detailsStyle, ...phongBanStyle });

export default function PhongBanDetails() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [department, setDepartment] = useState({});
  const [titleDepartment, setTitleDepartment] = useState("");

  const getPhongBan = async () => {
    Loading.standard("Loading...");
    const res = await AxiosClient.get(`layouts/getOne/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_AUTH_TOKEN"
        )}`,
      },
    });

    console.log("🚀🚀🚀 😘", res.data);

    const layout2 = res?.data?.layoutScreen.find(
      (item) => item?.layout_position == 2
    );

    const layout3 = res?.data?.layoutScreen.find(
      (item) => item?.layout_position == 3
    );

    const layout4 = res?.data?.layoutScreen.find(
      (item) => item?.layout_position == 4
    );

    const layout5 = res?.data?.layoutScreen.find(
      (item) => item?.layout_position == 5
    );

    const handleFilterLayout = (layout) => {
      console.log("🚀🚀🚀🚀🚀 layout", layout);
      return layout?.data?.map((item) => {
        let checkTypeSchedule = "";

        switch (item?.dataId) {
          case "lichTruc":
            checkTypeSchedule = "lichTruc";
            break;
          case "lichCongTac":
            checkTypeSchedule = "lichCongTac";
            break;
          case "lichTruc&lichCongTac":
            checkTypeSchedule = "lichTruc&lichCongTac";
            break;
          case "lichDangBang":
            checkTypeSchedule = "lichDangBang";
            break;
          default:
            checkTypeSchedule = "";
            break;
        }

        return {
          id: item?._id,
          title: item?.title,
          type: item?.type,
          daily: item?.daily,
          timestamp: item?.timestamp,
          author: item.author,
          content:
            item?.type === "schedules"
              ? undefined
              : `${API_ENDPOINT}collections/download/${item?.content}`,
          dataId: checkTypeSchedule,
          addBy: item.addBy,
          isDefault: false,
          date: item?.time?.date,
          hours: item?.time?.hour,
          specificDate: item?.time?.date,
          specificTime: item?.time?.hour,
        };
      });
    };

    const departmentInfo = {
      delayTime: res.data.delayTime,
      departmentId: res.data.departmentId,
      image: res.data.image,
      departmentName: res.data.departmentName,
      departmentTitle: res.data.departmentTitle,
      slogan: res.data.slogan,
      lastLogin: res.data.updateBy.lastLogin,
      role: res?.data.updateBy.username,
    };
    setDepartment(departmentInfo);

    // console.log("✅✅✅", res.data.layoutScreen);
    dispatch(setPhongbanSelected(handleFilterLayout(layout2)));
    dispatch(setPhongban3Selected(handleFilterLayout(layout3)));
    dispatch(setPhongban4Selected(handleFilterLayout(layout4)));
    dispatch(setPhongban5Selected(handleFilterLayout(layout5)));

    // const filterLayout5 = layout5?.data;
    // dispatch(setPhongban5Selected(filterLayout5));
    Loading.remove();
  };

  const deletePhongBan = async () => {
    const res = await AxiosClient.delete(`departments/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_AUTH_TOKEN"
        )}`,
      },
    });
    console.log("res", res);
    if (res.status === 200) {
      Notiflix.Notify.success("Xóa phòng ban thành công");
    }
  };

  useEffect(() => {
    getPhongBan();
  }, []);

  const [activeKey, setActiveKey] = useState("1");

  const handleChangeTab = (key) => {
    setActiveKey(key);
  };

  const phongbanSelected = useSelector(phongbanSelector);
  const phongban3Selected = useSelector(phongban3Selector);
  const phongban4Selected = useSelector(phongban4Selector);
  const phongban5Selected = useSelector(phongban5Selector);
  // const postPreview = useSelector(postPreviewSelector);

  // console.log(phongbanSelected);
  // console.log(phongban3Selected);
  // console.log(phongban4Selected);
  // console.log(phongban5Selected);
  // console.log(postPreview);

  const Body_leftBottom = ({ activeKey }) => {
    if (activeKey === "1") {
      return (
        <Layout1
          id={id}
          deparmentSlogan={data.departmentSlogan}
          departmentInfomation={department}
        />
      );
    } else if (activeKey === "2") {
      return phongbanSelected?.map((item) => (
        <Layout3 item={item} activeKey={activeKey} key={item._id} />
      ));
    } else if (activeKey === "3") {
      return phongban3Selected?.map((item) => (
        <Layout3 item={item} activeKey={activeKey} key={item._id} />
      ));
    } else if (activeKey === "4") {
      return phongban4Selected?.map((item) => (
        <Layout3 item={item} activeKey={activeKey} key={item._id} />
      ));
    } else if (activeKey === "5") {
      return phongban5Selected?.map((item) => (
        <Layout3 item={item} activeKey={activeKey} key={item._id} />
      ));
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    await deletePhongBan();
    navigate("/phongban");
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isModalOpenAddContent, setIsModalOpenAddContent] = useState(false);
  const showModalAddContent = () => {
    setIsModalOpenAddContent(true);
  };
  const handleCancelAddContent = () => {
    setIsModalOpenAddContent(false);
  };
  const handleOkAddContent = () => {
    setIsModalOpenAddContent(false);
  };

  const [items, setItems] = useState([
    {
      key: "1",
      label: "Layout",
    },
    {
      key: "2",
      label: "Cài đặt",
    },
    {
      label: "Khu vực chính",
      key: "3",
    },
    {
      label: "Khu vực phụ bên phải",
      key: "4",
    },
    {
      label: "Lịch",
      key: "5",
    },
  ]);

  const extractTime = (isoString) => {
    const date = new Date(isoString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  // const timeOnly = extractTime(data.updateBy?.lastLogin);

  // <HeaderPBan
  //   pbName={department.departmentName}
  //   userName={department.departmentName}
  //   timeEnd={timeOnly}
  // />;

  const handleResetPhongBan = () => {
    dispatch(setPhongbanSelected([]));
    dispatch(setPhongban3Selected([]));
    dispatch(setPhongban4Selected([]));
    dispatch(setPhongban5Selected([]));
  };
  return (
    <div className={cx("pbd-container")}>
      <Marquee img={Flag}>
        Trung thành, Dũng cảm - Mưu trí, Sáng tạo - Vì hạnh phúc nhân dân - Vì
        bình yên Thành phố
      </Marquee>
      <div className={cx("pbd-header-section")}>
        <Link
          to={"/phongban"}
          className={cx("pbd-backIcon")}
          onClick={handleResetPhongBan}
        >
          <IoIosArrowBack size={24} />
        </Link>
        <div className={cx("pbd-header")}>
          <HeaderPBan
            isDetails={true}
            pbName={department.departmentName}
            userName={department.role}
            timeEnd={extractTime(department.lastLogin)}
            id={id}
          />
        </div>
        <div className={cx("pbd-deleteIcon-section")}>
          <div className={cx("pbd-deleteIcon")} onClick={showModal}>
            <FaRegTrashAlt size={24} />
          </div>
        </div>
        <Modal
          title={`Xóa phòng ban ${department.departmentName}`}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Xóa"
          cancelText="Hủy"
          okButtonProps={{
            style: { backgroundColor: "#E61D23", color: "white" },
          }}
        >
          <p>Bạn có chắc chắn muốn xóa phòng ban này không?</p>
        </Modal>
      </div>

      <div className={cx("pbd-dropdown-container")}>
        <div className={cx("pbd-layoutContent")}>
          <div className={cx("pbd-dropdown-section")}>
            <div className={cx("pbd-dropdown-addContent-section")}>
              <div className={cx("pbd-dropdown")}>
                <DropdowButton
                  activeKey={activeKey}
                  onButtonKey={handleChangeTab}
                />
              </div>
            </div>
            <div className={cx("pbd-addContent")}>
              <Button
                disabled={activeKey === "1" && true}
                className={cx("left_add")}
                onClick={() => {
                  showModalAddContent();
                }}
              >
                <IoMdAddCircleOutline
                  size={25}
                  style={{ marginRight: "8px" }}
                />
                <span>Thêm nội dung</span>
              </Button>
              <Modal
                title={<span style={{ fontSize: "24px" }}>Thêm nội dung</span>}
                open={isModalOpenAddContent}
                onOk={handleOkAddContent}
                okText={false}
                okButtonProps={{
                  style: {
                    height: 50,
                    width: 150,
                    backgroundColor: "#E61D23",
                    display: "none",
                  },
                }}
                onCancel={handleCancelAddContent}
                cancelButtonProps={{
                  style: { height: 50, width: 100, display: "none" },
                }}
                width={"80vw"}
              >
                <AddContent
                  isMargee={false}
                  handleCancelAddContent={handleCancelAddContent}
                  activeKey={activeKey}
                />
              </Modal>
            </div>
          </div>
          <Body_leftBottom activeKey={activeKey} />
        </div>

        <div className={cx("pbd-item_body_right")}>
          <div className={cx("body_right_tabs")}>
            <Tabs>
              <div label="Layout">
                <Layout
                  items={items}
                  resolution="Full HD"
                  onButtonClick={handleChangeTab}
                  activeKey={activeKey}
                  admin={false}
                />
              </div>
              <div label="Cài đặt">
                <Setting currentRoom={department} />
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
