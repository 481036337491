import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import style from "./Home.module.scss";
import { Navbar } from "../../components/index";
import HeaderAdmin from "../../components/Header/HeaderAdmin";
import Marquee from "../../components/Marquee/Marquee";
import Flag from "../../assets/vietnam.png";
import { Routes, Route, Outlet } from "react-router-dom";
import Screen from "./Screen";
import { Button, Modal, Dropdown, List } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { getDepartmentAll } from "../../services/departmentsServices";
import Notiflix from "notiflix";
import { createScreen } from "../../services/screenServices";
import { useDispatch } from "react-redux";
import { getDepartmentInfo, getUserInfo } from "../../store/info/infoThunk";
import { screenAdded } from "../../store/screen/screenSlide";

const DefaultContent = () => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nameScreen, setNameScreen] = useState("");
  const [departmentSelect, setDepartmentSelect] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);
  const [loadingAddScreen, setLoadingAddScreen] = useState(false);
  const cx = classNames.bind(style);

  //  get list departments
  useEffect(() => {
    getDepartmentAll()
      .then((res) => {
        const customDepartments = res.data.map((item) => {
          return {
            label: item?.departmentName,
            key: item?._id,
            icon: <UserOutlined />,
          };
        });

        setDepartmentList(customDepartments);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getDepartmentInfo());
  }, []);

  //  handle modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setLoadingAddScreen(true);
    if (departmentSelect) {
      const data = {
        screenName: nameScreen,
        department_id: [departmentSelect?.key],
      };
      createScreen(data)
        .then((res) => {
          Notiflix.Notify.success("Tạo thành công");
          setNameScreen("");
          setIsModalOpen(false);
          dispatch(screenAdded(res.data)); // Dispatch hành động thêm màn hình
          setLoadingAddScreen(false);
        })
        .catch((err) => {
          Notiflix.Notify.failure("Tạo không thành công");
          setIsModalOpen(false);
          setLoadingAddScreen(false);
        });
    } else {
      Notiflix.Notify.warning("vui long nhập đủ thông tin");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //  handle dropdown
  const handleMenuClick = (item) => {
    setDepartmentSelect(item);
  };

  const menu = (
    <div
      style={{
        maxHeight: "200px",
        overflowY: "auto",
        backgroundColor: "white",
        borderRadius: "5px",
        position: "absolute",
        right: 0,
        left: 0,
        top: 40,
      }}
    >
      <List
        dataSource={departmentList}
        style={{ padding: "0 10px" }}
        renderItem={(item) => (
          <List.Item key={item.key} onClick={() => handleMenuClick(item)}>
            {item.label}
          </List.Item>
        )}
      />
    </div>
  );

  return (
    <div className={cx("home-contain")}>
      <Marquee img={Flag}>
        Trung thành, Dũng cảm - Mưu trí, Sáng tạo - Vì hạnh phúc nhân dân - Vì
        bình yên Thành phố
      </Marquee>
      <HeaderAdmin
        name="Màn hình"
        addName={"Thêm màn hình"}
        onBtnAdd={showModal}
      />
      <Screen />
      {/* modal */}
      <Modal
        title="THÊM MÀN HÌNH"
        open={isModalOpen}
        onOk={handleOk}
        confirmLoading={loadingAddScreen}
        onCancel={handleCancel}
        okText="Lưu màn hình"
        okButtonProps={{
          style: { backgroundColor: "#E61D23", color: "white" },
        }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div className={cx("form-addScreeen")}>
          <div className={cx("box")}>
            <span className={cx("title")}>Tên màn hình</span>
            <input
              className={cx("input")}
              placeholder="Nhập tên màn hình"
              value={nameScreen}
              onChange={(e) => setNameScreen(e.target.value)}
            />
          </div>
          <div className={cx("box-dropDownList")}>
            <span className={cx("title")}>Thêm vào phòng ban</span>
            <Dropdown
              overlay={menu}
              placement="bottomCenter"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              <Button
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ fontSize: "15px" }}>
                  {departmentSelect
                    ? departmentSelect.label
                    : "Chọn phòng ban bạn muốn thêm"}
                </span>
                <span style={{ fontWeight: 600, fontSize: "16px" }}>
                  <DownOutlined />
                </span>
              </Button>
            </Dropdown>
          </div>
        </div>
      </Modal>
    </div>
  );
};

function Home() {
  return (
    <div className="contain">
      <div className="home">
        <Navbar />
        <div className="body">
          <Routes>
            <Route path="/" element={<DefaultContent />} />
          </Routes>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Home;
