import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import style from "./Account.module.scss";
import { Image, Input } from "antd";
import accImage from "../../../assets/acc.png";
import { useSelector } from "react-redux";
import { departmentInfoSelector, userInfoSelector } from "../../../store/user/userSlice";
const cx = classNames.bind(style);

export default function Account({ admin }) {
  const userInfo = useSelector(userInfoSelector);
  const departmentInfo = useSelector(departmentInfoSelector)


  return (
    <div className={cx("container")}>
      <div className={cx("account-section")}>
        <div className={cx("title")}>
          <span>Tài khoản của bạn</span>
        </div>
        <div className={cx("account-info")}>
          <div className={cx("account-img")}>
            <Image
              src={accImage}
              style={{ borderRadius: "99px", width: "120px", height: "120px" }}
            />
          </div>
          <div className={cx("account-form")}>
            <div className={cx("HovaTen")}>
              <div className={cx("Ten")}>
                <label>Tên</label>
                <Input
                  placeholder="Tên"
                  value={userInfo?.username}
                  contentEditable={false}
                />
              </div>
            </div>
            {/* <div className={cx("email")}>
              <label>Tài khoản email</label>
              <Input
                placeholder="Email"
                value={userInfo?.email}
                contentEditable={false}
              />
            </div> */}
            <div className={cx("email")}>
              <label>Phòng ban trực thuộc</label>
              <Input
                placeholder="Nhập tên phòng ban"
                value={departmentInfo?.departmentName}
                contentEditable={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
