import React, { useCallback, useEffect, useState } from "react";
import { Input, Button, message } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  phongbanLayoutSelector,
  setPhongbanLayout,
} from "../../store/phongban/phongbanSlice";
import "./Layout1.scss";
import { getRole } from "../../utils/localStorage";
import { departmentInfoSelector } from "../../store/user/userSlice";

const StyledInput = styled(Input)`
  white-space: pre-wrap;
  overflow-wrap: break-word;
  height: auto;
`;

const Layout1 = React.memo(({ hide, admin, id, departmentInfomation }) => {
  const dispatch = useDispatch();

  const phongbanLayout = useSelector(phongbanLayoutSelector);
  const [slogan, setSlogan] = useState(departmentInfomation.slogan ?? "");
  const [departmentName, setDepartmentName] = useState(
    departmentInfomation?.departmentName ?? ""
  );
  const [titleDepartment, setTitleDepartment] = useState(departmentInfomation?.departmentTitle || "")
  // console.log("departmentInfomation", departmentInfomation);

  const role = getRole();

  const handleSubmit = useCallback(() => {
    if (id) {
      if (!departmentName) {
        message.error("Xin hãy nhập tên phòng ban");
      } else {
        departmentInfomation.departmentName = departmentName;
        departmentInfomation.departmentTitle = titleDepartment;
        const newLayout = {
          ...phongbanLayout,
          departmentTitle: departmentInfomation?.departmentTitle
            ? departmentInfomation?.departmentTitle
            : titleDepartment,
          departmentId: departmentInfomation.departmentId
            ? departmentInfomation.departmentId
            : id,
          departmentName: departmentInfomation.departmentName
            ? departmentInfomation.departmentName
            : departmentName,
          slogan: slogan ? slogan : departmentInfomation?.slogan,
        };

        dispatch(setPhongbanLayout(newLayout));
        message.success("Cập nhật phòng ban thành công");
      }
    } else if (role === "ADMIN") {
      if (!slogan || !departmentName) {
        message.error("Xin hãy nhập tên phòng ban và slogan");
        return;
      } else {
        console.log("đã vào");
        departmentInfomation.departmentName = departmentName;
        departmentInfomation.slogan = slogan;
        departmentInfomation.departmentTitle = titleDepartment;
        // console.log("departmentInfomation", departmentInfomation);
        const newLayout = {
          ...phongbanLayout,
          departmentTitle: departmentInfomation?.departmentTitle
            ? departmentInfomation?.departmentTitle
            : titleDepartment,
          departmentId: departmentInfomation.departmentId
            ? departmentInfomation.departmentId
            : id,
          departmentName: departmentName
            ? departmentName
            : departmentInfomation.departmentName,
          slogan: slogan ? slogan : departmentInfomation?.slogan,
        };
        dispatch(setPhongbanLayout(newLayout));
        message.success("Cập nhật phòng ban thành công");
      }
    } else if (role === "DEPARTMENT" && !id) {
      if (!departmentName) {
        message.error("Xin hãy nhập tên phòng ban");
        return;
      } else {
        departmentInfomation.departmentName = departmentName;
        departmentInfomation.slogan = slogan;
        departmentInfomation.departmentTitle = titleDepartment;
        const newLayout = {
          ...phongbanLayout,
          departmentTitle: departmentInfomation?.departmentTitle
            ? departmentInfomation?.departmentTitle
            : titleDepartment,
          departmentId: departmentInfomation.departmentId
            ? departmentInfomation.departmentId
            : id,
          departmentName: departmentInfomation.departmentName
            ? departmentInfomation.departmentName
            : departmentName,
          slogan: slogan ? slogan : departmentInfomation?.slogan,
        };

        dispatch(setPhongbanLayout(newLayout));
        message.success("Cập nhật phòng ban thành công");
      }
    }
  }, [
    dispatch,
    phongbanLayout,
    departmentName,
    titleDepartment,
    slogan,
    departmentInfomation,
    id,
    role,
  ]);

  return (
    <div className="layoutContainer" admin={admin ? 1 : 0}>
      <div className={`layout-name ${admin ? "layout1_admin" : ""}`}>
        <span style={{ color: "#000", fontWeight: "bold", marginBottom: 5 }}>
          Tiêu đề phòng ban
        </span>
        <Input
          placeholder={
            departmentInfomation?.departmentTitle
              ? departmentInfomation?.departmentTitle
              : titleDepartment
          }
          value={titleDepartment}
          onChange={(e) => setTitleDepartment(e.target.value)}
        />
      </div>
      <div className={`layout-name ${admin ? "layout1_admin" : ""}`}>
        <span style={{ color: "#000", fontWeight: "bold", marginBottom: 5 }}>
          Tên phòng ban sẽ được hiển thị
        </span>
        <Input
          placeholder={
            departmentInfomation?.departmentName
              ? departmentInfomation?.departmentName
              : departmentName
          }
          value={departmentName}
          onChange={(e) => setDepartmentName(e.target.value)}
        />
      </div>
      <div className={`layout-slogan ${admin ? "" : "layout1_admin"}`}>
        <span style={{ color: "black", fontWeight: "bold", marginBottom: 5 }}>
          Slogan sẽ được hiển thị
        </span>
        <StyledInput
          className="input_slogan"
          placeholder={
            departmentInfomation?.slogan ? departmentInfomation?.slogan : slogan
          }
          value={slogan}
          // disabled={role === "DEPARTMENT" || id ? true : false}
          onChange={(e) => setSlogan(e.target.value)}
        />
        <span style={{ fontSize: 12, fontStyle: "italic", marginTop: "8px" }}>
          Những thông tin trên sẽ luôn được hiển thị trên màn hình
        </span>
      </div>
      <Button className="btn-slogan" type="primary" onClick={handleSubmit}>
        Lưu thông tin
      </Button>
    </div>
  );
});

export default Layout1;
