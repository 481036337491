import React, { useEffect, useId, useMemo, useState } from 'react'
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import { Calendar as MiniCalendar } from 'react-calendar';
import dayjs from 'dayjs'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-calendar/dist/Calendar.css';
import './Calendar.scss'
import 'dayjs/locale/vi'
import { useDispatch, useSelector } from 'react-redux';
import { modalEditSheduleSelector, modalLoadingSelector, modalScheduleSelector, setModalSchedule } from '../../store/modal/modalSlice'
import ModalSchedule from '../Modal/ModalSchedule/ModalSchedule';
import { useCalendarEvents } from './hooks/useCalendarEvents';
import ScheduleList from './ScheduleList/ScheduleList';
import { useOpenModalSchedule } from './hooks/useOpenModalSchedule';
import ModalEditSchedule from '../Modal/ModalEditSchedule/ModalEditSchedule';

const localizer = dayjsLocalizer(dayjs)
dayjs.locale('vi')

function CalendarCustom({ type, listSchedule }) {
	const dispatch = useDispatch()
	const { isModalShedule, data } = useSelector(modalScheduleSelector)
	const { isModalEditShedule } = useSelector(modalEditSheduleSelector)
	const modalLoading = useSelector(modalLoadingSelector)

	const [selectedDay, setSelectedDay] = useState(new Date());
	const idModal = useId();
	// console.log("isModalScheduleOpen", isModalShedule);
	// console.log("listSchedule", listSchedule);

	const [dayStart, setDayStart] = useState('')
	const [dayEnd, setDayEnd] = useState('')
	const eventsBigCalendar = useCalendarEvents(listSchedule);
	const {
		handleAddSchedule,
		handleClickDay,
		handleEditSchedule
	} = useOpenModalSchedule(dispatch, type, setSelectedDay, listSchedule);
	// console.log(eventsBigCalendar);

	const monthNow = dayjs().add(1, 'm').format('MM')
	const dateNow = dayjs().format('DD/MM/YYYY')

	useEffect(() => {
	}, [listSchedule, modalLoading])

	const components = useMemo((props) => ({
		week: {
			header: (props) => {
				const format = props.localizer.format(props.date, 'dd').split('T')
				return format.length > 1 ? `Thứ ${format[1]}` : format[0];
			}
		},
		toolbar: ({ label }) => {
			const format = label.split(' ')
			setDayStart(format[2])
			setDayEnd(format[4])
		}
	}), [])

	const tileClassName = ({ date, view }) => {
		if (
			view === 'month' &&
			dayjs(date).get('D') === dayjs(selectedDay).get('date') &&
			dayjs(date).format('MM') === monthNow
		) {
			return 'tile-custom tile-custom-selected';
		}
		return 'tile-custom';
	}

	const eventPropGetter = (event, b, c, d) => ({
		className: d ? 'event-custom-selected' : 'event-custom',
	})

	return (
		<div className='calendar-container'>
			<div className='mini-calendar-container'>
				<span className='header'>
					Tháng {monthNow}
				</span>
				<MiniCalendar
					className='mini-calendar'
					tileClassName={tileClassName}
					locale='vi-VN'
					showNavigation={false}
					onClickDay={(e) => handleClickDay(e)}
				/>
				<ScheduleList listSchedule={listSchedule} dateNow={dateNow} type={type} />
			</div>
			<div className='big-calendar-container'>
				<div className='date-time-container'>
					<span className='date-time'>{dayStart}/{monthNow} - {dayEnd}/{monthNow}</span>
				</div>
				<Calendar
					view="week"
					selectable
					localizer={localizer}
					defaultDate={new Date()}
					style={{ fontSize: '16px', }}
					onSelectSlot={(event) => handleAddSchedule(event)}
					onSelectEvent={(event) => handleEditSchedule(event)}
					events={eventsBigCalendar}
					eventPropGetter={eventPropGetter}
					components={components}
				/>
			</div>
			{isModalShedule && <ModalSchedule key={idModal} />}
			{isModalEditShedule && <ModalEditSchedule key={idModal} />}
		</div>
	)
}

export default CalendarCustom