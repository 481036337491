import AxiosClient from "./axiosClient";

const API_ENDPOINT = "auth/";

export const login = (data) => {
  // console.log("dataLogin", data);
  return AxiosClient.post(API_ENDPOINT + "signin", data);
};

export const changePasswordUser = (data) => {
  return AxiosClient.put(API_ENDPOINT + "changePassword", data);
};

export const forgotPassword = (data) => {
  return AxiosClient.post(API_ENDPOINT + "forgotPassword", data);
};

export const changePassword = (data) => {
  return AxiosClient.put(API_ENDPOINT + "changePassword", data);
};
