import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./ForgotPassword.module.scss";
import { Button } from "../../components/Button/Button";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { forgotPassword } from "../../services/authServices";
import ModalLogin from "../../components/Modal/ModalLogin/ModalLogin.jsx";
const cx = classNames.bind(styles);

export default function ForgotPassword() {
  // Tạo các states cần thiết
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submitController = async (data) => {
    const { username, password, confirmPassword } = data;
    try {
      if (password !== confirmPassword) {
        return alert("Mật khẩu không trùng khớp");
      }
      const response = await forgotPassword({ username, password });
      if (response.status === 201) {
        setIsSuccess(true);
        setShowModal(true);
      }
    } catch (error) {
      setIsSuccess(false);
      setShowModal(true);
      setMessage(error.response?.data?.message || "Đã xảy ra lỗi");
      console.error("Error during password reset:", error); // Ensure no sensitive data is logged
    }
  };

  return (
    <div className={cx("container-login")}>
      <form className={cx("form")} onSubmit={handleSubmit(submitController)}>
        <div className={cx("title-and-desc")}>
          <div className={cx("title-section")}>
            <p className={cx("title")}>Quên mật khẩu</p>
          </div>
          <div className={cx("desc-section")}>
            <p className={cx("desc")}>
              Nhập mật khẩu mới và gửi yêu cầu đổi mật khẩu mới để đổi mật khẩu
              nhé!
            </p>
          </div>
        </div>

        <div className={cx("input-group")}>
          <div className={cx("input-field")}>
            <span>Nhập tên đăng nhập</span>
            <input
              {...register("username", { required: true })} // đăng ký input này với react-hook-form
              style={{ height: "50px" }}
              type="text"
              placeholder="Nhập tên đăng nhập"
            />
          </div>
          <div className={cx("input-field")}>
            <span>Nhập mật khẩu mới</span>
            <input
              {...register("password", { required: true })} // đăng ký input này với react-hook-form
              style={{ height: "50px" }}
              type="password"
              placeholder="Nhập mật khẩu mới"
            />
          </div>

          <div className={cx("input-field")}>
            <span>Nhập lại mật khẩu mới</span>
            <input
              {...register("confirmPassword", { required: true })} // đăng ký input này với react-hook-form
              style={{ height: "50px" }}
              type="password"
              placeholder="Nhập lại mật khẩu mới"
            />
          </div>
        </div>
        <div className={cx("button-login")}>
          <Button>Yêu cầu thay đổi mật khẩu</Button>
        </div>
      </form>
      {showModal && (
        <ModalLogin
          message={message}
          success={isSuccess}
          failed={!isSuccess}
          onClose={() => {
            if (isSuccess) {
              window.location.href = "/";
            }
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
}
