import AxiosClient from "./axiosClient";

export const createLayout = (data) => {
  console.log("creatreLayout", data);
  return AxiosClient.put(`layouts`, data);
};

export const createLayoutDetail = (id, data) => {
  console.log("createLayoutDetail", data);
  return AxiosClient.put(`layouts/${id}`, data);
};

export const postPreviewLayout = (data) => {
  // const { departmentId, slogan, layout, delayTime } = data;
  return AxiosClient.post(`layouts` + "/preview", data);
};
