import AxiosClient from "./axiosClient";

const API_ENDPOINT = "users/";

export const getInfoUser = () => {
  return AxiosClient.get(API_ENDPOINT + "details");
};

export const deleteUser = (id) => {
  return AxiosClient.delete(API_ENDPOINT + `delete/${id}`);
};

// lấy danh sách yêu cầu cấp lại mật khẩu
export const getRequestPassword = () => {
  return AxiosClient.get(API_ENDPOINT + "requestResetPassword");
};

// duyệt yêu cầu cấp lại mật khẩu
export const acceptRequestPassword = (username, type) => {
  return AxiosClient.put(API_ENDPOINT + `verify/${username}/${type}`);
};

// Đưa user vào phòng ban
export const addToDepartment = (userId, departmentId ) => {
  return AxiosClient.put(API_ENDPOINT + `addToDepartment/${userId}/${departmentId}`);
};
