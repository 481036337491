import { createSlice } from "@reduxjs/toolkit";

const NavTabSlice = createSlice({
  name: "navTab",
  initialState: {
    activeTab: "",
  },
  reducers: {
    changeTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

export const activeTabSelector = (state) => state.NavTabSlice.activeTab;

export const { changeTab } = NavTabSlice.actions;
export default NavTabSlice.reducer;
