import React, { useEffect, useId, useState } from "react";
import classNames from "classnames/bind";
import style from "./User.module.scss";
import { Button, Input, Modal, Select, Table, message } from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import AxiosClient from "../../../services/axiosClient";
import { useDispatch, useSelector } from "react-redux";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { BsThreeDots } from "react-icons/bs";
import { deleteUser } from "../../../services/userServices";
import {
  modalEditPermissonSelector,
  modalEditUserSelector,
  modalLoadingSelector,
  setModalEditUser,
  setModalLoading,
} from "../../../store/modal/modalSlice";
import ModalEditUser from "../../Modal/ModalEditUser/ModalEditUser";

const cx = classNames.bind(style);
const { Option } = Select;

const EditComponent = ({ record }) => {
  const dispatch = useDispatch();
  const handleEdit = (data) => {
    // console.log("Edit data: ", data);
    dispatch(
      setModalEditUser({
        isModalEditUserOpen: true,
        data,
      })
    );
  };

  const handleDelete = async (data) => {
    console.log("Delete data: ", data);
    await deleteUser(data.key)
      .then((res) => {
        message.success("Xóa thành công");
        dispatch(setModalLoading(true));
      })
      .catch((err) => {
        message.error(`Xóa thất bại ${err}`);
      });
  };

  const handlePermission = (data) => {
    dispatch(
      setModalEditUser({
        isModalEditPermissionOpen: true,
        data,
      })
    );
  };
  return (
    <div style={{ cursor: "pointer" }}>
      <Menu>
        <MenuButton
          height={30}
          as={IconButton}
          aria-label="Options"
          icon={<BsThreeDots />}
          style={{ border: "none", backgroundColor: "transparent" }}
        />
        <MenuList style={{ minWidth: "120px" }}>
          <MenuItem
            style={{
              border: "none",
              borderBottom: "0.5px solid #D6D6D6",
              borderRadius: "0",
              paddingTop: "0",
              justifyContent: "center",
            }}
            onClick={() => handleEdit(record)}
          >
            Đổi mật khẩu
          </MenuItem>
          {record.permission !== "ADMIN" && (
            <MenuItem
              style={{
                border: "none",
                borderBottom: "0.5px solid #D6D6D6",
                borderRadius: "0",
                justifyContent: "flex-start",
              }}
              onClick={() => handlePermission(record)}
            >
              Thay đổi phòng ban
            </MenuItem>
          )}
          <MenuItem
            style={{
              paddingTop: "0",
              paddingBottom: "0",
              border: "none",
              borderRadius: "0",
              // borderTop: '1px solid #D6D6D6',
              justifyContent: "center",
            }}
            onClick={() => handleDelete(record)}
          >
            Xóa
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
};

export default function UserComponent() {
  // Data table columns
  const columns = [
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Quyền truy cập",
      dataIndex: "permission",
      key: "permission",
      align: "center",
    },
    {
      title: "Người thêm",
      dataIndex: "addBy",
      key: "addBy",
      align: "center",
    },
    {
      title: "Lần đăng nhập cuối cùng",
      dataIndex: "lastLogin",
      key: "lastLogin",
      align: "center",
    },
    {
      title: "",
      dataIndex: "options",
      render: (_, record) => <EditComponent record={record} />,
    },
  ];

  const modalEditPermissonSelectors = useSelector(modalEditPermissonSelector);
  const modalId = useId();
  const [data, setData] = useState([]);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("DEPARTMENT");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [loadingAddUser, setLoadingAddUser] = useState(false);

  const loading = useSelector(modalLoadingSelector);
  const { isModalEditUserOpen, data: dataEdit } = useSelector(
    modalEditUserSelector
  );
  // Fetch data
  const fetchData = async () => {
    const res = await AxiosClient.get("users");
    // const res =await fetch("http://45.32.62.192:5000/users", {
    //   method: "GET",
    // })
    // console.log(res.body);
    setData(res.data);
  };

  useEffect(() => {
    fetchData();
  }, [loading]);

  const extractTime = (isoString) => {
    const date = new Date(isoString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const transformedData = data.map((item) => {
    const { _id, username, role, addBy, lastLogin } = item;
    return {
      key: _id,
      name: username,
      permission: role,
      addBy: addBy || "admin",
      lastLogin: extractTime(lastLogin) || "2021-10-10",
    };
  });

  // Validation function
  const validate = () => {
    const newErrors = {};
    // if (!email) {
    //   newErrors.email = "Email không được để trống";
    // } else if (!/\S+@\S+\.\S+/.test(email)) {
    //   newErrors.email = "Email không hợp lệ";
    // }
    if (!username) newErrors.username = "Username được để trống";
    // if (!lastName) newErrors.lastName = "Tên không được để trống";
    if (!password) newErrors.password = "Mật khẩu không được để trống";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Modal handlers
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setLoadingAddUser(true);
    console.log("clicked ok");
    if (!validate()) return;
    const res = await AxiosClient.post("auth/signup", {
      username: username,
      password: password,
      role: role,
      // fullName: `${firstName} ${lastName}`,
      fullName: null,
      email: null,
    });
    if (res.status === 201) {
      setLoadingAddUser(false);
      message.success("Thêm người dùng thành công");
      await fetchData();
      setIsModalOpen(false);
      setUsername("");
      setEmail("");
      setFirstName("");
      setLastName("");
      setPassword("");
      setRole("DEPARTMENT");
      setErrors({});
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setErrors({});
  };

  // Input handlers
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangeFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handleChangeLastName = (e) => {
    setLastName(e.target.value);
  };

  const handleChangePassword = (e) => {
    const newPassword = e.target.value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    setPassword(newPassword);
  };

  const handleChangeRole = (value) => {
    console.log("Role: ", value);
    setRole(value);
  };

  const handleChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  return (
    <div className={cx("container")}>
      <div className={cx("user-info-section")}>
        <div className={cx("header-section")}>
          <div className={cx("title-desc")}>
            <div className={cx("title")}>
              <span>Người dùng</span>
            </div>
            <div className={cx("desc")}>
              <span>
                Mời và quản lý thành viên cũng như chỉnh sửa những nhóm họ có
                quyền truy cập
              </span>
            </div>
          </div>
          {/* button */}
          <div className={cx("addBtn")}>
            <Button
              color="white"
              style={{ backgroundColor: "#E61D23", color: "white" }}
              onClick={showModal}
            >
              Thêm thành viên
            </Button>
            <Modal
              title={
                <span style={{ fontSize: "26px", fontWeight: "bold" }}>
                  Thêm người dùng
                </span>
              }
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              confirmLoading={loadingAddUser}
              okText="Thêm"
              width={"60%"}
              okButtonProps={{ style: { backgroundColor: "#E61D23" } }}
              cancelButtonProps={{ style: { display: "none" } }}
            >
              {/* <div className={cx("label-input")}>
                <label>Mời mọi người qua email</label>
                <Input
                  onChange={handleChangeEmail}
                  placeholder="Nhập email"
                  type="email"
                  value={email}
                />
                {errors.email && (
                  <div className={cx("error")}>* {errors.email}</div>
                )}
              </div> */}

              <div className={cx("HoVaTen")}>
                {/* <div className={cx("label-input")}>
                  <label>Họ người dùng</label>
                  <Input
                    onChange={handleChangeFirstName}
                    placeholder="Nhập họ người dùng"
                    type="text"
                    value={firstName}
                  />
                  {errors.firstName && (
                    <div className={cx("error")}>* {errors.firstName}</div>
                  )}
                </div> */}
                <div className={cx("label-input")}>
                  <label style={{ marginBottom: 5 }}>Username:</label>
                  <Input
                    onChange={handleChangeUsername}
                    placeholder="Nhập tên người dùng đăng nhập"
                    type="text"
                    value={username}
                  />
                  {errors.username && (
                    <div className={cx("error")}>* {errors.username}</div>
                  )}
                </div>
              </div>

              <div className={cx("label-input")}>
                <label style={{ marginBottom: 5 }}>Mật khẩu</label>
                <Input.Password
                  onChange={handleChangePassword}
                  placeholder="Nhập mật khẩu"
                  visibilityToggle={{
                    visible: passwordVisible,
                    onVisibleChange: setPasswordVisible,
                  }}
                  value={password}
                />
                {errors.password && (
                  <div className={cx("error")}>* {errors.password}</div>
                )}
              </div>

              <div className={cx("label-input")}>
                <label>Phân quyền truy cập cho người dùng</label>
                <div className={cx("seclect-permission")}>
                  <Select
                    defaultValue="DEPARTMENT"
                    onChange={handleChangeRole}
                    style={{ width: "100%", minHeight: 50 }}
                    value={role}
                  >
                    <Option value="ADMIN">
                      <div className={cx("permission")}>
                        <div className={cx("permission-title")}>
                          Nhóm quản trị
                        </div>
                        <div className={cx("permission-des")}></div>
                      </div>
                    </Option>
                    <Option value="DEPARTMENT">Nhóm quản lý phòng ban</Option>
                    <Option value="DATAENTRY">Nhóm nhập liệu phòng ban</Option>
                  </Select>
                </div>
              </div>
            </Modal>
          </div>
        </div>

        <div className={cx("userList")}>
          <div className={cx("search-section")}>
            <Input placeholder="Tìm kiếm" prefix={<SearchOutlined />} />
          </div>
          <div className={cx("user-count")}>
            <span>{data.length} user</span>
          </div>

          <div className={cx("userList-table")}>
            <Table
              dataSource={transformedData}
              columns={columns}
              pagination={false}
            />
          </div>
        </div>
      </div>
      {isModalEditUserOpen && (
        <ModalEditUser
          key={modalId}
        />
      )}
       {modalEditPermissonSelectors && (
        <ModalEditUser
          key={modalId}
          editPermission={true}
        />
      )}
    </div>
  );
}
