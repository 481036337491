import React, { useState, useEffect } from "react";
import "./MainScreen.scss";
import LogoCA1 from "../../assets/CAH2.png";
import LogoCA2 from "../../assets/LogoCATPHCM.png";
// import vector from "../asset/vector.png";
import vector from "../../assets/vector.png";
import { Image } from "antd";
import Caledar from "./Caledar";
import AxiosClient from "../../services/axiosClient";
import LayoutSection from "./LayoutSection";
import { getContent } from "../../services/contentServices";

function MainScreen() {
  const [data, setData] = useState({});
  const [content, setContent] = useState({});
  const [imageList, setImageList] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState("");

  const [layoutt1, setLayoutt1] = useState({});
  const [layoutt2, setLayoutt2] = useState(null);
  const [layoutt3, setLayoutt3] = useState(null);
  const [layoutt4, setLayoutt4] = useState(null);
  const [layoutt5, setLayoutt5] = useState(null);

  useEffect(() => {
    getContent()
      .then((res) => {
        console.log(res.data);
        const tempLayouts = {
          layoutt1: null,
          layoutt2: [],
          layoutt3: [],
          layoutt4: [],
          layoutt5: [],
        };

        res.data.forEach((data) => {
          console.log(data.layout);
          switch (data.layout) {
            case 1:
              tempLayouts.layoutt1 = data;
              break;

            case 2:
              tempLayouts.layoutt2.push(data);
              break;

            case 3:
              tempLayouts.layoutt3.push(data);
              break;

            case 4:
              tempLayouts.layoutt4.push(data);
              break;

            default:
              tempLayouts.layoutt5.push(data);
              break;
          }
        });

        setLayoutt1(tempLayouts.layoutt1);
        setLayoutt2(tempLayouts.layoutt2);
        setLayoutt3(tempLayouts.layoutt3);
        setLayoutt4(tempLayouts.layoutt4);
        setLayoutt5(tempLayouts.layoutt5);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // console.log(layoutt5);

  useEffect(() => {
    const fetchImage = async (image) => {
      try {
        const response = await fetch(
          `${AxiosClient}collections/download/${image}.png`,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("LOCAL_STORAGE_AUTH_KEY"),
            },
          }
        );
        const blob = await response.blob(); // Handling binary data
        const imageUrl = URL.createObjectURL(blob); // Creating a URL for the image blob
        console.log("image URL", imageUrl); // Logging the image URL for use, e.g., in an <img> src
        setImage(imageUrl);
      } catch (error) {
        console.error("Failed to fetch image", error);
      }
    };
    fetchImage("20240624070441");
  }, []);

  // lấy thông tin phòng ban
  useEffect(() => {
    const fetchLayout = async () => {
      try {
        const response = await AxiosClient.get("Contents/");
        const departments = response.data;
        setData(departments);
        setLoading(false);
      } catch (error) {
        setError("Error fetching list images");
        setLoading(false);
      }
    };

    fetchLayout();
  }, []);

  // Function to get collection_id by layout
  function getCollectionIdsByLayout(data) {
    const layoutMap = {};
    if (data.length > 0) {
      data.forEach((item) => {
        const layout = item.layout;
        const collectionId = item.collection_id[0]; // Assuming collection_id is an array with one item

        if (!layoutMap[layout]) {
          layoutMap[layout] = [];
        }

        layoutMap[layout].push(collectionId);
      });
    }

    return layoutMap;
  }

  const collectionIdsByLayout = getCollectionIdsByLayout(data);

  useEffect(() => {
    const fetchContentByCollectionIds = async () => {
      try {
        const allContent = {};
        const allPromises = [];

        Object.keys(collectionIdsByLayout).forEach((layout) => {
          collectionIdsByLayout[layout].forEach((idCollection) => {
            const promise = AxiosClient.get(`/Contents/${idCollection}`)
              .then((response) => {
                allContent[idCollection] = response.data;
              })
              .catch((error) => {
                setError(
                  "Error fetching content for collection " + idCollection
                );
              });
            allPromises.push(promise);
          });
        });

        await Promise.all(allPromises);
        setContent(allContent);
        setLoading(false);
      } catch (error) {
        setError("Error fetching content by collection ids");
        setLoading(false);
      }
    };

    if (data.length > 0) {
      fetchContentByCollectionIds();
    }
  }, [data]);
  if (loading) {
    return <p>Loading...</p>;
  }

  // const hasLayout4 = Boolean(imageList[3]);
  // const hasLayout5 = Boolean(data.layout5 && data.layout5.length > 0);
  const hasLayout4 = true;
  const hasLayout5 = true;
  let hasLayout45 = true;
  // if (!hasLayout4 && !hasLayout5) {
  //   hasLayout45 = false;
  // }
  const imageLists = [image, image, image];

  console.log(layoutt1);

  return (
    <div className="screenlayout_contain">
      <div
        className={`screenlayout_item ${
          !hasLayout45
            ? "no-layout4-layout5"
            : !hasLayout4
            ? "no-layout4"
            : !hasLayout5
            ? "no-layout5"
            : ""
        }`}
      >
        <div className="screenitem1 screenlayout1">
          <div className="logo_container">
            <img className="img_logo" width={90} src={LogoCA1} alt="Logo CA1" />
            <div className="logo_left"></div>
          </div>
          <div className="title_contain">
            <div className="title">
              <span>BỘ CÔNG AN</span>
            </div>
            <div className="content">
              <span>CÔNG AN THÀNH PHỐ HỒ CHÍ MINH</span>
            </div>
            <div className="slogan">
              <span>
                Xây dựng lực lượng Công an Thành phố Hồ Chí Minh thật sự trong
                sạch, vững mạnh, chính quy, tinh nhuệ, hiện đại
              </span>
            </div>
          </div>
          <div className="logo_container">
            <img className="img_logo" width={60} src={LogoCA2} alt="Logo CA2" />
            <div className="logo_right"></div>
          </div>
        </div>

        <LayoutSection
          number={2}
          label="THÔNG BÁO"
          // imageUrl={imageLists.slice(0, 2)}
          data={layoutt2}
        />
        <LayoutSection
          number={3}
          label="SỰ KIỆN & HOẠT ĐỘNG"
          // imageUrl={image}
          data={layoutt3}
        />

        {/* 
        {layoutt4 && (
          <LayoutSection
            number={4}
            label="THÔNG TIN NỘI BỘ"
            // imageUrl={{}}
            data={layoutt4}
          />
        )}
*/}

        {/* {layoutt5 && (
          <div className="screenlayout5">
            <Caledar data={data.layout5} dataContent={layoutt5} />
          </div>
        )} */}
      </div>
    </div>
  );
}

export default MainScreen;
