import { createSlice } from "@reduxjs/toolkit";
import { getUserInfo } from "../info/infoThunk";
import { getDepartmentInfo } from "../info/infoThunk";

const initialState = {
  role: "",
  departmentInfo: null,
  user: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDepartmentInfo.fulfilled, (state, action) => {
      state.departmentInfo = action.payload;
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export const departmentInfoSelector = (state) => state.user.departmentInfo;
export const userInfoSelector = (state) => state.user.user;

export const { setRole, setDepartmentIdAdmin } = userSlice.actions;
export default userSlice.reducer;
