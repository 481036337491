const LOCAL_STORAGE_AUTH_KEY = "LOCAL_STORAGE_AUTH_KEY";
const LOCAL_STORAGE_AUTH_ROLE = "LOCAL_STORAGE_AUTH_ROLE";

export const setToken = (token) => {
  localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, token);
};
export const setRole = (role) => {
  localStorage.setItem(LOCAL_STORAGE_AUTH_ROLE, role);
};
export const getToken = () => {
  const jwtToken = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
  return jwtToken || null;
};
export const getRole = () => {
  const jwtToken = localStorage.getItem(LOCAL_STORAGE_AUTH_ROLE);
  return jwtToken || null;
};
export const removeToken = () => {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
};
