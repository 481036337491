import AxiosClient from "./axiosClient";

const API_ENDPOINT = "collections/";

export const getCollections = (type, data) => {
	return AxiosClient.get(API_ENDPOINT + `${type}`, { params: data });
}

export const editCollections = (id, data) => {
	return AxiosClient.put(API_ENDPOINT + `${id}`, data);
};

export const editFileCollections = (id, data) => {
	return AxiosClient.put(API_ENDPOINT + `${id}`, data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
};

export const searchCollections = (data) => {
	return AxiosClient.get(API_ENDPOINT + 'search', { params: data });
}

export const deleteCollections = (id) => {
	return AxiosClient.delete(API_ENDPOINT + `${id}`);
}

// admin xác nhận nội dung từ phòng ban gửi lên
export const verifyCollection = (params) => {
	return AxiosClient.post(API_ENDPOINT+`verifyCollection/${params.id}/${params.type}`)
}
// gửi nội dung bài viết cho admin
export const sendCollection = (id) => {
	return AxiosClient.post(API_ENDPOINT+`sendToAdmin/${id}`)
}
