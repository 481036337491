import AxiosClient from "./axiosClient";

const API_ENDPOINT = "Contents/";

export const getContent = () => {
   return AxiosClient.get(API_ENDPOINT);
};

export const getLayouts = () => {
   return AxiosClient.get("layouts/getOne");
};

export const getContentById = (id) => {
   return AxiosClient.get(`Contents/${id}`);
};

export const getFileContent = (file) => {
   return AxiosClient.get(`collections/download/${file}`);
};
