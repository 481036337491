import { createAsyncThunk } from '@reduxjs/toolkit';
import { getListSchedules } from '../../services/scheduleServices';
import Notiflix from 'notiflix';

export const getListSchedulesThunk = createAsyncThunk(
    'getListSchedules',
    async () => {
        try {
            const listSchedules = await getListSchedules();
            return listSchedules?.data;
        } catch (error) {
            if (error) {
                Notiflix.Notify.failure('Không có ảnh hiển thị');
            }
            else {
                Notiflix.Notify.failure('Failed to get image');
            }
        }
    }
)