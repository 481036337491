import React, { useState } from "react";
import classNames from "classnames/bind";
import style from "./InfoComponent.module.scss";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import Tabs from "../Tabs/Tabs";
import Layout from "../ItemLeft/Layout";
import Account from "./components/Account";
import UserComponent from "./components/UserComponent";
import AccessComponent from "./components/AccessComponent";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "../../store/navTab/NavTabSlice";
import CensorRequest from "./components/CensorRequest/CensorRequest";
const cx = classNames.bind(style);
export default function InfoComponent() {
  const role = localStorage.getItem("LOCAL_STORAGE_AUTH_ROLE");
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.NavTabSlice.activeTab);
  // console.log("activeTab aaa", activeTab);

  // items for tabs
  const [itemsPhongBan, setItems] = useState([
    {
      key: "1",
      label: "Layout",
    },
    {
      key: "2",
      label: "Cài đặt",
    },
    {
      label: "Khu vực chính",
      key: "3",
    },
    {
      label: "Khu vực phụ bên phải",
      key: "4",
    },
    {
      label: "Lịch",
      key: "5",
    },
  ]);

  const [itemsAdmin, setItemsAdmin] = useState([
    {
      key: "1",
      label: "Tài khoản của bạn",
    },
    {
      key: "2",
      label: "Người dùng",
    },
    {
      label: "Quyền truy cập",
      key: "3",
    },
  ]);

  return (
    <div className={cx("container")}>
      <div className={cx("header-container")}>
        <div className={cx("header-section")}>
          {/* back section */}
          <div className={cx("back-section-container")}>
            <Link
              to={"/"}
              className={cx("back-section")}
              onClick={() => dispatch(changeTab(""))}
            >
              <div className={cx("backIcon")}>
                <FaArrowLeftLong color="white" />
              </div>
              <div className={cx("back-title")}>Quay lại khu vực làm việc</div>
            </Link>
          </div>

          {/* Role */}
          <div className={cx("role-section-container")}>
            <div className={cx("role-section")}>
              <span style={{ fontSize: "12px" }}>Quyền truy cập</span>
              {role === "ADMIN" ? (
                <span>Admin</span>
              ) : (
                <span>QL phòng ban</span>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Tab */}
      <div className={cx("tab-container")}>
        {role === "ADMIN" ? (
          <Tabs isAdmin={true}>
            <div label="Tài khoản của bạn">
              <Account admin />
            </div>
            <div label="Người dùng">
              <UserComponent />
            </div>

            <div label="Duyệt mật khẩu">
              <CensorRequest />
            </div>

            <div label="Quyền truy cập">
              <AccessComponent />
            </div>
          </Tabs>
        ) : (
          <Tabs isAdmin={true}>
            <div label="Tài khoản của bạn">
              <Account />
            </div>
          </Tabs>
        )}
      </div>
    </div>
  );
}
