import React, { useEffect, useState } from "react";
import { Button, Image, Input, Modal, Select, Upload, message } from "antd";
import Flag from "../../../assets/vietnam.png";
import { IoMdAddCircleOutline } from "react-icons/io";
import DropdowButton from "../../../components/Dropdown/DropdowButton";
import Tabs from "../../../components/Tabs/Tabs";
import Layout from "../../../components/ItemLeft/Layout";
import Layout1 from "../../../components/Layout/Layout1";
import Layout3 from "../../../components/Layout/Layout3";
import Layout2 from "../../../components/Layout/Layout2";
import Setting from "../../../components/ItemLeft/Setting";
import Marquee from "../../../components/Marquee/Marquee";
import HeaderPBan from "../../../components/Header/HeaderPBan";
import AddContent from "../ThuVien/AddContent";
import { InboxOutlined } from "@ant-design/icons";
import classNames from "classnames/bind";
import style from "./PhongBan.module.scss";
import Preview from "./Preview";
import AxiosClient, { API_ENDPOINT } from "../../../services/axiosClient";
import { useDispatch, useSelector } from "react-redux";
import {
  phongban3Selector,
  phongban4Selector,
  phongban5Selector,
  phongbanLayoutSelector,
  phongbanSelector,
  postPreviewSelector,
  setPhongban3Selected,
  setPhongban4Selected,
  setPhongban5Selected,
  setPhongbanLayout,
  setPhongbanSelected,
  setPostPreview,
} from "../../../store/phongban/phongbanSlice";
import HeaderAdmin from "../../../components/Header/HeaderAdmin";
import DanhSachphongBan from "./DanhSachphongBan";
import { createLayout } from "../../../services/layoutServices";
import Layout4 from "../../../components/Layout/Layout4";
import Layout5 from "../../../components/Layout/Layout5";
import { Option } from "antd/es/mentions";
import { id, se } from "date-fns/locale";
import Notiflix, { Loading } from "notiflix";
import { getLayouts } from "../../../services/contentServices";
import { getRole, getToken } from "../../../utils/localStorage";
import {
  departmentInfoSelector,
  userInfoSelector,
} from "../../../store/user/userSlice";
import _ from "lodash";

const cx = classNames.bind(style);

function PhongBan() {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState(getRole() === "ADMIN" ? "2" : "1");
  const [isOpenShowPreview, setIsOpenShowPreview] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [departmentName, setDepartmentName] = useState("");
  const [currentRoom, setCurrentRoom] = useState([]);
  const [fileList, setFileList] = useState([]);
  const showModal = () => setIsModalVisible(true);
  const [_id, set_id] = useState("");
  const departmentInfo = useSelector(departmentInfoSelector);
  const userInfo = useSelector(userInfoSelector);

  // Lấy thông tin layout của phòng ban
  const [slogan, setSlogan] = useState("");
  const [department, setDepartment] = useState({});
  const [loadingAddRoom, setLoadingAddRoom] = useState(false);
  // console.log("headingName", headingName);
  const postPreview = useSelector(postPreviewSelector);
  // console.log(postPreview);
  const handleGetUId = (id) => {
    set_id(id);
  };

  const [items, setItems] = useState([
    {
      key: "1",
      label: "Layout",
    },
    {
      key: "2",
      label: "Cài đặt",
    },
    {
      label: "Khu vực chính",
      key: "3",
    },
    {
      label: "Khu vực phụ bên phải",
      key: "4",
    },
    {
      label: "Lịch",
      key: "5",
    },
  ]);

  const [itemsAdmin, setItemsAdmin] = useState([
    // {
    //   key: "1",
    //   label: "Layout",
    // },
    {
      key: "2",
      label: "Layout",
    },
    {
      label: "Khu vực chính",
      key: "3",
    },
  ]);

  const phongbanSelected = useSelector(phongbanSelector);
  const phongban3Selected = useSelector(phongban3Selector);
  const phongban4Selected = useSelector(phongban4Selector);
  const phongban5Selected = useSelector(phongban5Selector);
  const phongbanLayout = useSelector(phongbanLayoutSelector);
  // console.log(phongbanSelected, phongban3Selected, phongban4Selected, phongban5Selected);

  const role = getRole();
  const token = getToken();
  // Lấy phòng ban hiện tại dựa trên token
  useEffect(() => {
    const currentRoom = async () => {
      try {
        if (!token) {
          console.log("Authorization token is not available.");
          return;
        }
        if (departmentInfo?._id) {
          // thay đổi API ở đây
          const res = await AxiosClient.get(`/layouts/getOne`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          console.log("❤️❤️❤️❤️❤️❤️", res.data);

          const layout2 = res?.data?.layoutScreen.find(
            (item) => item?.layout_position == 2
          );

          const layout3 = res?.data?.layoutScreen.find(
            (item) => item?.layout_position == 3
          );

          const layout4 = res?.data?.layoutScreen.find(
            (item) => item?.layout_position == 4
          );

          const layout5 = res?.data?.layoutScreen.find(
            (item) => item?.layout_position == 5
          );

          const departmentResult = {
            departmentId: res.data.departmentId,
            departmentTitle: res.data.departmentTitle,
            departmentName: res.data.departmentName,
            delayTime: res.data.delayTime,
            image: res.data.image,
            slogan: res.data.slogan,
            lastLogin: res.data.updateBy.lastLogin,
            role: res?.data.updateBy.username,
          };

          dispatch(
            setPhongbanLayout({
              ...phongbanLayout,
              delayTime: res?.data?.delayTime,
              slogan: res?.data?.slogan,
            })
          );
          setDepartment(departmentResult);
          dispatch(setPhongbanSelected(handleFilterLayout(layout2)));
          dispatch(setPhongban3Selected(handleFilterLayout(layout3)));
          dispatch(setPhongban4Selected(handleFilterLayout(layout4)));
          dispatch(setPhongban5Selected(handleFilterLayout(layout5)));
          // const filterLayout5 = layout5?.data[0];
          // dispatch(setPhongban5Selected(filterLayout5));
          // thêm tên và slogan ở đây
          setSlogan(res?.data?.slogan);

          setCurrentRoom(departmentResult);
        }
      } catch (error) {
        console.error("Failed to fetch current room:", error);
      }
    };

    const fetchLayout = async () => {
      try {
        const res = await AxiosClient.get("layouts/getOne", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "LOCAL_STORAGE_AUTH_KEY"
            )}`,
          },
        });
        // console.log("🚀🚀🚀🚀🚀", res.data);

        const layout2 = res?.data?.layoutScreen.find(
          (item) => item?.layout_position == 2
        );

        const layout3 = res?.data?.layoutScreen.find(
          (item) => item?.layout_position == 3
        );

        const layout4 = res?.data?.layoutScreen.find(
          (item) => item?.layout_position == 4
        );

        const layout5 = res?.data?.layoutScreen.find(
          (item) => item?.layout_position == 5
        );

        const departmentInfoResult = {
          departmentId: res.data.departmentId,
          departmentName: res.data.departmentName,
          departmentTitle: res.data.departmentTitle,
          slogan: res.data.slogan,
        };

        dispatch(
          setPhongbanLayout({
            ...phongbanLayout,
            slogan: res?.data?.slogan,
          })
        );
        setDepartment(departmentInfoResult);
        dispatch(setPhongbanSelected(handleFilterLayout(layout2)));
        dispatch(setPhongban3Selected(handleFilterLayout(layout3)));
        dispatch(setPhongban4Selected(handleFilterLayout(layout4)));
        dispatch(setPhongban5Selected(handleFilterLayout(layout5)));
        // const filterLayout5 = layout5?.data;
        // dispatch(setPhongban5Selected(filterLayout5));
      } catch (error) {
        console.log("Lỗi", error);
      } finally {
        Notiflix.Loading.remove();
      }
    };
    if (role === "DEPARTMENT" || role === "DATAENTRY") {
      currentRoom();
    } else if (role === "ADMIN") {
      fetchLayout();
    }
  }, []);

  // useEffect(() => {
  //   console.log("phongban5Selected", phongban5Selected);
  // }, [phongban5Selected]);

  const handleChangeTab = (key) => {
    if (key === "1") {
      setActiveKey("1");
    }
    if (key === "2") {
      setActiveKey("2");
    }
    if (key === "3") {
      setActiveKey("3");
    }
    if (key === "4") {
      setActiveKey("4");
    }
    if (key === "5") {
      setActiveKey("5");
    }
  };

  const handleFilterLayout = (layout) => {
    return layout?.data?.map((item) => {
      let checkTypeSchedule = "";

      switch (item?.dataId) {
        case "lichTruc":
          checkTypeSchedule = "lichTruc";
          break;
        case "lichCongTac":
          checkTypeSchedule = "lichCongTac";
          break;
        case "lichTruc&lichCongTac":
          checkTypeSchedule = "lichTruc&lichCongTac";
          break;
        case "lichDangBang":
          checkTypeSchedule = "lichDangBang";
          break;
        default:
          checkTypeSchedule = "";
          break;
      }

      return {
        id: item?._id,
        title: item?.title,
        type: item?.type,
        daily: item?.daily,
        timestamp: item?.timestamp,
        author: item.author,
        content:
          item?.type === "schedules"
            ? undefined
            : `${API_ENDPOINT}collections/download/${item?.content}`,
        dataId: checkTypeSchedule,
        addBy: item.addBy,
        isDefault:
          item?.type === "schedules" ||
          (userInfo?.role?.includes(item?.addBy) && userInfo?.role !== "ADMIN")
            ? false
            : true,
        date: item?.time?.date,
        hours: item?.time?.hour,
        specificDate: item?.time?.date,
        specificTime: item?.time?.hour,
      };
    });
  };
  const layout5 = _.isArray(phongban5Selected)
    ? phongban5Selected.length > 0 && _.isArray(phongban5Selected[0])
      ? phongban5Selected[0]
      : phongban5Selected
    : [phongban5Selected];
  // console.log("😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️", _.isArray(phongban5Selected), layout5);

  const Body_leftBottom = ({ activeKey }) => {
    if (activeKey === "1") {
      if (role === "ADMIN") {
        return (
          <Layout1
            hide={true}
            admin={true}
            sloganDe={slogan}
            departmentInfomation={department}
          />
        );
      } else if (role === "DEPARTMENT") {
        return (
          <Layout1
            admin={true}
            sloganDe={slogan}
            departmentInfomation={department}
          />
        );
      } else if (role === "DEPARTMENT") {
        return <Layout1 sloganDe={slogan} departmentInfomation={department} />;
      }
    } else if (activeKey === "2") {
      // console.log("😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️", phongban3Selected, activeKey);
      return (
        <>
          {phongbanSelected?.map((item) => (
            <Layout3
              item={item}
              activeKey={activeKey}
              key={item._id}
              department={department}
            />
          ))}
        </>
      );
    } else if (activeKey === "3") {
      // console.log("😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️", phongban3Selected, activeKey);
      return (
        <>
          {phongban3Selected?.map((items) => (
            <Layout3
              item={items}
              activeKey={activeKey}
              key={items._id}
              className={cx("layout3-item")}
            />
          ))}
        </>
      );
    } else if (activeKey === "4") {
      return phongban4Selected?.map((item) => (
        <Layout3 item={item} activeKey={activeKey} key={item._id} />
      ));
    } else if (activeKey === "5") {
      // console.log("😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️", layout5, activeKey);
      return (
        !_.isEmpty(layout5[0]) &&
        layout5?.map((item) => (
          <Layout3 item={item} activeKey={activeKey} key={item?._id} />
        ))
      );
    }
  };

  // show AddcontentModal
  const [isModalOpenAddContent, setIsModalOpenAddContent] = useState(false);
  const showModalAddContent = () => {
    setIsModalOpenAddContent(true);
  };
  const handleOkAddContent = () => {
    setIsModalOpenAddContent(false);
  };
  const handleCancelAddContent = () => {
    setIsModalOpenAddContent(false);
  };

  const handleShowPreview = () => setIsOpenShowPreview(true);
  const handleHidePreview = () => {
    setIsOpenShowPreview(false);
  };

  const handleOk = async () => {
    setLoadingAddRoom(true);
    if (!departmentName || fileList.length === 0) {
      message.error("Xin mời nhập tên phòng ban và upload ảnh.");
      return;
    }
    const imageFile = fileList[0];
    await handleAddRoom(departmentName, _id, imageFile);
    setIsModalVisible(false);
  };

  const handleAddRoom = async (departmentName, id, imageFile) => {
    try {
      const formData = new FormData();
      // Đảm bảo chỉ gửi một file
      formData.append("file", imageFile); // imageFile giờ là một đối tượng File, không phải mảng
      const uploadResponse = await AxiosClient.post(
        "/collections/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "LOCAL_STORAGE_AUTH_KEY"
            )}`,
          },
        }
      );

      if (uploadResponse.status === 201) {
        const imgUrl = `${API_ENDPOINT}collections/download/blog/${uploadResponse.data.file.filename}`;
        const addRoomResponse = await AxiosClient.post(
          "departments",
          {
            departmentName: departmentName,
            userId: id,
            image: imgUrl,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "LOCAL_STORAGE_AUTH_KEY"
              )}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (addRoomResponse.status === 201) {
          message.success("Phòng ban đã được thêm thành công");
          setLoadingAddRoom(false);
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("Lỗi khi thêm phòng ban", error);
      setLoadingAddRoom(false);
    }
  };

  const handleCancel = () => setIsModalVisible(false);

  const uploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        message.error("Chỉ hỗ trợ tải lên ảnh định dạng jpg hoặc png");
        return false;
      }
      setFileList([file]);
      // trả về false để dừng việc tải ảnh lên ngay lập tức
      return false;
    },
    onRemove: () => {
      setFileList([]);
    },
    fileList,
  };
  const BodyContent = () => (
    <div className={cx("body")}>
      <div className={cx("item_body_left")}>
        <div className={cx("body_leftTop")}>
          <div className={cx("left_title")}>
            <DropdowButton
              activeKey={activeKey}
              onButtonKey={handleChangeTab}
              role={role}
            />
          </div>
          <Button
            disabled={activeKey === "1" && true}
            className={cx("left_add")}
            onClick={() => {
              showModalAddContent();
            }}
          >
            <IoMdAddCircleOutline size={25} style={{ marginRight: "8px" }} />
            <span>Thêm nội dung</span>
          </Button>
        </div>
        <Modal
          centered
          title={<span style={{ fontSize: "24px" }}>Thêm nội dung</span>}
          open={isModalOpenAddContent}
          onOk={handleOkAddContent}
          okText={false}
          okButtonProps={{
            style: {
              height: 50,
              width: 150,
              backgroundColor: "#E61D23",
              display: "none",
            },
          }}
          onCancel={handleCancelAddContent}
          cancelButtonProps={{
            style: { height: 50, width: 100, display: "none" },
          }}
          width={"80vw"}
        >
          <AddContent
            isMargee={false}
            handleCancelAddContent={handleCancelAddContent}
            activeKey={activeKey}
          />
        </Modal>
        <div className={cx("body_leftBottom")}>
          <Body_leftBottom activeKey={activeKey} />
        </div>
      </div>
      <div className={cx("item_body_right")}>
        <div className={cx("body_right_tabs")}>
          {role === "ADMIN" ? (
            <Tabs>
              <div label="Layout">
                <Layout
                  items={itemsAdmin}
                  resolution="FULL HD"
                  onButtonClick={handleChangeTab}
                  activeKey={activeKey}
                  admin={true}
                />
              </div>
            </Tabs>
          ) : (
            <Tabs>
              <div label="Layout">
                <Layout
                  items={items}
                  resolution="FULL HD"
                  onButtonClick={handleChangeTab}
                  activeKey={activeKey}
                  admin={false}
                />
              </div>
              <div label="Cài đặt">
                <Setting currentRoom={currentRoom} />
              </div>
            </Tabs>
          )}
        </div>
      </div>
    </div>
  );

  const [user, setUser] = useState([]);
  useEffect(() => {
    const fetchUser = async () => {
      Loading.standard("Đang tải dữ liệu");
      const res = await AxiosClient.get("users/department");
      setUser(res.data);
      Loading.remove();
    };
    fetchUser();
  }, []);

  // hàm lấy thời gian
  const extractTime = (isoString) => {
    const date = new Date(isoString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };
  return (
    <div className={cx("containpb")}>
      <Marquee img={Flag}>
        Trung thành, Dũng cảm - Mưu trí, Sáng tạo - Vì hạnh phúc nhân dân - Vì
        bình yên Thành phố
      </Marquee>
      {role === "ADMIN" ? (
        <>
          <HeaderAdmin
            name="Phòng Ban"
            addName="Thêm phòng ban"
            onBtnAdd={showModal}
            onBackButtonClick={handleHidePreview}
            onBtnPreview={() => handleShowPreview()}
            showBackButton={isOpenShowPreview}
          />
          <Modal
            title={
              <span
                style={{ fontSize: "42px", backgroundColor: "transparent" }}
              >
                Thêm phòng ban
              </span>
            }
            open={isModalVisible}
            onOk={handleOk}
            confirmLoading={loadingAddRoom}
            onCancel={handleCancel}
            okText="Thêm"
            cancelText="Đóng"
            cancelButtonProps={{ style: { height: 50, width: 100 } }}
            okButtonProps={{
              style: { height: 50, width: 100, backgroundColor: "#E61D23" },
            }}
            width={"80vw"}
          >
            <div style={{ borderTop: "1px solid #000" }}>
              <label style={{ fontSize: "24px", marginTop: "8px" }}>
                Tên phòng ban
              </label>
            </div>
            <Input
              placeholder="Nhập tên phòng ban"
              style={{ height: "50px", fontSize: "24px" }}
              value={departmentName}
              onChange={(e) => setDepartmentName(e.target.value)}
            />
            <label style={{ fontSize: "24px", marginTop: "8px" }}>
              Thêm thành viên phòng ban
            </label>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              onChange={(value) => handleGetUId(value)}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {user.map((item) => {
                const { _id, username } = item;
                return (
                  <Option key={_id} value={_id}>
                    {username}
                  </Option>
                );
              })}
            </Select>
            <label style={{ fontSize: "24px", marginTop: "20px" }}>
              Thêm ảnh phòng ban
            </label>
            <Upload.Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Kéo thả hoặc click vào đây để tải ảnh lên
              </p>
              <p className="ant-upload-hint">Hỗ trợ tải lên một tệp duy nhất</p>
            </Upload.Dragger>
          </Modal>
          <Tabs isAdmin={true}>
            <div label="Gắn cứng nội dung">
              {isOpenShowPreview ? <Preview /> : <BodyContent />}
            </div>
            <div label="Danh sách phòng ban">
              <DanhSachphongBan />
            </div>
          </Tabs>
        </>
      ) : (
        <>
          <HeaderPBan
            isDetails={false}
            pbName={department.departmentName}
            timeEnd={extractTime(department.lastLogin)}
            userName={department?.role}
            onBackButtonClick={handleHidePreview}
            onBtnPreview={() => handleShowPreview()}
            showBackButton={isOpenShowPreview}
            id={departmentInfo?._id}
          />
          {isOpenShowPreview ? <Preview /> : <BodyContent />}
        </>
      )}
    </div>
  );
}

export default PhongBan;
